import { findNearestValue } from "@/pages/NftGeneratePage.tsx/helpers";
import { useState } from "react";


export const RangeSliderWithDropdown = ({ value, setValue, defaultValue, min = 0, max = 100, custom = false, dropdownValues = [50, 70, 80, 90, 95, 100], unit = "%", label = "" }: { min?: number, max?: number, setValue: (v: number) => void; value: number; custom?: boolean; dropdownValues?: Array<number>; unit?: string; label?: string; defaultValue: any; }) => {

  const [selectedOption, setSelectedOption] = useState(defaultValue); // Default to 10%

  const handleDropdownChange = (e: any) => {
    let percentageValue = parseInt(e.target.value);
    setValue(percentageValue);
    setSelectedOption(percentageValue.toString());
  };

  const handleSliderChange = (e: any) => {
    setValue(e.target.value);
    setSelectedOption(e.target.value);
  };

  const handleSliderMouseUp = () => {
    if (!custom) {
      const val = findNearestValue(dropdownValues, value);
      setValue(val);
      setSelectedOption(val.toString());
    }
  };

  return (
    <div className=" justify-between items-center text-[16px]">
      {label && <span className="mr-2 text-2 text-f12 select-none">{label}</span>}
      <br />
      <span>
        <select
          className='border-2 my-2 border-slate-200 rounded-lg text-2 outline-none'
          // className="mr-2"
          value={selectedOption}
          onChange={handleDropdownChange}
        >
          {!dropdownValues.includes(value) && <option hidden value={value}>{value}{unit}</option>}
          {dropdownValues.map((v) => <option value={v}>{v}{unit}</option>)}
        </select>

        <input
          className=' text-brand'
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={handleSliderChange}
          onMouseUp={handleSliderMouseUp}
        />
      </span>
    </div>
  );
};