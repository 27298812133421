import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from "@sentry/react";
import { useEffect } from 'react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS!,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/testnet.phrase.trade*/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ErrorBoundary
    fallbackRender={({ error }) => {
      // reload the page on crash!
      useEffect(() => { window.location.reload(); }, []);
      return <div className="grid place-items-center w-[100vw] h-[100vh]">
        <div>Aww Snap! Appologies.</div>
        <pre style={{ color: 'red' }}>{error.message}</pre>{' '}
      </div>;
    }}
  >
    <App />
  </ErrorBoundary>
);
