export const emojiCategories: EmojiCategory = {
  Happy: ['😊', '😄', '😁', '😃', '🙂', '😸', '😺', '😎', '😍', '😇'],
  Amazed: ['😲', '😯', '😳', '🤯', '😱', '😮', '🤩', '🙀', '🤔', '😵'],
  Wow: ['😲', '😮', '😯', '🤯', '🙀', '😵', '😧', '😳', '🤨', '😱'],
  Smiling: ['😊', '😄', '😃', '🙂', '😁', '😺', '😸', '😏', '😎', '😆'],
  Cheering: ['🎉', '👏', '🙌', '🎊', '🥳', '💥', '😄', '😃', '😁', '🤩'],
};

export const chooseRandomEmoji = (category: EmojisType): string => {
  const emojis = emojiCategories[category];
  return emojis[Math.floor(Math.random() * emojis.length)];
};