import { useModalState } from '@/atoms/modalState';
import ClickAwayListener from 'react-click-away-listener';
import { twMerge } from 'tailwind-merge';
import { SecondaryBtn } from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export default function YesNoModal() {
  const { modalState, closeModal } = useModalState();
  const onCancel = () => {
    closeModal();
    modalState.onCancel();
  };
  const onConfirm = () => {
    modalState.onConfirm();
    // closeModal();
    // const newInd = (modalTypes.indexOf(modalState.type) + 1) % modalTypes.length;
    // updateModal({ ...modalState, type: modalTypes[newInd] });
  };

  return modalState.for == 'yesno' ? (
    <div className="relative w-full h-full">
      <div className="absolute h-[100vh] flex felx-col justify-center items-center bg-[#00000066] w-full z-[100000]">
        <ClickAwayListener className='' onClickAway={() => {
          closeModal();
        }}>
          <div className={twMerge(` bg-grey1 mx-8 rounded-[10px] animate-fade-up shadow-2xl w-full h-full justify-center z-[1000000] `, modalState.className)}>
            <div className="relative h-full w-full ">
              <div className="h-full">
                <center className=" text-[20px] font-bold pt-8">
                  <FontAwesomeIcon icon={modalState.type === 'info' ? faInfoCircle : modalState.type === 'confirm' ? faQuestionCircle : modalState.type === 'error' ? faExclamationCircle : faExclamationTriangle}
                    className={`text-[35px] ${modalState.iconColor}`}
                  />
                </center>
                <center className=" text-[20px] font-bold">{modalState.title}</center>
                <center className=" text-f14 px-8 text-2 font-thin mt-4">
                  {modalState.message}
                </center>
              </div>

              <div className="h-[4rem] absolute w-full felx-1 bottom-8 px-8 flex justify-between ">
                <SecondaryBtn onClick={onCancel} className={twMerge("shadow-sm px-8 bg-4 text-2", modalState.noClass)}>{modalState.noText}</SecondaryBtn>
                <SecondaryBtn onClick={onConfirm} className={twMerge("shadow-sm px-8", modalState.yesClass)}>{modalState.yesText}</SecondaryBtn>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div >
  ) : null;
}
