import { useEffect, useState } from 'react';
import useSearchMarket from '../../atoms/marketSearch';
import { Tablist } from '../../components/Tablist';

import {
  Markets,
  Holdings,
  Watchlist,
  UserActivityTab,
} from './userProfileTabs';
import { UserCard } from './UserCard';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import LoginPage from '../LoginPage';
import { userInfoTabs } from '@/config';
import SwipeableViews from 'react-swipeable-views';
import { ListLoader, ListNftCardLoader } from '@/components/ListLoader';
import { useOwnedNftState, useUserHoldingState, useWatchlistNftState } from '@/atoms/marketState';

export default function UserProfilePage() {

  const [activeTab, setActiveTab] = useState('Holdings');
  const searchManager = useSearchMarket();
  const params = useParams();
  const myAccount = useAccount();
  const account = params.user_addr ? { address: params.user_addr } : myAccount;
  const [mountedTabs, setMountedTabs] = useState([true, false, false, false, false]);
  const [tabs, setTabs] = useState(userInfoTabs);
  const [, setHolding] = useUserHoldingState();
  const [, setOwnership] = useOwnedNftState();
  const [, setWatchlist] = useWatchlistNftState();

  useEffect(() => {
    if (account?.address == myAccount?.address) {
      setTabs([...userInfoTabs]);
    }
  }, [myAccount?.address]);

  useEffect(() => {
    return () => {
      setHolding(null);
      setOwnership(null);
      setWatchlist(null);
    };
  }, []);

  if (!account.address) return <LoginPage />;

  return (
    <div className="flex flex-col ">
      <LoginPage viewOnly />
      {/* creating a 1/3 height section for user details and 2/3 page section for user specific details  */}
      <div className="sticky z-[10] top-0 flex flex-col items-center justify-center w-full px-horizontalSm bg-[#f6f7fc]">
        <UserCard isMyProfile={account.address == myAccount.address} />
        <div className="flex w-full pb-4 select-none">
          <Tablist
            tablist={tabs}
            activeTab={searchManager.keyword ? '-1' : activeTab}
            onTabSelect={(tabName: string) => {
              const index = tabs.indexOf(tabName);
              setActiveTab(tabName);
              if (!mountedTabs[index]) {
                setMountedTabs((t) => {
                  t[index] = true;
                  return [...t];
                });
              }
            }} />
        </div>
      </div>

      {/* All list of Holdings/Ownships/Watchlist/Activities */}
      <div className="relative pt-4 select-none">
        <div className="flex flex-col w-full">
          <div className="w-full pb-3 bg-brandGrey">
            <SwipeableViews
              index={tabs.indexOf(activeTab)}
              onChangeIndex={(index) => {
                setActiveTab(tabs[index % tabs.length]);
                if (!mountedTabs[index]) {
                  setMountedTabs((t) => {
                    t[index] = true;
                    return [...t];
                  });
                }
              }}
              className='h-full'
            >
              {activeTab == "Holdings" || mountedTabs[0] ? <div className="h-[calc(100vh-210px)] mx-6 overflow-hidden">
                <Holdings user_addr={account.address} />
              </div> : <ListNftCardLoader />}

              {activeTab == "Owns" || mountedTabs[1] ? <div className="h-[calc(100vh-210px)] mx-6 overflow-hidden">
                <Markets user_addr={account.address} />
              </div> : <ListNftCardLoader />}

              {activeTab == "Watchlist" || mountedTabs[2] ? <div className="h-[calc(100vh-210px)] mx-6 overflow-hidden">
                <Watchlist user_addr={account.address} />
              </div> : <ListNftCardLoader />}

              {activeTab == "Activities" || mountedTabs[3] ? <div className="h-[calc(100vh-210px)] mx-6 overflow-hidden">
                <UserActivityTab user_addr={account.address} />
              </div> : <ListLoader />}

              {/* {account.address == myAccount.address && (activeTab == "Claim" || mountedTabs[4]) ? <div className="h-[calc(100vh-210px)] mx-6 overflow-hidden">
                <ClaimUserRewards user_addr={account.address} />
              </div> : <ListLoader />} */}

            </SwipeableViews>
          </div>
        </div>
      </div>

    </div>
  );
}
