import React, { useRef, useEffect } from 'react';

const BackgroundAudio: React.FC<{ url: string; trigger: number; }> = ({ url, trigger }) => {
  // Create a ref for the audio element
  const audioRef = useRef<any>(null);

  useEffect(() => {
    // Play the audio when the component mounts
    if (audioRef.current) {
      audioRef.current.play();
    }

    // Optionally, you can stop the audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset to start
      }
    };
  }, [trigger]); // Add url as a dependency to reload the audio when the url changes

  return (
    <audio
      ref={audioRef}
      src={url}
      // loop // This makes the audio loop indefinitely
      hidden // Optionally hide the audio player from the UI
    />
  );
};

export default BackgroundAudio;
