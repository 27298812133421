// @ts-ignore
import TimeAgo from 'react-timeago';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DisplayPrice } from '@/components/DisplayPrice';
import { chunkArray } from '@/utils/basic';
import { useMarketState } from '@/atoms/marketState';
import { view } from '@/Helpers/bigintUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { handleTextOverflow } from '@/utils/getSanitizeInput';

export const PresentNft: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  {
    nft: NFTStoredData;
    width: number;
    highlight?: boolean;
    className?: string;
    holders?: boolean;
  }> = ({ nft, width, className, highlight = false, holders = false, ...props }) => {
    const navigate = useNavigate();
    const [, setMarketState] = useMarketState();
    const onClick = () => {
      if (nft?.marketId) {
        navigate(`/markets/${nft.marketId}`);
        setMarketState(nft);
      }
    };
    props = props ?? {};

    return <div
      className={
        twMerge(`h-[${width}] w-[${width}] p-2 brand-shadow rounded-[10px] relative
      ${highlight ? "animate-fade" : "bg-white"} `, className)
      }
      onClick={onClick}
      {...props}
    >
      {!nft.minted && <div className=" absolute right-[-5px] top-[-5px] h-10 bg-brand2 text-white animate-shine rounded-lg text-f12 px-3 py-1">Mint Now</div>}
      <img
        src={nft?.thumbnail}
        // src={'/spinning-loading2.gif'}
        onError={(e) => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = "/img/NoNft.png";
          e.currentTarget.classList.remove('img-loading2');
        }}
        loading='lazy'
        height={width}
        width={width}
        className={`${nft?.thumbnail ? "img-loading2" : ""}  rounded-lg`} />
      <div className="p-2 select-none">
        <span className=' flex justify-end text-2 select-none'>
          <DisplayPrice active price={BigInt(nft?.buyPriceAfterFees ?? "0")} ethId={props?.id ? "eth-icon-display" : ""} />
        </span>
        <span className=' font-bold justify-between flex text-f12 select-none'>
          {handleTextOverflow(nft.title) ?? ""}
          {
            holders
              ? <span className='text-f10'>Shares {view(BigInt(nft.shares ?? ""), 3)}</span>
              : <span className='text-f10'>Qty {view(BigInt(nft.sharesSupply ?? ""), 3)}</span>
          }
        </span>
        By <span className=' text-2 '>
          {nft.author ?? ""}
        </span>
        <br />
      </div>
    </div >;
  };

const NullPresentNft: React.FC<{
  width: number;
  className?: string;
}> = ({ width, className, }) => {
  return <div className={
    twMerge(`bg-transparent`, className)
  }>
    <img src={""} width={width} loading='lazy' className="  rounded-lg" />
  </div >;
};

export const NftCardsPairInRow: React.FC<{
  className?: string;
  data: Record<string, NFTStoredData> | NFTStoredData[];
  panic?: boolean;
  holders?: boolean;
  sortner?: (a: NFTStoredData, b: NFTStoredData) => number;
  loadMore?: null | (() => void);
}> = ({ className, data, panic = true, holders = false, sortner, loadMore = null }) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const orgfiles = useMemo(() => Object.entries(data).map(([, v]) => v), [data]);
  const files = useMemo(() => sortner ? orgfiles.sort(sortner) : orgfiles, [orgfiles]);
  const filePairs = useMemo(() => chunkArray<typeof files[0]>(files, 2), [files]);

  useEffect(() => {
    if (columnRef.current) {
      setWidth((columnRef.current.offsetWidth / 2) - 8);
    }
  }, [columnRef]);

  return <>
    <div className={twMerge("w-full h-full overflow-y-auto", className)}>
      <div className="flex-col gap-2" ref={columnRef}>
        {filePairs?.map((pair, i) => {
          return <div key={i} className="flex gap-2 p-2 h-full w-full">
            <PresentNft key={i.toString() + pair[0].sharesSupply} holders={holders} highlight={pair[0]?.highlight ?? false} nft={pair[0]} width={width} />
            {pair && pair?.length > 1
              ? <PresentNft key={(i + 1).toString() + pair[1].sharesSupply} holders={holders} highlight={pair[1]?.highlight ?? false} nft={pair[1]} width={width} />
              : <NullPresentNft width={width} />}
          </div>;
        })}
      </div>
      {loadMore && <>
        <br />
        <div className="w-full flex justify-center cursor-pointer"
          onClick={loadMore}
        >
          <span className="bg-lightBrand2 font-thin text-0 rounded-[5px] p-2 mr-4 mt-4 px-4 shadow-md">
            <FontAwesomeIcon icon={faAnglesDown} className='animate-pulse' /> Load More
          </span>
        </div>
      </>}
      <br />
      <br />
      <br />
    </div>
  </>;
};
