import React from 'react';

export const TextError: React.FC<{ children: string; className?: string; }> = ({ children, className = "" }) => {
  return (
    <center className={className}>
      <p className='text-red-500 text-f12 animate-shake w-full text-center mt-4 px-4'>{children}</p>
    </center>
  );
};

