import { useEffect, useState } from 'react';
import { PrimaryBtn } from '../../components/Buttons';
import MemoPhraseNftIcon from '@/SVG/PhraseNft';
// import { getFirebaseDeviceToken } from '@/firebase/messaging';
// import MemoButtonLoader from '@/components/ButtonLoader';


// Configure chains & providers with the Public provider.
const InstallPage: React.FC<{ status: AppInstallStatus; }> = ({ status }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  // const [notiOn, setNotiOn] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const turnOnNotification = () => {
  //   setLoading(true);
  //   getFirebaseDeviceToken().then(r => {
  //     console.log({ r });
  //     if (r) { setNotiOn(true); }
  //   }).catch(e => {
  //   }).finally(() => {
  //     setLoading(false);
  //   });
  // };

  console.log({ status });

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      // Prevent the mini-infobar from appearing on mobile
      if (status.platform?.Android || status.platform?.iOS) e.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // @ts-ignore
      deferredPrompt.prompt();
      // @ts-ignore
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Clear the saved prompt
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-3">
      <img className="w-[200px] h-[150px] mb-5" src="/Logo.svg" />
      <div className="flex items-center font-bold text-1 text-[20px]">PHRASE.TRADE
        {/* <span className='text-f12 ml-2 p-1 px-2 bg-light3Brand text-white rounded-[5px]' >Beta</span> */}
        <span className='h-[15px] text-f10 ml-1 w-fit rounded-[4px] pb-2 bg-light3Brand px-2 mt-1 text-white'>Beta</span>
      </div>
      <div className="font-semibold text-2 text-f14">

        <span className='flex items-center'>
          Mint and Trade unique
          <MemoPhraseNftIcon className='px-2 mb-[-10px]' height={50} width={50} />
          on chain
        </span>

      </div>
      <div className='min-w-[70%] flex flex-col items-start w-[70%] max-w-[70%]'>
        <span className='flex bg-lightBrand rounded-md p-4 mt-10 mb-2 text-2  text-f10'>
          We're thrilled to have you here!
          For the best experience, we recommend using the mobile app.
          As we're currently in beta, you may occasionally encounter issues. Please share any feedback to help us improve and make Phrase.Trade even better.
        </span>
        {/* <PrimaryBtn
          disable={notiOn ? "Notifications are On!" : false}
          className="mt-[10px] active:translate-y-2  transition-transform"
          onClick={turnOnNotification}
        >
          {loading ? <center> <MemoButtonLoader className="scale-100 -my-[0.2rem] " loading={true} /></center> : "1. Allow Notifications"}
        </PrimaryBtn> */}
        <PrimaryBtn
          // disable={!notiOn ? "Notifications are On!" : false}
          className="mt-[10px] active:translate-y-2  transition-transform"
          onClick={handleInstallClick}
        >
          Install Phrase.Trade
        </PrimaryBtn>
      </div>

      <div className="mt-4 text-2">
        <a href="/privacy-policy" className=" mr-2 underline">Privacy Policy</a>|
        <a href="/terms-of-service" className=" mx-2 underline">Terms Of Service</a>|
        <a href="https://blogs.phrase.trade/how-to-install-pwa" className=" ml-2 underline">Install Help</a>
      </div>
    </div>
  );
};

export default InstallPage;