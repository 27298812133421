import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

const ControlIcon: React.FC<{ tag?: string, faIcon: IconProp; value: string; name: string; setValue: (v: any) => void; className?: string; }> = ({ faIcon, name, value, setValue, className = "", tag = null }) => {
  return <div className="relative ">
    {tag && !(tag == '0') && <center className="absolute z-10 -right-2 -top-0 rounded-full bg-brand p-[0.1rem] h-[15px] min-w-[15px] text-[9px] font-bold text-white">{tag}</center>}
    <center className={twMerge(`min-w-[33px] text-center border-2 p-2 rounded-lg  cursor-pointer ${value == name ? "bg-brand text-white border-brand" : "bg-white border-3 border-[1px]"} relative shadow-sm`, className)}
      onClick={() => setValue(name)} >
      <FontAwesomeIcon className={`text-[18px] pt-1 ${value == name ? "bg-brand text-white" : "text-brand2"}`} icon={faIcon} />
    </center>
  </div>;
};

export default ControlIcon;