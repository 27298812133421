import { type ClassValue, clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";

/**
 * Tailwind CSS classnames concatenation
 * @param inputs Tailwind CSS classnames
 * @returns Concatenated Tailwind CSS classnames
 * @example
 * cn('text-red-500', 'bg-blue-500') // 'text-red-500 bg-blue-500'
 * cn('text-red-500', 'bg-blue-500', 'hover:bg-blue-600') // 'text-red-500 bg-blue-500 hover:bg-blue-600'
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Format a Unix timestamp to a human-readable date
 * @param timeStamp Unix timestamp
 * @returns Human-readable date
 * @example
 * formatDate(1620000000) // 02 May 2021 12:26
 * formatDate('1620000000') // 02 May 2021 12:26
 */
export const formatDate = (timeStamp: number) => {
  const date = moment(timeStamp); // You can pass a specific date here
  return date.format('DD MMM YYYY HH:mm');
};

/**
 * Convert a Unix timestamp to a JavaScript timestamp
 * @param e Unix timestamp
 * @returns JavaScript timestamp
 * @example
 * toJSEpoch(1620000000) // 1620000000000
 * toJSEpoch('1620000000') // 1620000000000
 */
export const toJSEpoch = (e: string | number) => +e * 1000;

/**
 * Convert css rem to number
 * @param num css rem
 * @returns number
 * @example
 * pickNumFromRem('1rem') // 1
 * pickNumFromRem('1.5rem') // 1.5
 */
export const pickNumFromRem = (num: string) => {
  return parseFloat(num.slice(0, num.length - 3));
};

/**
 * Convert css px to number
 * @param num css px
 * @returns number
 * @example
 * pickNumFromPx('1px') // 1
 * pickNumFromPx('1.5px') // 1.5
 */
export const pickNumFromPx = (num: string) => {
  return parseInt(num.slice(0, num.length - 2));
};

/**
 * Wraps a given number into a shorter, more readable format.
 * 
 * Examples:
 * - 1 => "1"
 * - 20 => "20"
 * - 678 => "678"
 * - 4000 => "4k"
 * - 6789 => "6.8k"
 * - 88300 => "88.3k"
 * - 2433600 => "2.43m"
 * 
 * @param count - The number to format.
 * @returns The formatted string representation of the number.
 */
export function wrapCount(count: number): string {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else if (count < 1000000000) {
    return (count / 1000000).toFixed(2).replace(/\.00$/, '').replace(/\.0$/, '') + 'm';
  } else {
    return (count / 1000000000).toFixed(2).replace(/\.00$/, '').replace(/\.0$/, '') + 'b';
  }
}


// find if the nested route is active
export const isRouteActive = (page: string[]) => {
  return page.find(p => p == "/" ? window.location.pathname == p : window.location.pathname.includes(p));
};