import '@/App.css';
import { Toaster } from 'react-hot-toast';
import { ConfiguredRoutes } from '@/config/ConfiguredRoutes';
import "@/config/extendFontAwesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlainMount from './config/PlainMount';
import { isAppInstalled } from './utils/isInstalled';
import InstallPage from './pages/InstallPage';
import { isRouteActive } from './lib/utils';

// Configure chains & providers with the Public provider.
function App() {

  const installationStatus = isAppInstalled();

  return (
    installationStatus.isInstalled || isRouteActive(["/privacy-policy", "/terms-of-service"])
      || import.meta.env.VITE_DONT_NEED_INSTALL == "true"
      ? <>
        <PlainMount />
        <ConfiguredRoutes />
        <Toaster />
      </>
      : <InstallPage status={installationStatus} />
  );
}

export default App;
