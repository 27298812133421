import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
// @ts-ignore
import { debounce } from 'lodash';
import { axiosInstance } from '@/config/axiosInstance';

type SearchMarket = {
  keyword: string;
  loading: boolean;
  markets: NFTStoredData[];
};

const defaultState = {
  keyword: '',
  loading: false,
  markets: [],
};

const SearchMarketAtom = atom<SearchMarket>({
  key: 'SearchMarket',
  default: defaultState,
});

const debouncedSearchMarkets = debounce(async function (
  queryString: string,
  cb: (markets: Market[], keyword: string) => void
) {
  try {
    const { data } = await axiosInstance.get(
      `/api/nft/search/${queryString}`
    );
    cb(data.data ?? [], queryString);
  } catch (e) {
    return null;
  }
}, 100);


let timeOutForSearch: NodeJS.Timeout;

const useSearchMarket = () => {
  const setSearchMarket = useSetRecoilState(SearchMarketAtom);
  const searchState = useRecoilValue(SearchMarketAtom);
  const onSearch = async (keyword: string) => {
    clearTimeout(timeOutForSearch);
    setSearchMarket({ keyword, loading: true, markets: [] });
    if (!keyword) return setSearchMarket(defaultState);
    timeOutForSearch = setTimeout(() => {
      debouncedSearchMarkets(keyword, (markets: NFTStoredData, keyword: any) => {
        setSearchMarket((d: any) => {
          return { ...d, loading: false, markets };
        });
      });
    }, 700);
  };
  const cancelSearch = () => {
    setSearchMarket(defaultState);
  };

  return {
    ...searchState,
    onSearch,
    cancelSearch,
  };
};

export default useSearchMarket;
