import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { view } from '../Helpers/bigintUtils';
import { E8 } from '../Helpers/constants';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { twJoin } from 'tailwind-merge';
import { ReactNode } from 'react';
import { useEthPrice } from '@/atoms/ETHPrice';

export const DisplayPrice: React.FC<{
  price: bigint;
  active?: boolean;
  compact?: boolean | ReactNode;
  className?: string;
  ethId?: string;
  label?: string;
}> = ({ price, active, compact, className, ethId, label = "" }) => {
  // const dollarValue = 262410000000n;
  const eth = useEthPrice(); // Eg: 2656.25 or undefined
  const dollarValue = eth ? BigInt(eth) : null;

  return (
    <div className={twJoin('flex items-center text-f10 ', className)}>
      {compact ? (
        typeof compact == 'object' ? (
          compact
        ) : null
      ) : (
        <span className='mr-1'>{label}</span>
      )}
      <span className={`text-f12 ` + (active ? 'text-brand' : '')}>
        {view(price, 5)}
      </span>
      &nbsp;
      <FontAwesomeIcon
        id={ethId}
        height={8}
        width={8}
        className={`h-[8px] mb-[2px] rounded-full p-1  bg-2 text-white cursor-pointer`}
        icon={faEthereum}
      />
      &nbsp;|&nbsp;<span>${dollarValue ? view((price * dollarValue) / E8, 2) : "..."}</span>
    </div>
  );
};


export const PresentValue: React.FC<{
  price: bigint;
  active?: boolean;
  className?: string;
  textSize?: number;
}> = ({ price, active, className, textSize = 12 }) => {
  // const dollarValue = 242410000000n;
  const eth = useEthPrice(); // Eg: 2656.25 or undefined
  const dollarValue = eth ? BigInt(eth) : null;

  return (
    <div className={twJoin('flex items-center', className)}>
      <span className={`text-[${textSize}px] ` + (active ? 'text-brand' : '')}>
        {view(price, 5)}
      </span>
      &nbsp;
      <FontAwesomeIcon
        className={`h-[${textSize - 4}px] w-[${textSize - 4}px] px-2  mb-[2px] rounded-full p-1 bg-2 text-white cursor-pointer`}
        icon={faEthereum}
      />
      &nbsp;|&nbsp;<span className={`text-[${textSize - 2}px] `}>${dollarValue ? view((price * dollarValue) / E8, 2) : "..."}</span>
    </div>
  );
};
