import { faMicrophone, faPause, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';
import { AudioPlayer } from 'react-audio-play';
import { TextError } from './ui/TextError';

const VoiceRecorder: React.FC<{ blob: any, setBlob: (b: any) => void; }> = ({ blob, setBlob }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const mediaRecorderRef = useRef<null | MediaRecorder>(null);
  const audioChunksRef = useRef([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingIntervalRef = useRef<null | NodeJS.Timer>(null);
  const timeOutRef = useRef<null | NodeJS.Timeout>(null);

  const handleStartRecording = async () => {
    clearTimeout(timeOutRef.current ?? 0);
    timeOutRef.current = setTimeout(() => {
      handleStopRecording();
    }, 60000);

    recordingIntervalRef.current = setInterval(() => {
      setRecordingTime(prevTime => prevTime + 1);
    }, 1000);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        // @ts-ignore
        audioChunksRef?.current?.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      setBlob(audioBlob);
      audioChunksRef.current = [];
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  useEffect(() => {
    if (!blob) return;
    const audio = new Audio();
    audio.src = URL.createObjectURL(blob);

    // Once the metadata is loaded, check the duration
    audio.onloadedmetadata = () => {
      console.log({ dur: audio.duration });
      if (audio.duration == Infinity) {
        audio.currentTime = 1e101; // Force time update to get duration
        audio.ontimeupdate = () => {
          audio.ontimeupdate = null;
          audio.currentTime = 0; // Reset to the start
          if (audio.duration === Infinity) {
            setErrorMessage("Unable to determine audio duration.");
            setAudioURL('');
          } else if (audio.duration > 60) {
            setErrorMessage('The audio file exceeds the 1-minute limit.');
            setAudioURL('');
          } else {
            setAudioURL(audio.src);
            setFileName(blob?.name);
            setErrorMessage('');
          }
        };
      } else if (audio.duration > 60) {
        setErrorMessage('The audio file exceeds the 1-minute limit.');
        setAudioURL('');
      } else {
        setAudioURL(audio.src);
        setFileName(blob?.name);
        setErrorMessage('');
      }
    };
  }, [blob]);

  const handleStopRecording = () => {
    clearTimeout(timeOutRef.current ?? 0);
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    clearInterval(recordingIntervalRef.current ?? 0);
    setRecordingTime(0);
    setFileName('');
  };

  // =======================================================

  const [errorMessage, setErrorMessage] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // Clear previous error messages
      setErrorMessage('');
      // Check if the file is an audio file
      if (!file.type.startsWith('audio/')) {
        setErrorMessage('Please upload a valid audio file.');
        return;
      }
      setBlob(file);
    }
  };

  return (
    <div className="flex flex-col">

      <div className=' flex justify-evenly pt-2'>
        <center className='pt-2 flex-1' >
          <button className='w-[6rem] h-[6rem] bg-brand2 hover:bg-light3Brand2 rounded-[50%] border-3 border-[1px]'
            onClick={isRecording ? handleStopRecording : handleStartRecording}
          >
            <FontAwesomeIcon icon={isRecording ? faPause : faMicrophone} className='text-[20px] px-1 text-white' />
          </button>
          <br />
          <p className='text-2 text-f12 my-4'>
            {isRecording ? <p>Recording Time: <span className=' text-red-500'>{recordingTime}s</span></p> : 'Press to Start Recording'}
          </p>
          {/* {audioURL && <audio src={audioURL} controls />} */}
        </center>

        <center className='border-r-2 border-dotted' ></center>

        <center className='pt-2 flex-1' >
          <input
            id="file-upload-audio"
            type="file"
            accept="audio/*"
            onChange={handleFileUpload}
            className="hidden"
          />
          {/* Circular upload button */}
          <label
            htmlFor="file-upload-audio"
            className="w-[6rem] h-[6rem] flex items-center justify-center rounded-full bg-3 text-white cursor-pointer hover:bg-2 transition duration-300"
          >
            <FontAwesomeIcon icon={faUpload} className='text-[20px] px-1 text-white' />
          </label>
          {!fileName && <p className='text-2 text-f12 my-4' >OR Upload audio</p>}
          {fileName && <p className='text-2 text-f12 my-4'>Uploaded file: {fileName}</p>}
        </center>
      </div>

      <center>
        {audioURL && <AudioPlayer
          color='#8f95a4'
          sliderColor='#825ef6'
          src={audioURL}
        />}
        {/* {errorMessage && <p className='text-red-500 text-f12 animate-shake w-full text-center mt-4 px-4'>{errorMessage}</p>} */}
        {errorMessage && <TextError>{errorMessage}</TextError>}
      </center>

    </div>

  );
};

export default VoiceRecorder;
