import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Spinner: React.FC<{
  className?: string;
}> = ({ className = "" }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faSpinner} spin size="2x" className='' />
    </div>
  );
};

export default Spinner;