import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { ErrorPage } from '../../components/ErrorPage';
import { PrimaryBtn } from '../../components/Buttons';
import { useEffect, useState } from 'react';
import { registerUser, getUserData, setInviteCode, getInviteCode } from '../../Helpers/register';
import useUserState from '../../atoms/userState';
import useDrawerState from '../../atoms/drawerState';
import { formatAddress } from '../../Helpers/web3utils';
import { loginUserWithEmailPassword, setUserLoggedInData } from '@/firebase/instance';
import MemoPhraseNftIcon from '@/SVG/PhraseNft';
import InviteCode from '@/components/ui/InviteCode';
import toast from 'react-hot-toast';
import { useModalState } from '@/atoms/modalState';


// This is a custom hook that syncs the user state with the backend
export const useUserStateSync = () => {
  const { address, connector, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [userState, setUserState] = useUserState();
  const [fbUser, setFbUser] = useState<User & { loggedIn: boolean; } | null>(null);
  const { openModal } = useModalState();

  useEffect(() => {
    // get the firebase login and connet the wallet
    // if not firebase, register user, login firebase and connect the wallet
    if (!fbUser) setUserLoggedInData(setFbUser);
  }, [address, isConnected]);

  // firebase login function, need for authentication on backend
  const fbLogin = (email: string, pass: string) => {
    loginUserWithEmailPassword(email, pass).then(r => {
      if (!r) {
        openModal({
          for: 'yesno',
          type: "warning",
          title: 'Login Pending',
          message: <div className=''>
            Uh-oh, the last login attempt didn’t go through! Please try logging in again.
          </div>,
          yesText: "Complete Login",
          yesClass: "",
          noText: "",
          noClass: "bg-transperent shadow-none",
          onConfirm: () => {
            fbLogin(email, pass);
          },
          onCancel: () => {
            // @ts-ignore
            fbLogin(email, pass);
          },
        });
        disconnect();
        toast.error("Unable to Login!", { id: "Login Issue" });
      } else {
        setInviteCode("------");
      }
    });
  };

  useEffect(() => {
    if (userState == null && fbUser !== null && isConnected && connector && address) {

      const registerNow = async () => {
        await registerUser(connector, address).then(userInfo => {
          if (userInfo && userInfo.data) {
            setUserState(userInfo?.data);
            fbLogin(userInfo.data.email, userInfo.data.code);
            setInviteCode("------");
          } else {
            disconnect();
            toast.error("Unable to Login!", { id: "Login Issue" });
          }
        }).catch(err => {
          disconnect();
          toast.error("Unable to Login!", { id: "Login Issue" });
        });
      };

      const getUserInfo = async () => {
        try {
          const userFound = await getUserData(address);
          if (userFound) {
            setUserState(userFound);
          } else {
            await registerNow();
          }
        } catch (e) {
          await registerNow();
        }
      };

      // if firebase user is logged in, just fetch the data
      if (fbUser.loggedIn === true) {
        getUserInfo();
      } else {
        // register the user if not found, this will also login the user
        registerNow();
      }
    }
  }, [connector, address, isConnected, fbUser]);
};


// Configure chains & providers with the Public provider.
const LoginPage: React.FC<{ viewOnly?: boolean; }> = ({ viewOnly }) => {
  // const { address, connector, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const [isVerified, setIsVerified] = useState(false);
  const [autoLogin,] = useState(getInviteCode() == "------");
  const [userState,] = useUserState();
  const drawerManagement = useDrawerState();

  // return error page if no connectors found
  if (!connectors?.length) {
    return <ErrorPage>No Connectors found</ErrorPage>;
  }

  // call this for logging in the user
  const login = () => {
    if (isVerified || autoLogin) {
      connect({ connector: connectors[0] });
    } else {
      toast.error("Invite Code Required", { id: "InviteErrorNotValid" });
    }
  };

  // if view only then return null
  if (viewOnly) return null;

  const onCodeVarified = () => {
    setIsVerified(true);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-3">
      <img className="w-[200px] h-[150px]  mb-5" src="/Logo.svg" />
      <div className="flex items-center font-bold text-1 text-[20px]">PHRASE.TRADE
        {/* <span className='text-f12 ml-2 p-1 px-2 bg-light3Brand text-white rounded-[5px]' >Beta</span> */}
        <span className='h-[15px] text-f10 ml-1 w-fit rounded-[4px] pb-2 bg-light3Brand px-2 mt-1 text-white'>Beta</span>
      </div>
      <div className="font-semibold text-2 text-f14">
        {userState ? (
          <center>
            We recomment atleast 0.01 ETH <br />in your Account :{' '}
            <a
              href={`${import.meta.env.VITE_TESTNET_EXPLORER}/address/${userState.public_address}`}
              className="underline text-brand"
              target="_blank"
            >
              {formatAddress(userState.public_address)}
            </a>
          </center>
        ) : (
          <span className='flex items-center'>
            Mint and Trade unique
            <MemoPhraseNftIcon className='px-2 mb-[-10px]' height={50} width={50} />
            on chain
          </span>
        )}
      </div>
      {userState ? (
        <div className='min-w-[70%] flex flex-col items-start w-[70%] max-w-[70%]'>
          <PrimaryBtn
            className="mt-[20px] active:translate-y-2 transition-transform"
            onClick={() => drawerManagement.closeLoginDrawer()}
          >
            Understood
          </PrimaryBtn>
        </div>
      ) : (
        <div className='min-w-[70%] flex flex-col items-start w-[70%] max-w-[70%]'>
          {!autoLogin && <InviteCode onCodeVarified={onCodeVarified} />}
          <PrimaryBtn
            className="mt-[10px] active:translate-y-2  transition-transform"
            onClick={login}
          >
            Login
          </PrimaryBtn>
        </div>
      )}

      <div className="mt-4 text-2">
        <a href="/privacy-policy" className=" mr-2 underline">Privacy Policy</a>|
        <a href="/terms-of-service" className=" ml-2 underline">Terms Of Service</a>
      </div>
    </div>
  );
};

export default LoginPage;