
import { ListNftCardLoader } from '@/components/ListLoader';
import { NftCardsPairInRow } from './MarketCards';
import { nftDbFile, nftDbMyFile, preferencesDb } from '@/firebase/realtimeDb';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMarketListState, useMyMarketListState, useNewMarketListState, usePageLength } from '@/atoms/marketState';
import { NoDataFound } from '@/components/NoDataFound';
import { toJSEpoch } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { DisplayPrice } from '@/components/DisplayPrice';
import { SecondaryBtn } from '@/components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { view } from '@/Helpers/bigintUtils';
import Slider from "react-slick";
import { formatTimeDifference } from '@/utils/basic';
import { useAccount, useContractRead } from 'wagmi';
import { appConfig } from '@/config';
import { PhraseTradeStorageAbi } from '@/ABI/PhraseTradeStorage';
import { useEthPrice } from '@/atoms/ETHPrice';
import { E8 } from '@/Helpers/constants';

// latest first sortner
const latestFirstSortner = (a: NFTStoredData, b: NFTStoredData) => toJSEpoch(b.createdOn) - toJSEpoch(a.createdOn);

export const New: React.FC<{}> = () => {
  const [files, setFiles] = useNewMarketListState();
  const [page, setPage] = usePageLength().newPageLength;
  const fileLen = files ? Object.keys(files)?.length : 0;
  const triggerRef = useRef(false);

  const loadNextPage = useMemo(() => () => {
    triggerRef.current = true;
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    // this need to be done only once on page count change
    if (triggerRef.current && page > 0) {
      if (!files) {
        setTimeout(() => { if (!files) setFiles([]); }, 3000);
      }
      triggerRef.current = false;
      // Not unmounting the listners as they are needed for the whole session
      nftDbFile.getNewMintedListner((f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      }, page * 20);
      nftDbFile.getNewMintedUpdateListner((f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      });
    }
  }, [page]);

  useEffect(() => {
    if (fileLen == 0) {
      triggerRef.current = true;
      setPage(page + 1);
    }
  }, [fileLen]);

  if (!files) return <ListNftCardLoader />;
  if (files !== null && fileLen == 0) return <NoDataFound className=' text-center'>No new NFTs at the moment.<br /> Check back later!</NoDataFound>;

  return <NftCardsPairInRow
    loadMore={fileLen >= page * 20 ? loadNextPage : null}
    data={files} sortner={latestFirstSortner} />;
};

// Highest sharesSupply first sortner
const highestSharesSupplyFirstSortner = (a: NFTStoredData, b: NFTStoredData) => Number(b.sharesSupply) - Number(a.sharesSupply);

export const Top: React.FC<{}> = () => {
  const [files, setFiles] = useMarketListState();
  const [page, setPage] = usePageLength().topPageLength;
  const fileLen = files ? Object.keys(files)?.length : 0;
  const triggerRef = useRef(false);

  const loadNextPage = useMemo(() => () => {
    triggerRef.current = true;
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (triggerRef.current && page > 0) {
      if (!files) {
        setTimeout(() => { if (!files) setFiles([]); }, 3000);
      }
      triggerRef.current = false;
      // Need not to unmount the listners as they are needed for the whole session
      nftDbFile.getTopMintedListner((f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      }, page * 20);
      nftDbFile.getTopMintedUpdateListner((f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      });
    }
  }, [page]);

  useEffect(() => {
    if (fileLen == 0) {
      loadNextPage();
    }
  }, [fileLen]);

  if (!files) return <ListNftCardLoader />;
  if (files !== null && fileLen == 0) return <NoDataFound>No top NFTs available at the moment!</NoDataFound>;

  return <NftCardsPairInRow
    loadMore={fileLen >= page * 20 ? loadNextPage : null}
    data={files} sortner={highestSharesSupplyFirstSortner} />;
};

// Unminted first sortner
// const unmintedFirstSortner = (a: NFTStoredData, b: NFTStoredData) => a.minted ? 1 : -1;
const unmintedFirstSortner = highestSharesSupplyFirstSortner;

export const Mine: React.FC<{ address?: string; }> = ({ address }) => {
  const [files, setFiles] = useMyMarketListState();
  const [page, setPage] = usePageLength().myPageLength;
  const fileLen = files ? Object.keys(files)?.length : 0;
  const triggerRef = useRef(false);

  useEffect(() => {
    if (triggerRef.current && page > 0) {
      triggerRef.current = false;
      if (!files) {
        setTimeout(() => { if (!files) setFiles([]); }, 3000);
      }
      // Not unmounting the listners as they are needed for the whole session
      nftDbMyFile.getAllListner(address ?? '', (f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      });
      nftDbMyFile.getAllUpdateListner(address ?? '', (f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f
        }));
      });
      nftDbMyFile.getDeleteListner(address ?? '', (f) => {
        const marketIds = Object.keys(f);
        setFiles(p => Object.fromEntries(Object.entries(p ?? {}).filter(([key, value]) => !marketIds.includes(key))));
      });
    }
  }, [page]);

  useEffect(() => {
    if (fileLen == 0) {
      triggerRef.current = true;
      setPage(page + 1);
    }
  }, [fileLen]);

  if (!files) return <ListNftCardLoader />;
  if (files !== null && Object.keys(files).length == 0) return <NoDataFound>You don't have any NFTs yet!</NoDataFound>;

  return <NftCardsPairInRow data={files} panic={false} sortner={unmintedFirstSortner} />;
};


const TimeCounter: React.FC<{ time: Date; }> = ({ time }) => {
  const [timeLeft, setTimeLeft] = useState(formatTimeDifference(time));
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(formatTimeDifference(time));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <span>{timeLeft}</span>;
};

const RewardsInHand: React.FC<{}> = () => {
  const { address } = useAccount();
  const { data: rewards } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'totalRewardsInHand',
    account: address,
    watch: true,
  });
  const rewardsInHand = rewards ? BigInt(rewards) : 0n;
  const eth = useEthPrice(); // Eg: 2656.25 or undefined
  const dollarValue = eth ? BigInt(eth) : null;
  const dollarValueInHand = dollarValue ? (rewardsInHand * dollarValue) / E8 : 0n;
  return <>
    <p className="text-[26px] sm:text-[35px] font-bold poppins-500 text-4">
      <span className='text-[#ffdd87] pr-1'>
        {view(rewardsInHand || 0n, 3)}ETH
      </span>=<span className='pl-1'>
        ${dollarValue ? view(dollarValueInHand, 2) : "..."}
      </span>
    </p>
    <p className="text-[14px] sm:text-[18px] font-semibold text-4">Rank #1 NFT users will receive
      <span className='text-[#ffdd87] pl-1' >
        ${dollarValue ? view(dollarValueInHand / 2n, 1) : "..."}
      </span>
    </p>
  </>;
};

export const SliderCompaign: React.FC<{ rewardIn: number; }> = ({ rewardIn }) => {
  const compaign = [
    <div key={1} className=' relative'>
      <img src={"/img/RewardArb.png"} alt={`Rewards`} className={`w-[600px] rounded-[10px]`} />
    </div>,
    <div key={2} className=' relative'>
      <img src={"/img/Vault.png"} alt={`Rewards`} className={`w-[600px] rounded-[10px]`} />
      <div className="top-0 right-5 sm:right-10 flex-col absolute flex justify-center items-center h-full">
        <p className="text-[15px] sm:text-[20px] font-thin text-4">Total Rewards in Pool</p>
        <RewardsInHand />
      </div>
    </div>,
    <div key={3} className=' relative'>
      <img src={"/img/Time.png"} alt={`Rewards`} className={`w-[600px] rounded-[10px]`} />
      <div className="top-0 left-5 sm:left-10 flex-col absolute flex justify-center items-center h-full">
        <p className="text-[15px] sm:text-[20px] font-thin text-0">Time is Running out</p>
        <p className="text-[28px] sm:text-[35px] font-bold poppins-500 text-0">
          Next Rewards In
        </p>
        <p className="text-[17px] sm:text-[20px] font-semibold text-0">
          <span className='  pl-1' >
            {rewardIn ? <TimeCounter time={new Date(rewardIn)} /> : null}
          </span>
        </p>
      </div>
    </div>,
    <div key={4} className=' relative'>
      <img src={"/img/Machine.png"} alt={`Rewards`} className={`w-[600px] rounded-[10px]`} />
      <div className="top-0 right-5 sm:right-10 flex-col absolute flex justify-center items-center h-full">
        <p className="text-[13px] sm:text-[15px] font-thin text-4">Unique Phrase Test Machine</p>
        <p className="text-[18px] sm:text-[25px] font-bold poppins-500 text-4">
          🔒 Protecting Originality,
          <br />
          Preventing Duplication 🔒
        </p>
      </div>
    </div>,
    <video key={5} autoPlay loop muted className={`w-[600px] rounded-[10px]`} >
      <source src={"/img/HelpGrow.mp4"} type="video/mp4" />
    </video>,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Change image every 3 seconds
  };

  const stopSwipe = (event: React.TouchEvent | React.MouseEvent) => {
    event.stopPropagation(); // Stops the event from propagating to the swipeable parent
  };

  return (<div
    onTouchStart={stopSwipe} // Stops touch events
    className={`rounded-[10px] justify-between w-full h-fit mt-2 mb-10 items-center cursor-pointer `}
  >
    <Slider pauseOnHover adaptiveHeight={true} swipe={false} arrows={true} touchMove={true} touchThreshold={200} {...settings}>
      {compaign.map((c) => c)}
    </Slider>
  </div>);
};

const HighTtvFirstSortner = (a: NFTStoredData, b: NFTStoredData) => Number(b.ttv) - Number(a.ttv);

export const Rank: React.FC<{}> = () => {
  const [files, setFiles] = useMarketListState();
  const [page, setPage] = usePageLength().topPageLength;
  const fileLen = files ? Object.keys(files)?.length : 0;
  const triggerRef = useRef(false);
  const [nextRewardIn, setNextRewardIn] = useState(0);

  // get the next reward time
  useEffect(() => {
    preferencesDb.getNextRewardAt().then(t => {
      console.log({ t });
      setNextRewardIn(t);
    });
  }, []);

  // function to load the more nft elements
  const loadMore = useMemo(() => () => {
    triggerRef.current = true;
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (triggerRef.current && page > 0) {
      if (!files) {
        setTimeout(() => { if (!files) setFiles([]); }, 3000);
      }
      triggerRef.current = false;
      // Need not to unmount the listners as they are needed for the whole session
      nftDbFile.getRankListner((f) => {
        if (f) setFiles(p => ({
          ...Object.fromEntries(Object.entries(p ?? {}).map(([key, value]) => [key, { ...value, highlight: false }])),
          ...f,
        }));
      }, page * 20);
    }
  }, [page]);

  useEffect(() => {
    if (fileLen == 0) {
      loadMore();
    }
  }, [fileLen]);

  if (!files) return <ListNftCardLoader />;
  if (files !== null && fileLen == 0) return <NoDataFound>No Rank Available!</NoDataFound>;

  return <div className='w-full h-full'>
    <SliderCompaign rewardIn={nextRewardIn} />
    <div className="h-full overflow-y-auto pb-[20rem]">
      {Object.values(files).sort(HighTtvFirstSortner).map((nft, i) => <NftRankCard key={nft.key} nft={nft} idx={i + 1} />)}
      {(fileLen >= page * 20) && loadMore && <>
        <br />
        <div className="w-full flex justify-center cursor-pointer"
          onClick={loadMore}
        >
          <span className="bg-lightBrand2 font-thin text-0 rounded-[5px] p-2 mr-4 mt-4 px-4 shadow-md">
            <FontAwesomeIcon icon={faAnglesDown} className='animate-pulse' /> Load More
          </span>
        </div>
      </>}
      <br />
      <br />
      <br />
    </div>
  </div>;
};


const NftRankCard: React.FC<{
  nft: NFTStoredData;
  idx: number;
}> = ({ nft, idx }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => nft.marketId && navigate(`/markets/${nft.marketId}`)}
      className={`flex flex-col ${nft.highlight ? "animate-fade" : "bg-white"} rounded-[10px] p-4 py-[0.9rem] shadow-sm justify-between w-full  items-center cursor-pointer mb-4 `}
    >
      <div className="flex w-full">
        {/* profile img section */}
        <span className="flex items-center flex-grow">
          <img
            className="w-[35px] h-[35px] rounded-[5px] mr-[10px] mt-1 img-loading"
            src={nft?.thumbnail || '/dplaceholder.png'}
            alt="user profile"
          />

          {/* demographics */}
          <span className="flex flex-col w-full">
            <span className="flex justify-between w-full pt-1 ">
              <span className="font-semibold leading-tight text-f14">
                {nft?.title || ''}
              </span>

              <span className="mt-1 font-bold text-0">
                Qty {view(BigInt(nft.sharesSupply ?? ""), 3)}
              </span>
            </span>

            {/* <PrimeText>{user.last_name}</PrimeText> */}
            <span className="flex justify-between w-full text-2 ">
              <div className="flex w-full justify-between mt-1">
                <div className="flex items-center gap-2 text-f10 !whitespace-nowrap ">
                  <DisplayPrice label='Vol :' active price={BigInt(nft.ttv)} />
                </div>
                <SecondaryBtn
                  className="p-1 text-[10px] font-semibold rounded-[4px] px-2 "
                  onClick={() => console.log}
                >
                  Rank <span className='text-f14'>
                    {`#${idx}`}
                  </span>
                </SecondaryBtn>
              </div>

            </span>
          </span>
        </span>
      </div>
    </div>
  );
};