import { cn } from '@/lib/utils';
import { Skeleton } from './ui/skeleton';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const ListLoader: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <div className={cn('flex flex-col gap-5 ', className)}>
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </div>
  );
};

export const PresentNftSkeleton: React.FC<{
  width: number;
  className?: string;
}> = ({ width, className = "" }) => {
  return <div className={twMerge(`h-full w-full p-2 brand-shadow rounded-[10px] bg-white`, className)}>
    <img height={width} width={width} alt="" className=" bg-3 rounded-lg animate-pulse" />

    <div className="p-2">
      <span className=' flex w-full justify-end text-2 animate-pulse '>
        <span className='bg-3 h-5 w-[100px] rounded-md'></span>
      </span>
      <span className=' font-bold justify-between flex text-f12 pt-1  animate-pulse'>
        <span className='text-f10 bg-3 h-5 w-[50px] rounded-md'></span>
        <span className='text-f10 bg-3 h-5 w-[70px] rounded-md'></span>
      </span>
      <span className='flex pt-1'>
        <span className='bg-3 h-5 w-[80px] rounded-md'></span>
      </span>
    </div>
  </div >;
};

export const ListNftCardLoader: React.FC<{ className?: string; }> = ({ className }) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    if (columnRef.current) {
      setWidth((columnRef.current.offsetWidth / 2) - 8);
    }
  }, [columnRef]);

  return (
    <div className="w-full">
      <div className="flex-col gap-2 w-full opacity-30" ref={columnRef}>
        <div className="flex gap-2 p-2 h-full w-full">
          <PresentNftSkeleton width={width} />
          <PresentNftSkeleton width={width} />
        </div>
        <div className="flex gap-2 p-2 h-full w-full">
          <PresentNftSkeleton width={width} />
          <PresentNftSkeleton width={width} />
        </div>
      </div>
    </div>
  );
};

export function CardSkeleton() {
  return (
    <div className="py-5 px-5 rounded-[10px] flex items-start w-full gap-4 bg-white shadow-sm ">
      <div className="flex flex-col items-center justify-center gap-3 opacity-30 ">
        <Skeleton className="block w-[35px] h-[35px] rounded-[4px]" />
      </div>
      <div className="flex flex-col w-full gap-3 opacity-30 ">
        <div className="flex gap-10 w-full justify-between">
          <Skeleton className="block w-2/3 h-5" />
          <Skeleton className="block w-1/6 h-5" />
        </div>
        <div className="flex gap-3">
          <Skeleton className="w-[80px] h-5" />
        </div>
      </div>
    </div>
  );
}
