import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MemoTImerIcon from '../../SVG/TImerIcon';
import { SecondaryBtn } from '../../components/Buttons';
import { NoDataFound } from '@/components/NoDataFound';
// @ts-ignore
import TimeAgo from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faDivide, faFeatherPointed, faMoneyCheck, faSackDollar, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PresentValue } from '@/components/DisplayPrice';
import MemoMoreIcon from '@/SVG/MoreIcon';
import { showShares } from './UserCardSm';
import { getMinNftData } from '@/Helpers/register';
import { toJSEpoch } from '@/lib/utils';

const UserActivityList: React.FC<{ data: any[]; loadMore: null | (() => void); }> = ({ data, loadMore }) => {

  const activity = useMemo(() => Object.values(data)
    .filter((d) => d.type == 'ClaimedReflectionFee' || d.type == "ClaimedCreatorFee" || d.type == "ClaimedReward" ||
      d.type == 'ClaimedOwnerFee' || d.type == "BuyShare" || "SellShare"), [data])
    .sort((a, b) => b?.doneAt - a?.doneAt);

  if (!activity.length) {
    return (
      <NoDataFound className="mt-3">No data found</NoDataFound>
    );
  }

  return (
    <div className="flex flex-col gap-[10px]">
      {activity.map((a: any, i) => {
        if (a.type == 'ClaimedReward') {
          return (
            <UserActivityCard
              key={i}
              data={{ ...a, type2: 'Reward Claimed' }}
              icon={faSackDollar}
            />
          );
        }
        if (a.type == 'ClaimedOwnerFee') {
          return (
            <UserActivityCard
              key={i}
              data={{ ...a, type2: 'Owner Fees Claimed', reward: a.fee }}
              icon={faMoneyCheck}
            />
          );
        }
        if (a.type == 'ClaimedCreatorFee') {
          return (
            <UserActivityCard
              key={i}
              data={{ ...a, type2: 'Creator Fees Claimed', reward: a.fee }}
              icon={faFeatherPointed}
            />
          );
        }
        if (a.type == 'ClaimedReflectionFee') {
          return (
            <UserActivityCard
              key={i}
              data={{
                ...a, type2: 'Dividends Claimed',
                reward: a.fee,
              }}
              icon={faDivide}
            />
          );
        }
        if (a.type == 'BuyShare' || a.type == 'SellShare') {
          return (
            <UserActivityBuySellCard
              key={i}
              activityData={a}
            />
          );
        }
      })}

      {loadMore && <>
        <div className="w-full flex justify-center cursor-pointer"
          onClick={loadMore}
        >
          <span className="bg-lightBrand2 font-thin text-0 rounded-[5px] p-2 mr-4 mt-4 px-4 shadow-md">
            <FontAwesomeIcon icon={faAnglesDown} className='animate-pulse' /> Load More
          </span>
        </div>
        <br />
        <br />
        <br />
      </>}
    </div>
  );
};

export default UserActivityList;


const UserActivityCard: React.FC<{
  data: any;
  icon?: IconDefinition;
  img?: string;
}> = ({ data, img, icon }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col shadow-sm bg-white rounded-[10px] p-3 justify-between w-full h-full items-center cursor-pointer ">
      <div className="flex w-full"
        onClick={() => { if (data?.marketId) navigate(`/markets/${data?.marketId}`); }}
      >
        {/* profile img section */}
        <span className="flex flex-grow">
          <span className='pr-2'>
            {img
              ? <img
                className="w-[35px] h-[35px] rounded-[5px] mr-[10px] mt-1 img-loading"
                src={img || '/dplaceholder.png'}
                alt="user profile"
              />
              : icon
                ? <FontAwesomeIcon
                  className={`w-[30px] h-[30px] mr-[7px] p-1 text-2 cursor-pointer`}
                  icon={icon}
                />
                : null
            }
          </span>
          {/* demographics */}
          <span className="flex flex-col w-full mt-0">
            <span className="flex justify-between w-full ">
              <span className="font-semibold text-f14 flex justify-between w-full">
                <PresentValue price={BigInt(data?.reward)} className='text-2' textSize={14} active={true} />
                {data?.marketId && <MemoMoreIcon className='scale-100 translate-y-1 ml-1 fill-brand ' />}
              </span>
              {/* <MemoRedirectIcon className='scale-150 text-brand' /> */}
            </span>
            {/* <PrimeText>{user.last_name}</PrimeText> */}
            <span className="flex justify-between w-full text-2 ">
              <div className="flex font-[500] items-center justify-between">
                <div className="flex items-center gap-2 text-f10">
                  <MemoTImerIcon />
                  <TimeAgo date={toJSEpoch(data?.doneAt)} />
                </div>
              </div>
              <SecondaryBtn
                className="p-1 text-[10px] bg-4 text-2 font-semibold rounded-[4px] px-2 "
              >
                {data?.type2.toUpperCase()}
              </SecondaryBtn>
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};



const UserActivityBuySellCard: React.FC<{
  activityData: BuySellActivityForUser;
}> = ({ activityData }) => {
  const navigate = useNavigate();

  const [minNft, setMinNft] = useState<{
    thumbnail: string;
    title: string;
  } | null>(null);

  useEffect(() => {
    getMinNftData(activityData.marketId).then((nft) => setMinNft(nft));
  }, [activityData.marketId]);

  return (
    <div
      onClick={() => activityData.marketId && navigate(`/markets/${activityData.marketId}`)}
      className={`flex flex-col ${activityData.highlight ? "animate-fade" : "bg-white"} rounded-[10px] p-4 py-[0.9rem] shadow-sm justify-between w-full h-full items-center cursor-pointer `}
    >
      <div className="flex w-full">
        {/* profile img section */}
        <span className="flex items-center flex-grow">
          <img
            className="w-[35px] h-[35px] rounded-[5px] mr-[10px] mt-1 img-loading"
            src={minNft?.thumbnail || '/dplaceholder.png'}
            alt="user profile"
          />

          {/* demographics */}
          <span className="flex flex-col w-full">
            <span className="flex justify-between w-full pt-1 ">
              <span className="font-semibold leading-tight text-f14">
                {minNft?.title || ''}
              </span>

              <span className="mt-1 font-semibold text-2">
                {showShares(activityData.qty)}
              </span>
            </span>

            {/* <PrimeText>{user.last_name}</PrimeText> */}
            <span className="flex justify-between w-full text-2 ">
              <div className="flex w-full justify-between mt-1">
                <div className="flex items-center gap-2 text-f10 !whitespace-nowrap ">
                  <MemoTImerIcon />
                  <TimeAgo date={toJSEpoch(activityData?.doneAt)} />
                </div>
                <SecondaryBtn
                  className="p-1 text-[10px] font-semibold rounded-[4px] px-2 "
                  onClick={() => console.log}
                >
                  {activityData.type}
                </SecondaryBtn>
              </div>

            </span>
          </span>
        </span>
      </div>
    </div>
  );
};