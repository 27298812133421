import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twJoin } from 'tailwind-merge';


export const Tablist: React.FC<{
  tablist: string[];
  activeTab: string;
  className?: string;
  onTabSelect: (tab: string) => void;
}> = ({ tablist, onTabSelect, activeTab, className }) => {
  return (
    <div className={twJoin('flex gap-[3px] w-full overflow-x-auto', className)}>
      {tablist.map((tab) => (
        <button
          key={tab}
          className={
            ' text-2  text-f14 font-[500] px-2 py-1 rounded-[5px] cursor-pointer' +
            (activeTab == tab ? ` text-brand bg-lightBrand2` : '')
          }
          onClick={() => onTabSelect(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};


export const SearchTab: React.FC<{ keyword: string; onClose?: () => void; }> = ({
  keyword,
  onClose,
}) => {
  return (
    <button
      key={keyword}
      className={
        ' flex gap-4 text-f12 font-[600] px-3 py-1 rounded-[5px]  text-brand bg-lightBrand2'
      }
    >
      {keyword}
      <FontAwesomeIcon
        onClick={onClose}
        icon={faXmark} className='text-[1.2rem] mt-1' />
    </button>
  );
};
