import { useState } from 'react';
import { PrimaryButton } from './Buttons';
import { googleFonts } from '@/utils/googleFontList';
import { presentName } from '@/utils/getSanitizeInput';

export const SetFont = ({ options, selectedOption, setSelectedOption }:
  { options: Array<string>; selectedOption: string, setSelectedOption: (o: string) => void; }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleSearchChange = (e: any) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = options.filter(option =>
      option.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filtered);
  };

  const renderOptions = () => {
    return filteredOptions.map((option, index) => (
      <div
        key={index}
        className="py-1 pl-2 hover:bg-slate-200 hover:rounded-[4px]"
        onClick={() => handleSelectOption(option)}
      >
        {option}
      </div>
    ));
  };

  return (
    <div className="dropdown-container text-f12 w-full">

      <div className="flex justify-evenly w-full text-2 select-none">
        <div className="w-1/2">
          <div className=" p-2 border-[1px] border-slate-300 rounded-[4px]">
            {presentName(selectedOption, 20) || 'Select an option'}
          </div>
          <div className="w-full flex flex-col justify-between h-full">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className=" text-f12 p-2 rounded-[4px] mt-1 placeholder:text-3 outline-none focus:border-brand2 border-[1px] border-slate-300"
            />
            <PrimaryButton
              onClick={() => {
                const i = Math.floor(Math.random() * googleFonts.length);
                setSelectedOption(googleFonts[i]);
              }}
              className="p-2 py-3 text-f12 text-white mb-12 min-w-fit font-semibold rounded-[4px]"
            >
              Pick Random
            </PrimaryButton>
          </div>

        </div>
        <div className="w-1/2 relative max-h-[15rem] text-2  overflow-y-scroll">
          {filteredOptions.length > 0 ? (
            <p className="overflow-y-hidden cursor-pointer ml-4">
              {renderOptions()}
            </p>
          ) : (
            <div className="dropdown-option">No options found</div>
          )}
        </div>

      </div>

    </div>
  );
};