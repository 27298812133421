import useDrawerState from '@/atoms/drawerState';
import { useNftSotredDataState } from '@/atoms/marketState';
import { nftDbFile, nftDbFileMatrics } from '@/firebase/realtimeDb';
import { formatAddress, formatString } from '@/Helpers/web3utils';
import { formatDate, wrapCount } from '@/lib/utils';
import ExportIcon from '@/SVG/ExportIcon';
import { faBookmark, faCaretDown, faCaretUp, faEye, faFlag, faHeart, faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayPrice } from './DisplayPrice';
import { useReportIssueModal } from '@/atoms/modalState';
import { useProtection } from '@/Helpers/useProtection';
import CopyIcon from '@/SVG/CopyIcon';
import toast from 'react-hot-toast';

export const NftInfoDrawer: React.FC<{}> = () => {
  const [protect] = useProtection();
  const [nftData,] = useNftSotredDataState();
  // const [opened, setOpened] = useState(false);
  const [nftOpen, setNftOpen] = useState(false);
  const navigate = useNavigate();
  const direct = (url: string) => window.open(`${import.meta.env.VITE_IPFS_GATEWAY_URL}/${url}`, '_blank');
  const drawer = useDrawerState();
  const { openReportModal } = useReportIssueModal();
  const [matrix, setMatrix] = useState({
    seen: 0,
    likes: 0,
    shared: 0,
    watched: 0,
    holders: 0
  });

  useEffect(() => {
    if (nftData?.marketId) {
      nftDbFileMatrics.getSeenCount(nftData.marketId).then((seen) => {
        setMatrix((prev) => ({ ...prev, seen }));
      });
      nftDbFileMatrics.getSharedCount(nftData.marketId).then((shared) => {
        setMatrix((prev) => ({ ...prev, shared }));
      });
      nftDbFile.getLikeCount(nftData.marketId).then((likes) => {
        setMatrix((prev) => ({ ...prev, likes }));
      });
      nftDbFile.getWatchlistCount(nftData.marketId).then((watched) => {
        setMatrix((prev) => ({ ...prev, watched }));
      });
      nftDbFile.getHoldersCount(nftData.marketId).then((holders) => {
        setMatrix((prev) => ({ ...prev, holders }));
      });
    }
  }, [nftData?.marketId]);

  return (
    <div className='flex flex-col text-2 relative select-none '>
      <div className="flex justify-between mb-4 select-none">
        <div className="w-[65px] h-[60px] bg-white shadow-md rounded-[5px]">
          <div className=" text-[18px] mt-1 text-brand font-bold h-[30px] ">{wrapCount(matrix.seen)}</div>
          <div className="">
            <FontAwesomeIcon icon={faEye} className='text-[17px] text-2' />
          </div>
        </div>
        <div className="w-[65px] h-[60px] bg-white shadow-md rounded-[5px]">
          <div className=" text-[18px] mt-1 text-brand font-bold h-[30px] ">{wrapCount(matrix.likes)}</div>
          <div className="">
            <FontAwesomeIcon icon={faHeart} className='text-[17px] text-2' />
          </div>
        </div>
        <div className="w-[65px] h-[60px] bg-white shadow-md rounded-[5px]">
          <div className=" text-[18px] mt-1 text-brand font-bold h-[30px] ">{wrapCount(matrix.holders)}</div>
          <div className="">
            <FontAwesomeIcon icon={faUser} className='text-[17px] text-2' />
          </div>
        </div>
        <div className="w-[65px] h-[60px] bg-white shadow-md rounded-[5px]">
          <div className=" text-[18px] mt-1 text-brand font-bold h-[30px] ">{wrapCount(matrix.watched)}</div>
          <div className="">
            <FontAwesomeIcon icon={faBookmark} className='text-[17px] text-2' />
          </div>
        </div>
        <div className="w-[65px] h-[60px] bg-white shadow-md rounded-[5px]">
          <div className=" text-[18px] mt-1 text-brand font-bold h-[30px] ">{wrapCount(matrix.shared)}</div>
          <div className="">
            <FontAwesomeIcon icon={faPaperPlane} className='text-[17px] text-2' />
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-between mb-4 w-full select-none">
        <div className="flex justify-between w-full bg-white p-4 rounded-[5px] shadow-md">
          <div className="text-[14px]">Trading Volume</div>
          <div className=" text-[15px] font-bold mr-5 ">
            <DisplayPrice active price={BigInt(nftData.ttv)} compact className='scale-[1.44] mt-1' />
          </div>
        </div>
        <div className="w-[40px] pt-5 bg-white p-4 rounded-[5px] cursor-pointer shadow-md"
          onClick={() => protect(() => {
            drawer.closeDrawer();
            openReportModal();
          })}
        >
          <FontAwesomeIcon icon={faFlag} className='text-[18px] text-brand' />
        </div>
      </div>


      <details className="my-3 pl-8  poppins-700 ">
        <summary className="flex flex-col justify-between"
          onClick={() => setNftOpen(!nftOpen)}
        >
          <div className="text-f14 flex gap-2 text-0 cursor-pointer ml-[-1rem] ">NFT Details <FontAwesomeIcon className='text-[16px] pt-[0.3rem] text-2' icon={nftOpen ? faCaretUp : faCaretDown} /> </div>
        </summary>

        {/* <center className=" text-md font-bold mb-4">Info</center> */}
        <div className="flex justify-between my-3 poppins-700 ml-[-1rem]">
          <div className="text-f14 text-2">URIs</div>
        </div>

        <div className="flex justify-between mt-1">
          <div className="text-f12 text-2">NFT Token ID</div>
          <div className="text-f12 text-light3Brand underline flex cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(nftData?.marketId as string);
              toast('NFT Token ID copied!', { id: "Tokenid copied" });
            }}
          >{formatString(nftData?.marketId ?? "")}
            <CopyIcon className=' ml-1' fill='#8086cc' />
          </div>
        </div>

        <div className="flex justify-between mt-1">
          <div className="text-f12 text-2">NFT URI</div>
          <div className="text-f12 text-light3Brand underline flex cursor-pointer"
            onClick={() => direct(nftData?.key)}
          >{formatString(nftData?.key ?? "")}
            <ExportIcon className=' ml-1' fill='#8086cc' />
          </div>
        </div>

        {nftData?.thumbCid && <div className="flex justify-between mt-1">
          <div className="text-f12 text-2">Thumbnail URI</div>
          <div className="text-f12 text-light3Brand underline flex cursor-pointer"
            onClick={() => direct(nftData?.thumbCid ?? "")}
          >{formatString(nftData?.thumbCid)}
            <ExportIcon className=' ml-1' fill='#8086cc' />
          </div>
        </div>}

        {nftData?.bgCid && <div className="flex justify-between">
          <div className="text-f12 text-2">Background URI</div>
          <div className="text-f12 text-light3Brand underline flex cursor-pointer"
            onClick={() => direct(nftData?.bgCid ?? "")}
          >{formatString(nftData?.bgCid)}
            <ExportIcon className=' ml-1' fill='#8086cc' />
          </div>
        </div>}

        {nftData?.audioCid && <div className="flex justify-between">
          <div className="text-f12 text-2">Audio URI</div>
          <div className="text-f12 text-light3Brand underline flex cursor-pointer"
            onClick={() => direct(nftData?.audioCid ?? "")}
          >{formatString(nftData?.audioCid)}
            <ExportIcon className=' ml-1' fill='#8086cc' />
          </div>
        </div>}

        <div className="flex justify-between my-3 poppins-700 ml-[-1rem]">
          <div className="text-f14 text-2">Ownership Details</div>
        </div>

        <div className="flex justify-between mt-3">
          <div className="text-f12 text-2">Creator</div>
          <div onClick={() => {
            drawer.closeDrawer();
            navigate(`/profile/${nftData?.creator}`);
          }} className="text-f12 text-2 underline flex cursor-pointer">
            {formatAddress(nftData?.creator)}
            <ExportIcon className='ml-1' fill='#8086cc' />
          </div>
        </div>

        <div className="flex justify-between mt-2">
          <div className="text-f12 text-2">Owner</div>
          <div onClick={() => {
            drawer.closeDrawer();
            navigate(`/profile/${nftData?.owner}`);
          }} className="text-f12 text-2 underline flex cursor-pointer">
            {formatAddress(nftData?.owner)}
            <ExportIcon className='ml-1' fill='#8086cc' />
          </div>
        </div>

        <div className="flex justify-between my-3 poppins-700 ml-[-1rem]">
          <div className="text-f14 text-2">Basic Details</div>
        </div>

        <div className="flex justify-between">
          <div className="text-f12 text-2">Font</div>
          <div className="text-f12 text-2 flex cursor-pointer">{nftData?.font}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-f12 text-2">Font Color</div>
          <div className="text-f12 text-2 flex cursor-pointer">{nftData?.fontColor}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-f12 text-2">Background Color</div>
          <div className="text-f12 text-2 flex cursor-pointer">{nftData?.backgroundColor}
          </div>
        </div>
        {/* <div className="flex justify-between">
          <div className="text-f12 text-2">Created On</div>
          <div className="text-f12 text-2  flex cursor-pointer">{formatDate(parseInt(nftData?.createdOn))}
          </div>
        </div> */}
        <div className="flex justify-between">
          <div className="text-f12 text-2">Minted On</div>
          <div className="text-f12 text-2  flex cursor-pointer">{formatDate(parseInt(nftData?.mintedOn) * 1000)}
          </div>
        </div>
      </details>

      {/* <details className="my-3 pl-8  poppins-700 ml-[-1rem]">
        <summary className="flex flex-col justify-between"
          onClick={() => setOpened(!opened)}
        >
          <div className="text-f14 flex text-0 cursor-pointer  justify-between ">
            <span className='flex gap-2'>
              Message by NFT Owner <FontAwesomeIcon className='text-[16px] pt-[0.3rem] text-2' icon={nftOpen ? faCaretUp : faCaretDown} />
            </span>
            <FontAwesomeIcon icon={faPen} className='text-brand translate-y-2' />
          </div>
        </summary>

        <div className="text-f10 text-red-400">(Click only if you trust the source)</div>
        <div className="flex justify-between mt-3">
          <div className="text-f12 text-2">Creator</div>
          <div onClick={() => navigate(`/profile/${nftData?.creator}`)} className="text-f12 text-2 underline flex cursor-pointer">
            {formatAddress(nftData?.creator)}
            <ExportIcon className='ml-1' fill='#8086cc' />
          </div>
        </div>

        <div className="flex justify-between mt-2">
          <div className="text-f12 text-2">Owner</div>
          <div onClick={() => navigate(`/profile/${nftData?.owner}`)} className="text-f12 text-2 underline flex cursor-pointer">
            {formatAddress(nftData?.owner)}
            <ExportIcon className='ml-1' fill='#8086cc' />
          </div>
        </div>
      </details> */}


    </div>
  );
};
