import * as React from "react";

function PrivacyPolicy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} fill="none" {...props}>

      <path d="M6.98335 3.77191C6.98335 4.11831 6.99351 4.46493 6.9815 4.81089C6.94477 5.85255 6.47623 6.65643 5.55117 7.20307C5.0898 7.47572 4.59631 7.68178 4.08966 7.85967C4.03883 7.87755 3.97021 7.88112 3.92054 7.86325C3.29999 7.63887 2.69168 7.39057 2.15568 7.00283C1.48822 6.51988 1.1158 5.87155 1.04533 5.07527C1.00213 4.58673 1.0116 4.0935 1.00306 3.60228C0.997279 3.27823 1.00444 2.95395 1.00005 2.6299C0.997972 2.47793 1.05873 2.38116 1.20613 2.31702C2.10046 1.92838 2.99133 1.53236 3.88658 1.14551C3.95127 1.11757 4.05131 1.11847 4.116 1.1464C5.01471 1.53482 5.90951 1.93151 6.80684 2.32283C6.945 2.38317 7.00114 2.47837 6.99998 2.62096C6.99698 3.00468 6.99883 3.38841 6.99883 3.77213C6.99374 3.77191 6.98843 3.77191 6.98335 3.77191ZM6.47438 3.78397C6.48154 3.78397 6.48894 3.78397 6.4961 3.78397C6.4961 3.45612 6.49448 3.12827 6.49772 2.80042C6.49841 2.73941 6.47877 2.70923 6.42078 2.68376C5.6355 2.34138 4.85184 1.99565 4.06609 1.65371C4.02982 1.63785 3.97345 1.63718 3.93741 1.65304C3.14843 1.99632 2.36061 2.34227 1.57417 2.69113C1.54229 2.70521 1.50648 2.75371 1.50671 2.78611C1.51087 3.51981 1.49862 4.25441 1.53397 4.98699C1.564 5.61208 1.8357 6.14241 2.35113 6.54088C2.83261 6.91299 3.3887 7.14765 3.95797 7.35795C3.98939 7.36957 4.03444 7.36242 4.06771 7.35012C4.52169 7.18363 4.9625 6.99054 5.37166 6.7342C6.04789 6.3107 6.42771 5.71377 6.47092 4.93403C6.49194 4.55187 6.47438 4.16747 6.47438 3.78397Z" fill="#8F97AD" stroke="#8F97AD" strokeWidth="0.5" />
      <circle cx="4" cy="4" r="1" fill="#8F97AD" />
      <path d="M4 4V6" stroke="#8F97AD" />

    </svg>
  );
}

const MemoPrivacyPolicy = React.memo(PrivacyPolicy);
export default MemoPrivacyPolicy;
