import { ListLoader } from "@/components/ListLoader";
import { UserCardList } from "@/components/UserCardList";
import { appConfig } from "@/config";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useAccount, useContractWrite, usePublicClient } from "wagmi";
import MarketActivityList from "./MarketActivityList";
import PrimeFadeText from "@/components/PrimeFadeText";
import MemoButtonLoader from "@/components/ButtonLoader";
import { PrimaryBtn } from "@/components/Buttons";
import { useHoldersNftState, useMarketActivityDataState, useNftSotredDataState, useUserAddressMapState, useWatchedByNftState } from "@/atoms/marketState";
import { mainDbBuySellActivity, nftDbFile } from "@/firebase/realtimeDb";
import { NoDataFound } from "@/components/NoDataFound";
import { DisplayPrice } from "@/components/DisplayPrice";
import ShowPrompt from "@/components/ShowPrompt";
import { makeBuySellActivity, makeUserAddressMap, sortOnShares } from "../helpers";
import { getOtherUserData } from "@/Helpers/register";
import { PhraseTradeMainAbi } from "@/ABI/PhraseTradeMain";
import useDrawerState from "@/atoms/drawerState";


export const HoldersTab: React.FC<{ nft: NFTStoredData; }> = ({ nft, ...props }) => {
  const [users, setUsers] = useHoldersNftState();
  const [realtimeData, setRealtimeData] = useState<Record<string, Holders> | null>(null);
  const [realtimeUpdate, setRealtimeUpdate] = useState<Record<string, HoldingData> | null>(null);

  useEffect(() => {
    if ((!users || users.length == 0) && nft?.marketId) {
      nftDbFile.getHoldersLastBatch(nft.marketId).then(f => {
        setUsers(f.sort(sortOnShares as any) ?? []);
      });
      const unSubData = nftDbFile.getHoldersLastBatchListner(nft?.marketId, setRealtimeData);
      const unSubUpdate = nftDbFile.getHoldersSharesUpdateListner(nft?.marketId, setRealtimeUpdate);
      return () => {
        setUsers(null);
        unSubUpdate();
        unSubData();
      };
    }
  }, [nft]);

  // addes new users in realtime
  useEffect(() => {
    if (realtimeData) {
      const newUsers: any = [];
      for (let [k, v] of Object.entries(realtimeData)) {
        newUsers.push({ ...v, public_address: k, highlight: true });
      }
      const allUsesPromises = newUsers.map(async (v: any) => {
        return { ...v, ...await getOtherUserData(v.public_address) };
      });
      Promise.all(allUsesPromises).then((newUsers) => {
        setUsers((u) => {
          return [
            ...newUsers,
            ...(u?.map(p => ({ ...p, highlight: false })) ?? [])
          ].sort(sortOnShares as any).filter(d => Number(d.shares) > 0);
        });
      });
    }
  }, [realtimeData]);

  // updates the shares value in realtime
  useEffect(() => {
    if (realtimeUpdate) {
      const address = Object.keys(realtimeUpdate)[0];
      const shares = realtimeUpdate[address].shares;
      // @ts-ignore
      setUsers((u) => u?.map(
        (v) => v.public_address == address
          ? { ...v, shares, highlight: true }
          : { ...v, highlight: false }
      ).sort(sortOnShares as any)
      );
    }
  }, [realtimeUpdate]);



  return (<div {...props} className="h-full px-6 pb-[50rem] overflow-y-auto">
    {!users && <ListLoader />}
    {users && users.length == 0 && <NoDataFound>No One's holding it!</NoDataFound>}
    {users && users.length > 0 && <UserCardList users={users as Holders[]} />}
  </div>);
};


export const WatchListedByTab: React.FC<{ nft: NFTStoredData; }> = ({ nft, ...props }) => {
  const [users, setUsers] = useWatchedByNftState();
  const [realtimeWatchlist, setRealtimeWatchlist] = useState<any>(null);
  const [realtimeWatchUpdate, setRealtimeWatchUpdate] = useState<any>(null);

  useEffect(() => {
    if ((!users || users.length == 0) && nft?.marketId) {
      nftDbFile.getWatchedByLastBatch(nft.marketId).then(f => {
        setUsers(f ?? []);
      });
      const unSubAdd = nftDbFile.getWatchedByLastBatchListner(nft?.marketId, setRealtimeWatchlist);
      const unSubDelete = nftDbFile.getWatchedByLastBatchDeleteListner(nft?.marketId, setRealtimeWatchUpdate);
      return () => {
        setUsers(null);
        unSubAdd();
        unSubDelete();
      };
    }
  }, [nft]);


  // addes new users in realtime
  useEffect(() => {
    if (realtimeWatchlist) {
      const newUsers: any = [];
      for (let [k, v] of Object.entries(realtimeWatchlist)) {
        newUsers.push({ since: v, public_address: k, highlight: true });
      }
      const allUsesPromises = newUsers.map(async (v: any) => {
        return { ...v, ...await getOtherUserData(v.public_address) };
      });
      Promise.all(allUsesPromises).then((newUsers) => {
        console.log({ newUsers });
        setUsers((u) => {
          return [
            ...newUsers,
            ...(u?.map(p => ({ ...p, highlight: false })) ?? [])
          ];
        });
      });
    }
  }, [realtimeWatchlist]);

  // Delete users in realtime
  useEffect(() => {
    if (realtimeWatchUpdate) {
      const address = new Set(Object.keys(realtimeWatchUpdate));
      // @ts-ignore
      setUsers((u) => u?.filter((v) => !address.has(v.public_address)));
    }
  }, [realtimeWatchUpdate]);


  return (<div {...props} className="h-full px-6 pb-[50rem] overflow-y-auto">
    {!users && <ListLoader />}
    {users && users.length == 0 && <NoDataFound>No One's watching it!</NoDataFound>}
    {users && users.length > 0 && <UserCardList users={users as Holders[]} />}
  </div>);

};


export const MarketActivityTab: React.FC<{ nft: NFTStoredData; }> = ({ nft, ...props }) => {
  const [activity, setActivity] = useMarketActivityDataState();
  const [userAddressMap, setUserAddrMap] = useUserAddressMapState();
  const [page, setPage] = useState(0);
  const triggerRef = useRef(false);

  const loadNextPage = useMemo(() => () => {
    triggerRef.current = true;
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (!activity) {
      loadNextPage();
    }
  }, [nft]);

  useEffect(() => {
    if (triggerRef.current && page > 0) {
      triggerRef.current = false;
      const unSub = mainDbBuySellActivity.getLastBatchListner(
        nft.marketId,
        (realAct) => {
          setActivity(p => [...makeBuySellActivity(realAct, true),
          ...(p?.map((v) => ({ ...v, highlight: false })) ?? [])]); // set activity
        },
        page * 20
      );
      // unmount
      return () => {
        unSub(); // unsubscribe
        setUserAddrMap(null);
        setActivity(null);
      };
    }
  }, [page]);

  useEffect(() => {
    makeUserAddressMap(activity).then(u => {
      setUserAddrMap({ ...u, ...userAddressMap });
    });
  }, [activity]);

  return (<div {...props} className="h-full px-6 pb-[50rem] overflow-y-auto">
    {!activity
      ? <ListLoader />
      : activity?.length > 0 && userAddressMap
        ? <MarketActivityList
          loadMore={activity?.length >= page * 20 ? loadNextPage : undefined}
          userAddrMap={userAddressMap} data={activity} />
        : <NoDataFound>No Activity found!</NoDataFound>}
  </div>);
};


export const ClaimMarketRewards: React.FC<{}> = () => {
  const [nft,] = useNftSotredDataState();
  const account = useAccount();
  const drawerManager = useDrawerState();
  const { earnedDividends, earnedRewards } = drawerManager.drawerState?.screen == "claim-reward"
    ? drawerManager.drawerState?.options
    : {
      earnedDividends: BigInt(0),
      earnedRewards: BigInt(0),
    };

  const [loading, setLoading] = useState({
    rewards: false,
    reflection: false,
    ownerFees: false,
    creatorFees: false,
  });
  const [claimed, setClaimed] = useState({
    rewards: false,
    reflection: false,
    ownerFees: false,
    creatorFees: false,
  });


  console.log({ earnedDividends, earnedRewards, nft });


  const { waitForTransactionReceipt } = usePublicClient();

  const { writeAsync: claimRewardAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'claimRewards',
  });

  const { writeAsync: claimReflectionAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'claimReflectionFees',
  });


  const claimRewards = useMemo(() => async () => {
    if (!nft?.marketId || !account?.address) return;
    setLoading(p => ({ ...p, rewards: true }));
    const argPack = {
      args: [BigInt(nft?.marketId), account?.address] as [bigint, `0x${string}`]
    };
    const { hash } = await claimRewardAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    setLoading(p => ({ ...p, rewards: false }));
    setClaimed(p => ({ ...p, rewards: false }));
    toast.success('Rewards claimed');
  }, [nft, account]);

  const claimReflection = useMemo(() => async () => {
    if (!nft?.marketId || !account?.address) return;
    setLoading(p => ({ ...p, reflection: true }));
    const argPack = {
      args: [BigInt(nft?.marketId), account?.address] as [bigint, `0x${string}`]
    };
    const { hash } = await claimReflectionAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    setLoading(p => ({ ...p, reflection: false }));
    setClaimed(p => ({ ...p, reflection: false }));
    toast.success('Dividends claimed');
  }, [nft, account]);

  return (
    <div className=" py-5">
      <div className="flex flex-col gap-[10px] text-left  pr-6 pl-6">

        <ShowPrompt step="" message={
          (<>It's recommended to claim an amount of at least <span className="text-brand">$1</span></>) as any
        } className="mb-2" />

        <div className="flex flex-col bg-white p-4 rounded-[10px]  shadow-sm">
          <span className="font-semibold text-f14">
            Collected Rewards
          </span>
          <span className="flex justify-between">
            <PrimeFadeText classname=" text-[12px] text-2  ">
              <DisplayPrice
                active={!!earnedRewards}
                price={earnedRewards as any ?? BigInt(0)}
              />
            </PrimeFadeText>
            <PrimaryBtn
              className={`p-1 text-[white] text-[12px] mr-2 w-[50px] h-fit min-w-fit font-semibold rounded-[4px] 
                ${(!!earnedRewards && !claimed.rewards) ? (loading.rewards ? '' : 'animate-shine') : 'bg-3 cursor-not-allowed'
                }`}
              onClick={() => claimRewards()}
            >
              <MemoButtonLoader
                className="mx-auto h-[2.4rem] mt-[-0.64rem]"
                loading={loading.rewards}
              />{' '}
              {loading.rewards ? '' : 'Claim'}
            </PrimaryBtn>
          </span>
        </div>

        {/* Claim Reflection Card */}
        <div className="flex flex-col bg-white p-4 rounded-[10px] shadow-sm ">
          <span className="font-semibold text-f14">Collected Dividend</span>
          <span className="flex justify-between">
            <PrimeFadeText classname=" text-[12px] text-2 ">
              <DisplayPrice
                active={!!earnedDividends}
                price={earnedDividends as any ?? BigInt(0)}
              />
            </PrimeFadeText>
            <PrimaryBtn
              className={`p-1 text-[white] text-[12px] mr-2 w-[50px] h-fit min-w-fit font-semibold rounded-[4px] 
                ${(!!earnedDividends && !claimed.reflection) ? (loading.reflection ? '' : 'animate-shine') : 'bg-3 cursor-not-allowed'
                }`}
              onClick={() => claimReflection()}
            >
              <MemoButtonLoader
                className="mx-auto h-[2.4rem] mt-[-0.64rem]"
                loading={loading.reflection}
              />{' '}
              {loading.reflection ? '' : 'Claim'}
            </PrimaryBtn>
          </span>
        </div>

      </div>
    </div>
  );
};
