import * as React from 'react';

function MoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="20" rx="5" fill="#ECF2FE" />
      <g clip-path="url(#clip0_8_1014)">
        <path d="M6.85981 12.5875C6.77034 12.5871 6.68456 12.5517 6.62075 12.489C6.58929 12.4577 6.56432 12.4204 6.54729 12.3794C6.53025 12.3384 6.52148 12.2944 6.52148 12.2499C6.52148 12.2055 6.53025 12.1615 6.54729 12.1205C6.56432 12.0795 6.58929 12.0422 6.62075 12.0109L8.63169 9.99995L6.62075 7.98901C6.55735 7.92561 6.52173 7.83962 6.52173 7.74995C6.52173 7.70555 6.53047 7.66159 6.54746 7.62057C6.56445 7.57955 6.58936 7.54228 6.62075 7.51089C6.65214 7.47949 6.68941 7.45459 6.73043 7.4376C6.77145 7.42061 6.81542 7.41187 6.85981 7.41187C6.94948 7.41187 7.03547 7.44748 7.09888 7.51089L9.34888 9.76089C9.38034 9.79224 9.4053 9.8295 9.42234 9.87052C9.43937 9.91155 9.44814 9.95553 9.44814 9.99995C9.44814 10.0444 9.43937 10.0884 9.42234 10.1294C9.4053 10.1704 9.38034 10.2077 9.34888 10.239L7.09888 12.489C7.03507 12.5517 6.94929 12.5871 6.85981 12.5875Z" fill="#3C32A3" stroke="#7D38ED" />
      </g>
      <defs>
        <clipPath id="clip0_8_1014">
          <rect width="7.2" height="7.2" fill="white" transform="translate(4.3999 6.3999)" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoMoreIcon = React.memo(MoreIcon);
export default MemoMoreIcon;
