import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Tablist } from '../../components/Tablist';
import useUserState from '../../atoms/userState';
import { HoldersTab, MarketActivityTab, WatchListedByTab } from './components/Tabs';
import Typewriter from 'typewriter-effect';
import SwipeableViews from 'react-swipeable-views';
import { twMerge } from 'tailwind-merge';
import { DisplayPrice } from '@/components/DisplayPrice';
import useDrawerState, { useBuyBtnDrawer, useNFTShowState } from '@/atoms/drawerState';
import toast from 'react-hot-toast';
import { useMarketMintedState, useMarketState, useNftSotredDataState } from '@/atoms/marketState';
import { appConfig, marketViewTabs } from '@/config';
import { getOtherUserData } from '@/Helpers/register';
import { nftDbFile, nftDbFileMatrics, } from '@/firebase/realtimeDb';
import { loadFont } from '../NftGeneratePage.tsx/helpers';
import BackgroundAudio from '@/components/BackgroundAudio';
import ControlIcon from '@/components/ControlIcon';
import { faBookmark as faBellOn, faEllipsisVertical, faHeart as faHeartOn, faPause, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import Tilt from 'react-parallax-tilt';
import { Skeleton } from '@/components/ui/skeleton';
import { faBookmark as faBellOff, faPaperPlane, faHeart as faHeartOff } from '@fortawesome/free-regular-svg-icons';
import { useModalState } from '@/atoms/modalState';
import { SecondaryBtn, UnderlineButton } from '@/components/Buttons';
import { pickNumFromRem } from '@/lib/utils';
import { useAccount, useContractRead } from 'wagmi';
import { PhraseTradeMainAbi } from "@/ABI/PhraseTradeMain";
import { axiosInstance } from '@/config/axiosInstance';
import { useProtection } from '@/Helpers/useProtection';
import { useAudioON } from '@/atoms/audioState';


const ScrollableComponent: React.FC<{ nft: NFTStoredData; owner: MinimalUser | null; }> = ({ nft, owner }) => {
  const [isShow, setIsShow] = useNFTShowState();
  const [nftShow, setNftShow] = useState(false);
  const bottomSectionRef = useRef(null);
  const nftSectionRef = useRef(null);
  const [activeTab, setActiveTab] = useState(marketViewTabs[0]);
  const [hideOnce, setHideOnce] = useState(true);
  const [font, setFont] = useState(''); // Initial font
  const [phrase, setPhrase] = useState('');
  // const [author, setAuthor] = useState('');
  // const [title, setTitle] = useState('');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [fontSize, setFontSize] = useState<string>('1rem'); // Initial font size
  // const [fontSizePercent, setFontSizePercent] = useState(70); // Initial font size factor
  const [opacityPercent, setOpacityPercent] = useState(100); // Initial opacity factor
  const [paddingTop, setPaddingTop] = useState(15); // Initial padding top factor
  const [backgroundColor, setBackgroundColor] = useState('transparent'); // Initial background color
  const [fontColor, setFontColor] = useState('black'); // Initial font color
  const [openControl, setOpenControl] = useState("image");
  const [fontWeight, setFontWeight] = useState<number | string>(600);
  const [lineHeight, setLineHeight] = useState(120);
  const [isCapturing, setIsCapturing] = useState(false);
  // const [isTyping, setIsTyping] = useState(false);
  const [isPlay, setIsPlay] = useState(true);
  useEffect(() => { if (!isPlay) setIsPlay(true); }, [isPlay]);
  const [playPause, setPlayPause] = useState(false);
  const [proportion, setProportion] = useState(1);
  const squareRef = useRef(null);
  const [userState,] = useUserState();
  const tabList = useMemo(() => userState ? [...marketViewTabs] : marketViewTabs, [userState]);
  const [mountedTabs, setMountedTabs] = useState([true, false, false, false]);
  const dimension = useMemo(() => nft?.dimension ? parseInt(nft.dimension) : 0, [nft]);
  const [btnDrawer, setBtnDrawer] = useBuyBtnDrawer();
  const [onceShow, setOnceShow] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [nftData, setNftData] = useNftSotredDataState();
  const [, setIsMinted] = useMarketMintedState();
  const [watching, setWatching] = useState(false);
  const [heart, setHeart] = useState(false);
  const [heartCount, setHeartCount] = useState(0);
  const heartMutex = useRef(false);
  const navigate = useNavigate();
  const drawerManager = useDrawerState();
  const { openModal, closeModal } = useModalState();
  const [protect] = useProtection();
  const [isAudioOn,] = useAudioON();

  useEffect(() => { setIsShow(false); }, []);

  // const handleSpeak = () => {
  //   const utterance = new SpeechSynthesisUtterance(phrase);
  //   speechSynthesis.speak(utterance);
  // };


  // when font is changed, load the font in link element 
  useEffect(() => {
    if (font) loadFont(font);
  }, [font]);

  useEffect(() => {
    if (isPlay) setTrigger(p => p + 1);
  }, [isPlay]);

  useEffect(() => {
    console.log({ proportion });
    if (nft?.font) setFont(nft.font);
    if (nft?.fontSize) setFontSize((pickNumFromRem(nft.fontSize) * proportion).toFixed(2) + 'rem');
    if (nft?.background) setBackgroundImageUrl(nft.background);
    if (nft?.backgroundColor) setBackgroundColor(nft.backgroundColor);
    if (nft?.opacity) setOpacityPercent(parseFloat(nft.opacity) * 100);
    if (nft?.paddingTop) setPaddingTop(pickNumFromRem(nft.paddingTop) * proportion);
    if (nft?.fontColor) setFontColor(nft.fontColor);
    if (nft?.fontWeight) setFontWeight(nft.fontWeight);
    if (nft?.lineHeight) setLineHeight(parseInt(nft.lineHeight) - 5);
    if (nft?.phrase) setPhrase(nft.phrase);
  }, [nft, proportion]);

  useEffect(() => {
    if (nftData && nftData?.minted) {
      setIsMinted(true);
    } else {
      setIsMinted(false);
    }
  }, [nftData]);

  useEffect(() => setNftData(nft), []);

  // set Watching and Liked status
  useEffect(() => {
    if (nft?.marketId && userState?.public_address) {
      nftDbFile.isWatchlisted(
        userState?.public_address as string,
        nft?.marketId as string).then(w => {
          console.log({ isWatchlisted: !!w, w });
          setWatching(!!w);
        });
      nftDbFile.isLiked(
        userState?.public_address as string,
        nft?.marketId as string).then(l => {
          console.log({ liked: l });
          setHeart(l);
        });
    }
  }, [nft?.marketId, userState?.public_address]);


  // Listen for changes in the likes count
  useEffect(() => {
    if (nft?.marketId) {
      const heartSub = nftDbFile.getLikesCountListner(nft?.marketId, setHeartCount);
      return () => {
        heartSub();
      };
    }
  }, [nft?.marketId]);

  // Resize the font size based on the height of the square div
  useEffect(() => {
    setNftData(nft);
    const resizeFont = () => {
      if (squareRef.current) {
        // @ts-ignore
        const height = squareRef.current.offsetHeight; // Get the height of the square div
        const p = parseFloat((height / dimension).toFixed(6)); // Calculate the proportion of the height of the square div to the original 
        console.log({ p, height, dimension });
        setProportion(p);
      }
    };
    // Call resizeFont on mount and add event listener for window resize
    resizeFont();
    window.addEventListener('resize', resizeFont);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', resizeFont);
  }, [nft]); // Empty dependency array means this effect runs once on mount


  useEffect(() => {
    if (!bottomSectionRef.current || !hideOnce) return;

    const handleScroll = () => {
      // Check if the bottom section is scrolled more than 
      // @ts-ignore
      const isScrolled2 = bottomSectionRef.current?.scrollTop > 10;
      setIsShow(isScrolled2);
    };

    const bottomSection = bottomSectionRef.current;
    if (isShow) {
      // @ts-ignore
      bottomSection?.removeEventListener('scroll', handleScroll);
    } else {
      // @ts-ignore
      bottomSection?.addEventListener('scroll', handleScroll);
    }

    // @ts-ignore
    return () => bottomSection?.removeEventListener('scroll', handleScroll);
  }, [bottomSectionRef, isShow]);


  // ========================= Handlers ===========================

  const handleWatchlistToggle = () => {
    setBtnDrawer(false);
    openModal({
      for: 'yesno',
      type: watching ? "confirm" : "info",
      title: watching ? "Remove from Watchlist" : "Add to Watchlist",
      message: watching
        ? "You will no longer receive updates for this NFT."
        : "You will receive notifications for this NFT.",
      className: "h-[22rem]",
      onConfirm: () => {
        if (watching) {
          nftDbFile.removeFromWatchlist(userState?.public_address as string, nft?.marketId as string).then(() => {
            setWatching(p => !p);
          });
        } else {
          nftDbFile.addToWatchlist(userState?.public_address as string, nft?.marketId as string).then(() => {
            setWatching(p => !p);
          });
        }
        closeModal();
      },
    });
  };

  const handleInfoShow = () => {
    drawerManager.openNftInfoDrawer();
  };

  const handleNftDelete = () => {

    setBtnDrawer(false);
    openModal({
      for: 'yesno',
      type: "warning",
      title: 'Delete Unminted NFT!',
      message: <div className=''>
        This NFT will be permanently deleted from your account. Are you sure you want to delete this NFT?
      </div>,
      yesText: "Delete",
      yesClass: "bg-red-200 text-red-500",
      noText: "Mint Now",
      noClass: "bg-brand text-white",
      onConfirm: () => {
        axiosInstance.delete(`/api/mint/remove/${nft.key}`).then(res => {
          closeModal();
          console.log({ Deleted: res.data });
          toast.success('NFT deleted');
          navigate(-1);
        });
        // nftDbFile.deleteMarket(nft?.marketId as string).then(() => {
        //   toast.success('NFT deleted');
        //   navigate(-1);
        // });
      },
      onCancel: () => {
        // @ts-ignore
        drawerManager.openMintDrawer(nftData);
      },
    });
  };

  const handleLike = () => {
    if (heartMutex.current) return;
    heartMutex.current = true;
    if (heart) {
      nftDbFile.removeFromLikes(userState?.public_address as string, nft?.marketId as string).then(() => {
        setHeart(false);
        heartMutex.current = false;
      });
    } else {
      nftDbFile.addToLikes(userState?.public_address as string, nft?.marketId as string).then(() => {
        setHeart(true);
        heartMutex.current = false;
      });
    }
  };

  const handleNftShare = () => {
    drawerManager.openShareDrawer({ nft });
  };


  if (nft?.key == "0") {
    return (
      <div className="h-full">
        <div className="h-[70%] w-full flex flex-col items-center justify-center">
          <img src="/img/NotFound.svg" alt="Not Found" className="h-[10rem] shadow-md" />
          <center className="text-2 text-f12 mt-2">
            Sorry, we couldn't find the NFT <br /> you're looking for.<br /><br />
            <SecondaryBtn onClick={() => navigate("/markets")} className=" shadow-sm px-8 bg-4">Go Home</SecondaryBtn>
          </center>
        </div>
      </div>
    );
  }


  return (
    <div className="flex flex-col h-full overflow-hidden relative">
      {isPlay && isAudioOn && nft?.audio && <BackgroundAudio trigger={trigger} url={nft?.audio} />}

      {/* NFt presentation */}
      <div ref={nftSectionRef} className={` bg-white transition-all duration-1000 ${isShow ? "h-0" : "h-fit"}`}>
        {isShow || nftShow ?
          <center className={` top-0 duration-300 h-full w-full ${isShow ? 'px-2 blur-sm' : ''} `}>
            <img
              src={nft?.thumbnail}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/img_placeholder.svg';
                e.currentTarget.classList.remove('img-loading');
              }}
              loading='lazy'
              className={`object-contain h-full w-[94%] py-1 `} />
          </center>
          : <Tilt glareEnable perspective={1500}>
            <center className="w-full">
              <div className="">
                {/* this whole node with center tag will be captured as thumbnel by html2canvas */}
                <center className="w-[94%]" id="text-with-img-nft2">
                  <>
                    <div className={`absolute w-[94%] max ${isCapturing ? "border-[0px] rounded-none" : "rounded-lg border-[0px] border-lightBrand"} square-container bg-white`}
                      style={{
                        textAlign: "center",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: nft?.background ? `url(${nft?.background})` : 'none',
                        opacity: (opacityPercent / 100),
                      }}
                    >
                      <div className="square" ref={squareRef}>
                        <div className="square-content"></div>
                      </div>
                    </div>

                    <div className="square">
                      {nft?.thumbnail
                        ? <div
                          className="w-full square-content "
                          style={{
                            backgroundColor: backgroundColor,
                          }}
                        >
                          {(hideOnce && isPlay) && <div
                            style={{
                              paddingTop: `${paddingTop}rem`,
                              paddingBottom: `${paddingTop}rem`,
                              backgroundColor: "transparent",
                              fontSize: fontSize,
                              resize: "none",
                              overflow: "hidden",
                              color: fontColor,
                              fontWeight: fontWeight,
                              lineHeight: lineHeight / 100,
                            }}
                            className="font-thin h-full">
                            {phrase &&
                              <Typewriter
                                options={{
                                  wrapperClassName: 'font-thin',
                                  autoStart: true,
                                }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .callFunction((s) => {
                                      setIsPlay(true);
                                      s.elements.wrapper.style.fontFamily = font;
                                      s.elements.wrapper.style.fontWeight = fontWeight.toString();
                                    })
                                    // .callFunction((s) => {
                                    //   handleSpeak();
                                    // })
                                    .pauseFor(500)
                                    .typeString(phrase.replace(/\n/g, '<br />'))
                                    .changeDelay(50)
                                    .callFunction((s) => {
                                      s.elements.cursor.hidden = true;
                                      setPlayPause(true);
                                      if (onceShow) return;
                                      setBtnDrawer(true);
                                      setOnceShow(true);
                                      setTimeout(() => setBtnDrawer(false), 5000);
                                    })
                                    .start();
                                }}
                              />}
                          </div>}

                          {/* {!hideOnce && !isPlay && <div className=""
                            style={{
                              paddingTop: `${paddingTop}rem`,
                              paddingBottom: `${paddingTop}rem`,
                              fontFamily: font,
                              backgroundColor: "transparent",
                              fontSize: fontSize,
                              resize: "none",
                              overflow: "hidden",
                              color: fontColor,
                              fontWeight: fontWeight,
                              lineHeight: lineHeight / 100,
                            }}
                            hidden={isShow}
                          >
                            {phrase.split("\n").map((line, index) => (
                              <p
                                className='phrase-text-p'
                                style={{
                                  fontFamily: font,
                                  paddingBottom: "0.2rem",
                                }}
                              >{line}</p>
                            ))}
                          </div>} */}

                        </div>
                        : <Skeleton className="w-full opacity-30 square-content" />}
                    </div>
                  </>
                </center>
              </div>
            </center>
          </Tilt>
        }

      </div>

      {/* middle Control Buttons, Owner Profile and Tabs*/}
      <div className={`duration-300 rounded-[5px] mx-4 transition-all ${isShow ? '' : ''} `}>

        {!isShow && <div className="mx-2">
          <div className="flex justify-center overflow-x-auto text-[12px] gap-4 mt-2 mb-0  pb-1 p-0">
            {nft && !nft?.minted && nft.owner == userState?.public_address
              ? <ControlIcon faIcon={faTrash} className="px-1 my-0" name="delete" value={openControl}
                setValue={() => protect(handleNftDelete)} />
              : <ControlIcon tag={heartCount.toString()} faIcon={heart ? faHeartOn : faHeartOff} className="px-1 my-0" name="voice" value={openControl} setValue={() => protect(handleLike)} />
            }

            {nft && nft?.minted && <ControlIcon faIcon={watching ? faBellOn : faBellOff} className="px-1 my-0" name="watching" value={openControl}
              setValue={() => protect(handleWatchlistToggle)} />}

            <ControlIcon faIcon={playPause ? faPlay : faPause}
              className="px-1 my-0" name="voice" value={openControl} setValue={(name: string) => {
                setIsPlay(p => !p);
                setPlayPause(false);
              }} />

            <ControlIcon faIcon={faPaperPlane} className="px-1 my-0" name="voice" value={openControl} setValue={handleNftShare} />

            {nft && nft?.minted && <ControlIcon faIcon={faEllipsisVertical} className="px-1 my-0" name="info" value={openControl}
              setValue={handleInfoShow} />}
          </div>
        </div>}

        <MarketInfoCard market={nft} owner={owner} view={isShow}
          toggleView={() => {
            if (isShow) {
              setTimeout(() => setNftShow(false), 400);
            } else {
              setNftShow(true);
            }
            setIsShow(p => !p);
            setHideOnce(false);
          }} preview />

        {/* {data && data?.buyPrice && <RewardCard rewards={marketRewardsConfig} market={data} />} */}

        <Tablist
          className="ml-3 mb-3 select-none"
          tablist={tabList}
          onTabSelect={(tabName: string) => {
            const index = tabList.indexOf(tabName);
            setActiveTab(tabName);
            if (!mountedTabs[index]) {
              setMountedTabs((t) => {
                t[index] = true;
                return [...t];
              });
            }
          }}
          activeTab={activeTab}
        />

      </div>

      {/* List of Holders/Watching/Activity etc */}
      <div className={` bg-bg-cyan-100 overflow-hidden ${isShow ? 'h-full' : 'h-auto'}`}>

        <div className="relative pt-4 select-none">
          <div className="flex flex-col w-full">
            <div className="w-full pb-3 bg-brandGrey">
              <SwipeableViews
                index={tabList.indexOf(activeTab)}
                onChangeIndex={(index) => {
                  console.log("onChangeIndex", index);
                  setActiveTab(tabList[index % tabList.length]);
                  if (!mountedTabs[index]) {
                    setMountedTabs((t) => {
                      t[index] = true;
                      return [...t];
                    });
                  }
                }}
                className='h-full'
              >
                {(activeTab == "Holders" || mountedTabs[0]) && <div className="h-screen overflow-hidden">
                  <HoldersTab nft={nft} />
                </div>}

                {(activeTab == "Watching" || mountedTabs[1]) && <div className="h-screen overflow-hidden">
                  <WatchListedByTab nft={nft} />
                </div>}

                {(activeTab == "Activity" || mountedTabs[2]) && <div className="h-screen overflow-hidden">
                  <MarketActivityTab nft={nft} />
                </div>}

                {/* {(activeTab == "Claim" || mountedTabs[3]) && <div className="h-screen overflow-hidden">
                  <ClaimMarketRewards nft={nft} />
                </div>} */}

              </SwipeableViews>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export const MarketInfoCard: React.FC<{
  market: NFTStoredData;
  owner: MinimalUser | null;
  preview?: boolean;
  className?: string;
  idx?: number;
  view?: boolean;
  toggleView?: () => void;
}> = ({ market, owner, preview, idx, className, view, toggleView }) => {

  const navigate = useNavigate();
  const unInitialisedMarket = false;
  const [btnDrawer, setBtnDrawer] = useBuyBtnDrawer();
  const [showClaim, setShowClaim] = useState(false);
  const drawerManager = useDrawerState();
  const account = useAccount();
  const seenRef = useRef(false);

  // mark the market as seen for this user
  useEffect(() => {
    if (account?.address && market?.marketId && !seenRef.current) {
      nftDbFileMatrics.setSeen(market.marketId as string, account.address);
      seenRef.current = true;
    }
  }, [market, account]);

  const dividendsOfArgsPack = useMemo(() => [market?.marketId, account?.address], [market, account]);
  const { data: earnedDividends } = useContractRead<any, 'dividendsOf', string>({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'dividendsOf',
    args: dividendsOfArgsPack,
    account: account?.address,
    watch: true
  });

  const checkEarnedRewardsArgsPack = useMemo(() => [market?.marketId, account?.address], [market, account]);
  const { data: earnedRewards } = useContractRead<any, "checkEarnedRewards", string>({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi as any,
    functionName: 'checkEarnedRewards',
    args: checkEarnedRewardsArgsPack,
    account: account?.address,
    watch: true
  });

  // check if rewards are available, if yes show the button
  useEffect(() => {
    if ((earnedRewards && BigInt(earnedRewards) > BigInt(0)) ||
      (earnedDividends && BigInt(earnedDividends) > BigInt(0))) {
      setShowClaim(true);
    } else {
      setShowClaim(false);
    }
    if (drawerManager.drawerState?.screen == 'claim-reward') {
      drawerManager.openClaimRewardDrawer({ market, earnedDividends, earnedRewards });
    }
  }, [earnedRewards, earnedDividends]);

  useEffect(() => {
    setBtnDrawer(false);
    return () => {
      setBtnDrawer(null);
    };
  }, []);

  return (
    <div
      className={twMerge(
        ` p-4 pb-0 pt-2 rounded-[10px] justify-between flex gap-[15px] w-full my-4 mt-2 bg-white`,
        className
      )}
    >
      <div className="flex flex-col mt-5 gap-[3px] items-center w-[60px] justify-start "
        onClick={() => { navigate(`/profile/${owner?.public_address}`); }}
      >
        {owner && owner?.img_url !== undefined
          ? <img
            src={owner?.img_url ? owner?.img_url : "/img_placeholder.svg"}
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = '/img_placeholder.svg';
              e.currentTarget.classList.remove('img-loading');
            }}
            loading="lazy"
            className={`${owner?.img_url ? "img-loading" : ""} max-w-[60px] min-w-[60px] min-h-[60px] max-h-[60px] rounded-[5px] object-cover`}
          />
          : <Skeleton
            className="max-w-[60px] min-w-[60px] min-h-[60px] max-h-[60px] rounded-[5px] opacity-30"
          />}
        {/* <span className="p-1 px-2 bg-green-300 rounded-lg animate-pulse flex">NewOwner</span> */}
      </div>

      <div className="flex flex-col items-start w-full flex-grow mb-4">
        <div
          className={`flex justify-between w-full mb-[2px] mt-1 `}
        >
          <div className="flex justify-between w-full select-none">
            <span className="font-semibold text-f14">{market?.title ?? <Skeleton className="w-[100px] h-5 opacity-30" />}</span>
          </div>
        </div>

        <p className="phrase-text-p font-semibold text-f12 text-2 w-[calc(100% - 60px)] select-none">
          {market?.phrase ?? <Skeleton className="w-[200px] mt-2 h-10 opacity-30" />}
        </p>
        {/* </div> */}

        <div className="flex justify-end w-full select-none">
          <span className="font-semibold text-f10 italic text-0">{market?.author ?
            <>
              {"By "} <span className=" underline text-2 hover:text-brand" onClick={() => {
                navigate(`/profile/${market?.creator}`);
              }}>
                {market?.author}
              </span>
            </>
            : <Skeleton className="w-[80px] mt-1 h-5 opacity-30" />}</span>
        </div>

        {unInitialisedMarket ? null : (
          <span className='flex w-full mt-2 '>
            <DisplayPrice
              className={`text-2 ${btnDrawer ? "animate-pulse" : ""}`}
              compact
              active
              price={BigInt(market?.buyPriceAfterFees ?? "0")}
            />
            {showClaim && <>
              <span className='text-f12 pl-2'>|</span>
              <UnderlineButton
                className="text-f12 ml-2 mr-6 animate-shine"
                onClick={() => {
                  drawerManager.openClaimRewardDrawer({ market, earnedDividends, earnedRewards });
                }}
              >
                Claim
              </UnderlineButton>
            </>}
          </span>
        )}
      </div>
    </div>
  );
};

const MarketInfoPage: React.FC<any> = ({ }) => {
  const param = useParams();
  const [marketState, setMarketState] = useMarketState();
  const [owner, setOwner] = useState<MinimalUser | null>(null);
  const seenRef = useRef(false);

  useEffect(() => {
    if (marketState && marketState?.creator) {
      getOtherUserData(marketState.creator as Address).then(u => {
        setOwner(u);
      });
    }
  }, [marketState]);

  useEffect(() => {
    if (!marketState || marketState?.key !== param.marketid) {
      if (param.marketid) nftDbFile.getMinted(param.marketid).then(nft => {
        if (!nft) {
          if (param.marketid) nftDbFile.getUnminted(param.marketid).then(nft => {
            if (!nft) {
              // @ts-ignore
              setMarketState({ key: "0" });
            } else {
              setMarketState(nft);
            }
          });
        } else {
          setMarketState(nft);
        }
      });
    }
    return () => {
      setMarketState(null);
    };
  }, []);

  return <ScrollableComponent nft={marketState} owner={owner} />;
};

export default MarketInfoPage;

