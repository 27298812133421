import { faAuth } from "@/firebase/instance";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosAIInstance = axios.create({
  baseURL: import.meta.env.VITE_AI_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Add an interceptor to inject the ID token into headers
axiosInstance.interceptors.request.use(async (config) => {
  // Specify the paths where you want to skip the interceptor
  const pathsToSkip = ['/api/auth/register', '/api/auth/register/is_invited'];
  console.log({ URl: config?.url });
  // Check if the request URL matches any of the paths to skip
  // @ts-ignore
  if (config?.url && pathsToSkip.some(path => config.url.includes(path))) {
    return config; // Skip the token injection for these paths
  }

  // Get the current user from Firebase Auth
  const currentUser = faAuth.currentUser;
  if (currentUser) {
    // Get the Firebase ID token
    const idToken = await currentUser.getIdToken();
    // Attach the ID token to the Authorization header
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }
  return config;
}, (error) => {
  // Handle request error here
  return Promise.reject(error);
});