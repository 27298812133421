import { cn } from '@/lib/utils';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-3', className)}
      {...props}
    />
  );
}

interface TimeoutSkeletonProps extends HTMLAttributes<HTMLDivElement> {
  timeOut?: number | null;
  label?: any;
}

function TimeoutSkeleton({
  className,
  style,
  label = <></>,
  timeOut = null,
  ...props
}: TimeoutSkeletonProps) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (timeOut && timeOut > 0) {
      setTimeout(() => {
        setShow(false);
      });
    }
  }, []);

  return (show ?
    <Skeleton className={twMerge(` opacity-30`, className)} /> : label
  );
}


export { Skeleton, TimeoutSkeleton };
