import * as React from 'react';

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.85313 2.10359L7.89612 1.14656C7.80315 1.05267 7.67503 1 7.54222 1H4.98602C4.43368 1 3.98604 1.44781 3.98604 2L3.98634 6C4.0001 6.55313 4.44853 7 5.00008 7H8.00002C8.55001 7 9 6.55 9 6V2.45703C9 2.32453 8.94688 2.19734 8.85313 2.10359ZM8.25001 6C8.25001 6.13806 8.13808 6.25 8.00002 6.25H4.98602C4.84792 6.25 4.73602 6.13806 4.73602 6V2.00203C4.73602 1.86397 4.84796 1.75203 4.98602 1.75203H6.98598L7.00004 2.5C7.00004 2.77609 7.22394 3 7.50003 3H8.23595V6H8.25001ZM5.25007 8C5.25007 8.13806 5.13814 8.25 5.00008 8.25H1.98607C1.84798 8.25 1.73608 8.13806 1.73608 8L1.74983 4.00156C1.74983 3.8635 1.86176 3.75156 1.99982 3.75156H3.50011V3H1.99998C1.44765 3 1 3.44766 1 4L1.00015 8C1.00019 8.55156 1.44796 9 2.00014 9H5.00008C5.55007 9 6.00006 8.55 6.00006 8V7.5H5.26414L5.25007 8Z" fill="#8F97AD" stroke="#8F97AD" strokeWidth="0.3" />
    </svg>
  );
}

export default React.memo(CopyIcon);

