/**
 * Check if the app is installed and detect the platform (Android or iOS)
 */
export const isAppInstalled = (): AppInstallStatus => {
  if (typeof window === 'undefined') return { isInstalled: false, platform: { iOS: false, Android: false, Desktop: false } };

  // Check for iOS standalone mode
  const isIOSInstalled = () => window.navigator && 'standalone' in window.navigator && window.navigator.standalone;

  // Check for Android/Desktop standalone mode (PWA installed)
  const isStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

  // Detect if user is on iOS
  // @ts-ignore
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Detect if user is on Android
  const isAndroid = /android/i.test(navigator.userAgent);

  const isIOSInstalledApp = isIOSInstalled();
  const isAndroidOrDesktopInstalled = isStandaloneMode();

  return {
    isInstalled: (isAndroidOrDesktopInstalled || isIOSInstalledApp) as boolean,
    platform: {
      iOS: isIOS,
      Android: isAndroid,
      Desktop: !isAndroid && !isIOS,
    },
  };
};
