import { getOtherUserData } from "@/Helpers/register";

// @ts-ignore
export const makeAddress = (v: BuySellActivity) => v?.buyer ?? v?.seller;

export const makeUserAddressMap = async (f: BuySellActivity[]) => {
  const userAddrMap: UserAddrMap = {};
  for (let [k, v] of Object.entries(f)) {
    const address = makeAddress(v);
    userAddrMap[address] = await getOtherUserData(address);
    // console.log({ v, address, userAddrMap });
  }
  return userAddrMap;
};

export const makeTypes = (type: string) => type == "BuyShare" ? "buy" : type == "SellShare" ? "sell" : type;

export const makeTimestamp = (t: string) => {
  const s = t.split(":");
  return s.length >= 1 ? s[0] : null;
};

export const makeBuySellActivity = (f: Record<string, BuySellActivity>, highlight = false) => {
  const a: any[] = []; // activity
  for (let [k, v] of Object.entries(f)) {
    a.push({
      ...v, blockTimestamp: makeTimestamp(k) as string,
      type: makeTypes(v.type),
      highlight: highlight,
    });
    a.sort((a, b) => {
      return parseInt(b.blockTimestamp) - parseInt(a.blockTimestamp);
    });
  }
  return a;
};


export const sortOnShares = (a: { shares: string; }, b: { shares: string; }, asc: false) => {
  return asc ? parseInt(a.shares) - parseInt(b.shares) : parseInt(b.shares) - parseInt(a.shares);
};