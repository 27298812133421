import { useEffect, useState } from 'react';
import MemoAddMarkets from '../SVG/AddMarkets';
import MemoMarketListIcon from '../SVG/MarketListIcon';
import { NavLink } from 'react-router-dom';
import useDrawerState, { useBuyBtnDrawer, useNFTShowState } from '../atoms/drawerState';
import { MobileDrawer } from './MobileDrawer';
import MemoProfileIcon from '../SVG/ProfileIcon';
import { AccountDropdown } from './AccountDropdown';
import { Tooltip } from 'react-tooltip';
import { MemoSettingsBig } from '@/SVG/Settings';
import { useUserStateSync } from '@/pages/LoginPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton, SecondaryButton } from './Buttons';
import { useProtection } from '@/Helpers/useProtection';
import { useAccount } from 'wagmi';
import { useMarketMintedState, useNftSotredDataState } from '@/atoms/marketState';
import { useEthPriceRefresher } from '@/atoms/ETHPrice';
import YesNoModal from './YesNoModal';
import ReportIssueModal from './ReportIssueModal';
import OnboardingModal from './OnbordingModal';
import { isRouteActive } from '@/lib/utils';

// icons on the bottom for the navigation
const Icons = [
  {
    page: '/',
    activeOn: ['/', '/markets'],
    name: 'MarketList',
    Icon: MemoMarketListIcon,
  },
  {
    page: '/add',
    activeOn: ['/add'],
    name: 'AddMarkets',
    Icon: MemoAddMarkets,
  },
  {
    page: '/profile',
    activeOn: ['/profile'],
    name: 'profile',
    Icon: MemoProfileIcon,
  }
];


// All the components in the app are wrapped in this Layout component
const Layout: React.FC<{ children: React.ReactNode; }> = ({ children }) => {
  useUserStateSync(); // whenever page refreshed, fetch user data from the backend
  useEthPriceRefresher(); // fetch the latest ETH price from the Pyth network

  // const navigate = useNavigate();
  const drawerManager = useDrawerState();
  const { address } = useAccount();
  const [btnDrawer, setBtnDrawer] = useBuyBtnDrawer();
  const [protect] = useProtection();
  const [shine, setShine] = useState(false);
  const [minted,] = useMarketMintedState();
  const [nftData,] = useNftSotredDataState();
  const [isShow, setIsShow] = useNFTShowState();

  useEffect(() => {
    if (btnDrawer) {
      setTimeout(() => setShine(true), 300);
    } else {
      setShine(false);
    }
  }, [btnDrawer]);

  useEffect(() => {
    if (address) {
      if (Icons.filter((icon) => icon.name === 'settings').length === 0) {
        // if user is logged in, show the settings icon
        Icons.push({
          activeOn: ["/settings"],
          page: '/settings',
          name: 'settings',
          Icon: MemoSettingsBig,
        });
      }
    } else {
      if (Icons.length > 3) Icons.pop();
    }
  }, [address]);

  return (
    <>
      <div className="flex flex-shrink-0 h-[100vh] align-middle items-center justify-center overflow-hidden ">
        <div className="flex flex-col responsive-layout h-[100vh] ">
          {/* Injecting all the modals to open properly */}
          <YesNoModal />
          <ReportIssueModal />
          <OnboardingModal />

          {/* top bar with account dropdown */}
          <div className="w-full bg-gray2 h-[40px] items-center justify-between flex px-[10px]">
            <div className="flex justify-start">
              <img className="w-[35px] h-[25px]" src="/Logo.svg" />
              <div className="flex flex-col justify-end ml-2">
                {/* <span className='h-[12px] w-fit rounded-[4px] pb-1 bg-brand px-2 text-white'>Beta</span> */}
                <span className='h-[15px] w-fit rounded-[4px] pb-2 bg-light3Brand px-2 mt-1 text-white'>Testnet</span>
              </div>
            </div>
            <AccountDropdown />
          </div>

          {/* Body of the App with all middel interactive area */}
          <div className="flex-1 w-full mb-[50px] min-h-full bg-grey1 overflow-hidden">
            {children}
          </div>

          {/* overwrite/overlap the bottom navigation with bottom drawer */}
          {drawerManager.drawerState?.screen ? <MobileDrawer /> : null}

          {/* bottom navigation buttons */}
          <div className={` w-full flex flex-col z-50 items-center fixed bottom-0 left-0 text-2 `}>

            {!drawerManager.isOpen() && <>
              {/* Button to toggle show/hide of Buy/Sell Drawer on top of Bottom Navigator */}
              {(btnDrawer === true || btnDrawer === false) &&
                ((!nftData?.minted && nftData?.owner == address) || nftData?.minted) && // make sure the user is the owner of the NFT (we are going to mint)
                <div className=" h-[25px] ml-auto "
                >
                  <div className="flex gap-4 responsive-mr">
                    <center
                      onClick={() => setIsShow(p => !p)}
                      className="rounded-t-xl border-t-[1px] border-x-[1px] border-[2]  w-[40px] bg-white">
                      <FontAwesomeIcon
                        icon={isShow ? faEye : faEyeSlash}
                        className={`text-light3Brand2 mb-[-8px] text-[15px]`} />
                    </center>
                    {address && <div
                      onClick={() => setBtnDrawer(p => !p)}
                      className="flex justify-center items-center rounded-t-xl border-t-[1px] border-x-[1px] border-[2] w-[40px] bg-white">
                      <FontAwesomeIcon icon={faCaretUp} className={`text-light3Brand2 ${btnDrawer ? " rotate-180 pb-[-0.1rem]" : "bounce-5s"} text-[25px]`} />
                    </div>}
                  </div>
                </div>}


              {/* Buy/Sell Drawer on top of Bottom Navigator */}
              {/* Only for logged in user to see, means having address */}
              {address && ((!nftData?.minted && nftData?.owner == address) || nftData?.minted) &&
                <div className={` transition-all duration-300 w-full md:w-fit
                    ${btnDrawer ? "h-[60px] border-t-[1px] border-x-[1px] border-[2]" : "h-0"} 
                  bg-white rounded-t-[10px]`
                }>
                  <div className="flex gap-2 px-10 pt-4 responsive-layout">
                    {minted ? <>
                      <SecondaryButton
                        onClick={() =>
                          protect(() => drawerManager.openSellDrawer())
                        }
                        className="px-3 text-f14 h-[4rem] w-full"
                      >
                        Sell
                      </SecondaryButton>
                      <PrimaryButton
                        onClick={() =>
                          protect(() => drawerManager.openBuyDrwer())
                        }
                        className={`px-3 text-f14 h-[4rem] w-full ${btnDrawer && shine ? "animate-shine" : ""}`}
                      >
                        Buy
                      </PrimaryButton>
                    </> : <>
                      <PrimaryButton
                        onClick={() =>
                          protect(() => drawerManager.openMintDrawer(nftData as any))
                        }
                        className={`px-3 text-f14 h-[4rem] w-full ${btnDrawer && shine ? "animate-shine" : ""}`}
                      >
                        Mint Now
                      </PrimaryButton>
                    </>
                    }
                  </div>
                </div>}
            </>}

            {/* Bottom Navigator */}
            <div className={`flex text-2 bg-white justify-center ${btnDrawer === true ? "border-white" : "topborder"} w-full h-[50px] pt-2 items-center ${address ? "tab-gap-login" : "tab-gap"}`}>

              {Icons.map((icon) => {
                return (
                  <NavLink
                    onClick={(e) => {
                      drawerManager.closeDrawer();
                    }}
                    className={isRouteActive(icon.activeOn) ? 'text-brand' : ''}
                    to={icon.page} key={icon.name}
                  >
                    <icon.Icon on={isRouteActive(icon.activeOn) ? "true" : undefined} />
                  </NavLink>
                );
              })}

            </div>
          </div>

        </div>
      </div>
      <Tooltip
        delayShow={1500}
        style={{
          backgroundColor: '#E5E7EB',
          color: '#8F95A4',
        }}
        id="tooltip"
      />
    </>
  );
};

export { Layout };
