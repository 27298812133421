import { openDB } from 'idb';

const DB_NAME = 'OtherUsersData';
const STORE_NAME = 'OtherUsersDataStore';

async function initDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'key' });
      }
    },
  });
}

/**
 * Stores data in IndexedDB with the given key.
 * @param {string} key - The key under which the data will be stored.
 * @param {any} value - The value to be stored.
 */
export async function storeData(key: string, value: any) {
  const db = await initDB();
  const ts = Date.now();
  await db.put(STORE_NAME, { key, value, ts });
}

/**
 * Fetches data from IndexedDB for the given key.
 * @param {string} key - The key of the data to fetch.
 */
export async function fetchData(key: string) {
  const db = await initDB();
  const result = await db.get(STORE_NAME, key);
  if (!result) return null;
  if (!result.ts || result.ts < Date.now() - 1000 * 60 * 60 * 24) {
    db.delete(STORE_NAME, key);
    return null;
  }
  return result ? result.value : null;
}

/**
 * Deletes all data stored in IndexedDB.
 */
export async function clearAllData() {
  const db = await initDB();
  await db.clear(STORE_NAME);
}

// Example usage:
// (async () => {
//   await storeData('myKey', { name: 'John', age: 30 });
//   const data = await fetchData('myKey');
//   console.log(data); // { name: 'John', age: 30 }
//   await clearAllData();
// })();