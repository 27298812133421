import { useAccount, useBalance, useContractRead } from 'wagmi';
import PrimeFadeText from '../../components/PrimeFadeText';
import PrimeText from '../../components/PrimeText';
import useUserState, { useOtherUserState } from '../../atoms/userState';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatAddress } from '../../Helpers/web3utils';
import { DisplayPrice } from '@/components/DisplayPrice';
import { Skeleton, TimeoutSkeleton } from '@/components/ui/skeleton';
import { getOtherUserData } from '@/Helpers/register';
import { useEffect, useMemo, useState } from 'react';
import { UnderlineButton } from '@/components/Buttons';
import useDrawerState from '@/atoms/drawerState';
import { appConfig } from '@/config';
import { PhraseTradeStorageAbi } from '@/ABI/PhraseTradeStorage';
import CopyIcon from '@/SVG/CopyIcon';
import toast from 'react-hot-toast';
import { isAddress, zeroAddress } from 'viem';
import { axiosInstance } from '@/config/axiosInstance';

const UserCard: React.FC<{ isMyProfile: boolean; }> = ({ isMyProfile }) => {
  const params = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const account = useAccount();
  const { address } = params?.user_addr
    ? { address: isAddress(params.user_addr) ? params.user_addr : zeroAddress }
    : account;
  const other = useMemo(() => params?.user_addr ? true : false, [params]);
  const [userState,] = useUserState();
  const [otherUserState, setOtherUserState] = useOtherUserState();
  const drawerManager = useDrawerState();
  const [showClaim, setShowClaim] = useState(false);
  const navigate = useNavigate();
  const email = useMemo(() => userState?.email.includes(address ?? "")
    ? userState?.email.replace(address ?? "", formatAddress(address ?? ""))
    : userState?.email, [userState, address]);

  const code = searchParam.get("code");
  const state = searchParam.get("state");
  console.log({ code, state });
  useEffect(() => {
    if (code && state) {
      axiosInstance.post(`/api/auth/verify`)
        .then(r => { console.log({ r }); })
        .catch(e => { console.log({ e }); });
    }
  }, [code, state]);

  useEffect(() => { if (address == zeroAddress) navigate("/"); }, [address]);

  // try to fetch user balance with the address
  const { data, isError, isLoading } = useBalance({
    address: address as Address,
    watch: true,
  });

  const { data: creatorFeesCollected } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'creatorFeesCollected',
    args: [account?.address] as [Address],
    account: account?.address,
    watch: true
  });

  const { data: ownerFeesCollected } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'ownerFeesCollected',
    args: [account?.address] as [Address],
    account: account?.address,
    watch: true
  });

  useEffect(() => {
    if (creatorFeesCollected || ownerFeesCollected) {
      setShowClaim(true);
    } else {
      setShowClaim(false);
    }
    if (drawerManager.drawerState?.screen === 'claim-fees') {
      drawerManager.openClaimFeesDrawer({ ownerFeesCollected, creatorFeesCollected });
    }
  }, [creatorFeesCollected, ownerFeesCollected]);

  // if expicitly passed user address, fetch user data
  if (other) {
    // if data is not loaded of concerned user, fetch it
    if (otherUserState?.public_address !== params.user_addr) {
      getOtherUserData(params.user_addr as Address).then(user => {
        setOtherUserState(user);
      });
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-full select-none">
      <div className="flex w-full">
        {/* profile img section */}
        <span className="flex items-center flex-grow">
          <img
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = '/img_placeholder.svg';
              e.currentTarget.classList.remove('img-loading');
            }}
            className={`${other ? (otherUserState?.img_url ? "img-loading" : "my-2") : userState?.img_url ? "img-loading" : "my-2"} rounded-[10px] mr-4 object-cover  w-[60px] h-[60px] `}
            src={other ? (otherUserState?.img_url ? otherUserState?.img_url : "/img_placeholder.svg") : userState?.img_url ? userState?.img_url : "/img_placeholder.svg"}
            alt="user profile"
          />
          <span className="flex flex-col justify-between py-3">
            <PrimeText>
              {
                other ?
                  (otherUserState?.first_name ? otherUserState?.first_name : <TimeoutSkeleton label={"Anonymous"} timeOut={2000} className="w-[100px] h-6" />)
                  : (userState?.first_name ? userState?.first_name : <TimeoutSkeleton label={"Anonymous"} timeOut={2000} className="w-[100px] h-6" />)
              }
            </PrimeText>


            {!other ? (
              <PrimeFadeText>
                {!email ?
                  <TimeoutSkeleton timeOut={2000} className="mt-2 w-[150px] h-6" />
                  : <div className="text-f12">{email}</div>}
              </PrimeFadeText>
            ) : (
              <span
                className=" mt-[-4px] pl-0 max-w-[70px] text-2 text-f12 rounded-lg p-2"
              >
                {otherUserState?.public_address
                  ? <span className='flex gap-2'>{formatAddress(otherUserState?.public_address)}
                    <span className='flex' onClick={() => {
                      navigator.clipboard.writeText(otherUserState?.public_address as string);
                      toast('Account copied to clipboard Successfully!', { id: "Account copied" });
                    }} ><CopyIcon /></span>
                    {/* <span className='flex'></span> */}
                  </span>
                  : <Skeleton className="w-[70px] h-4" />}
              </span>
            )}

            {!isLoading ? (
              <div className="flex items-end text-2">
                <DisplayPrice
                  className="text-2"
                  compact={<>Balance&nbsp;</>}
                  price={data?.value as bigint ?? '0'}
                  active
                />
                {showClaim && isMyProfile && <>
                  <span className='text-f12 pl-2'>|</span>
                  <UnderlineButton
                    className="text-f12 ml-2 mr-6 animate-shine"
                    onClick={() => {
                      drawerManager.openClaimFeesDrawer({ ownerFeesCollected, creatorFeesCollected });
                    }}
                  >
                    Claim
                  </UnderlineButton>
                </>}
              </div>
            ) : (
              <Skeleton className="w-[100px] h-3" />
            )}

            {/* <span className=' flex text-f10 text-3 mt-1 cursor-pointer underline'>
              Connect X.com Profile <ExportIcon
                onClick={() => {
                  const twitterClientId = 'MnNzUlpIYnk4eXdBRkhMa2tLVnM6MTpjaQ';
                  const redirect_url = 'http://localhost:8080/profile';
                  const twitterOAuthURL = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${twitterClientId}&redirect_uri=${redirect_url}&state=${`0!!twitter!!challenge`}&scope=tweet.read%20users.read%20follows.read&code_challenge=challenge&code_challenge_method=plain`;

                  window.open(twitterOAuthURL);
                }}
                className='ml-2  cursor-pointer' fill='#3c32a3' />
            </span> */}
          </span>
        </span>
        {/* finance */}
        {/* <span className="flex flex-col py-3 min-w-4 jstify-between">
          <PrimeText style={{ 'align-self': 'flex-end' }}>
            {isLoading ? 'Fetching..' : view(data?.value)}
            &nbsp;ETH
          </PrimeText>
          <PrimeFadeText
            classname=" text-[12px]"
            style={{ alignSelf: 'flex-end', fontWeight: 100 }}
          >
            Wallet Balance
          </PrimeFadeText>
          {userState?.country && (
            <PrimeFadeText classname="" style={{ 'align-self': 'flex-end' }}>
              {userState?.country}
            </PrimeFadeText>
          )}
        </span> */}
      </div>
    </div>
  );
};

export { UserCard };
