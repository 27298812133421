export const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
// sanitize the string from unwanted characters

export const getSanitizedInput = (ip: string, decimal = 4) => {
  if (ip.length > 8) return ip.substring(0, 8);
  if (inputRegex.test(escapeRegExp(ip))) {
    console.log(`ip: `, ip);

    let newValue = ip;
    // Check if newValue has more than 3 decimal places
    const decimalPart = newValue.split('.')[1];
    if (decimalPart && decimalPart.length > decimal) {
      // If yes, limit it to 3 decimal places
      newValue = parseFloat(newValue).toFixed(decimal);
    }
    return newValue;
  }
  return '';
};


/**
 * Checks if a string is a valid Ethereum address.
 * 
 * @param address The Ethereum address to validate.
 * @returns true if the address is valid, false otherwise.
 */
export const isValidEthAddress = (address: string): boolean => {
  // Check if the address is the correct length and format
  const regex = /^0x[a-fA-F0-9]{40}$/;
  return regex.test(address);
};


/**
 * Truncates a given name to a specified length and appends "..." if the original name is longer than the specified length.
 * If the name is shorter than or equal to the specified length, it returns the original name.
 * 
 * @param name The name to be processed.
 * @param length The maximum allowed length of the name.
 * @returns The processed name.
 */
export function presentName(name: string, length: number = 15): string {
  if (name?.length > length) {
    return `${name.substring(0, length)}...`;
  } else {
    return name;
  }
}


export const handleTextOverflow = (text: string, length: number = 15): string => {
  if (text?.length > length) {
    return `${text.substring(0, length)}...`;
  } else {
    return text;
  }
};