import useDrawerState from '../atoms/drawerState';
import Drawer from 'react-bottom-drawer';
import AccountBalanceDrawer from './AccountBalanceDrawer';
import ExportWalletDrawer from './ExportWalletDrawer';
import LoginPage from '@/pages/LoginPage';
import { MintNftDrawer } from './MintNftDrawer';
import { BuyDrawer, SellDrawer } from './BuySellDrawer';
import NftShareDrawer from './NftShareDrawer';
import { NftInfoDrawer } from './NftInfoDrawer';
import { ClaimFeesDrawer, ClaimRewardsDrawer } from './ui/ClaimRewardsDrawer';
import { NftExistDrawer } from './NftExistDrawer';

const MobileDrawer: React.FC<any> = ({ }) => {
  const drawerManager = useDrawerState();

  return (
    <Drawer
      duration={300}
      hideScrollbars={drawerManager.drawerState?.screen ? true : false}
      onClose={drawerManager.closeDrawer}
      isVisible={drawerManager.drawerState?.screen ? true : false}
      className={'drawer'}
    >
      <div className="m-auto root-w px-[15px] mb-[5rem] rounded-t-2xl">
        <center>
          {drawerManager.drawerState?.screen == 'login' ?
            <LoginPage />
            : drawerManager.drawerState?.screen == 'wallet' ?
              <ExportWalletDrawer />
              : drawerManager.drawerState?.screen == 'balance' ?
                <AccountBalanceDrawer />
                : drawerManager.drawerState?.screen == 'mint-nft'
                  ? <MintNftDrawer data={drawerManager.drawerState.nft} />
                  : drawerManager.drawerState?.screen == 'handel-buy'
                    ? <BuyDrawer />
                    : drawerManager.drawerState?.screen == 'handel-sell'
                      ? <SellDrawer />
                      : drawerManager.drawerState?.screen == 'share'
                        ? <NftShareDrawer options={drawerManager.drawerState.options} />
                        : drawerManager.drawerState?.screen == 'nft-info'
                          ? <NftInfoDrawer />
                          : drawerManager.drawerState?.screen == 'claim-reward'
                            ? <ClaimRewardsDrawer />
                            : drawerManager.drawerState?.screen == 'claim-fees'
                              ? <ClaimFeesDrawer />
                              : drawerManager.drawerState?.screen == 'nft-exist'
                                ? <NftExistDrawer data={drawerManager.drawerState.data} />
                                : null}
        </center>
      </div>
    </Drawer>
  );
};

export { MobileDrawer };
