import "./style.css";

export default function TermsOfService() {
  return (
    <div className="poppins-500 mx-4 w-full" style={{ fontFamily: 'Inter, sans-serif, poppins', fontSize: 16 }}>
      <h2 className="text-[1.5em] my-10">Phrase.Trade Terms of Service</h2>
      <br />
      <p>Effective as of 1st March 2024.</p>
      <br />
      <p>These Terms of Service ("Terms") govern your use of Phrase.Trade, a decentralized platform where users mint and
        trade text NFTs. By accessing or using the Service, you agree to these Terms.</p>
      <h3 className="text-[1.2em] my-10 font-bold">Index</h3>
      <ul className="flex flex-col gap-4 list-disc pl-5">
        <li>Acceptance of Terms</li>
        <li>Eligibility</li>
        <li>Use of Service</li>
        <li>Minting and Trading NFTs</li>
        <li>Harmful or Reported NFTs</li>
        <li>Intellectual Property</li>
        <li>Third-Party Services</li>
        <li>Privacy Policy</li>
        <li>Termination</li>
        <li>Changes to the Terms</li>
        <li>Contact Us</li>
      </ul>
      <br />
      <h2 className="text-[1.5em] my-10">Acceptance of Terms</h2>
      <p>By using Phrase.Trade, you agree to comply with and be bound by these Terms, along with our Privacy Policy.</p>
      <h2 className="text-[1.5em] my-10">Eligibility</h2>
      <p>To use the Service, you must be at least 18 years old and capable of forming a legally binding agreement.</p>
      <h2 className="text-[1.5em] my-10">Use of Service</h2>
      <p>The Service allows users to mint, trade, and interact with text-based NFTs. You agree to use the Service in
        compliance with applicable laws and regulations.</p>
      <h2 className="text-[1.5em] my-10">Minting and Trading NFTs</h2>
      <p>When minting or trading NFTs, you agree to follow all guidelines provided by the platform. Transactions are
        processed using decentralized technologies and are irreversible once completed.</p>

      <h2 className="text-[1.5em] my-10">Harmful or Reported NFTs</h2>
      <p>
        If an NFT receives 10 or more genuine reports from users regarding harmful, offensive, or destructive content, we will remove it from our platform. Additionally, if we receive one or more reports and determine through our investigation that the NFT is harmful or destructive, we may remove it before it reaches 10 reports.
      </p>
      <p>
        Please note that, due to the decentralized nature of the technology, the NFT will continue to exist on the blockchain and may be visible to you, but will no longer be accessible to other users on Phrase.Trade.
      </p>

      <h2 className="text-[1.5em] my-10">Intellectual Property</h2>
      <p>You retain ownership of the intellectual property rights in the NFTs you create, but by minting or trading them
        on Phrase.Trade, you grant us a license to display and distribute them through our platform.</p>

      <h2 className="text-[1.5em] my-10">Third-Party Services</h2>
      <p>We use third-party services such as Web3 Auth for authentication and Firebase for backend services. Your use of
        these services is subject to their respective terms and policies.</p>

      <h2 className="text-[1.5em] my-10">Privacy Policy</h2>
      <p>Your use of the Service is also governed by our <a href="privacy-policy-v1.html">Privacy Policy</a>, which
        describes how we collect, use, and share your personal information.</p>

      <h2 className="text-[1.5em] my-10">Phone Number and Email Obfuscation</h2>
      <p>If you log in with a phone number, we will display the country code and the first and last digits to other Phrase.Trade users. For example, a phone number may appear as <code>+1-7xxxxxxxx4</code>.</p>
      <br />
      <p>If you log in with just an email, we will show your an obfuscated version of your email address on our platform to help users recognize the account. For example, an email address may appear as <code>g****8@g**.com</code>.</p>

      <h2 className="text-[1.5em] my-10">Termination</h2>
      <p>We reserve the right to terminate your access to the Service if you violate these Terms or engage in harmful
        activities.</p>
      <h2 className="text-[1.5em] my-10">Changes to the Terms</h2>
      <p>We may update these Terms from time to time. Any changes will be effective immediately upon posting on the site,
        and continued use of the Service signifies your acceptance of the updated Terms.</p>
      <h2 className="text-[1.5em] my-10">Contact Us</h2>
      <p>If you have any questions regarding these Terms, please contact us at <a className="text-blue-500" href="mailto:support@phrase.trade">support@phrase.trade</a>.</p>
      <br />
      <center>
        <a className=" underline text-brand" href="https://testnet.phrase.trade">Go to Home (Phrase.Trade)</a><p />
      </center>
      <br />
      <br />
      <br />
    </div>
  );
}
