import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { TextInput } from "./TextInput";
import Spinner from "./Spinner";
import { axiosInstance } from "@/config/axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { setInviteCode } from "@/Helpers/register";
import ShowPrompt from "../ShowPrompt";


const InviteCode: React.FC<{ onCodeVarified: () => void; }> = ({ onCodeVarified }) => {
  const [inviteC, setInviteC] = useState<string>("");
  const [isValid, setIsValid] = useState(false);
  const [verifying, setVefiying] = useState(false);

  const handleChange = useMemo(() => (val: string) => {
    if (isValid || verifying) return;
    const v = val.replace(/[^a-zA-Z0-9-]/g, ""); // Only allow digits
    if (v.length > 3) setInviteC(v.substring(0, 3) + " " + v.substring(3, v.length));
    else setInviteC(v);
  }, [isValid, verifying]);

  useEffect(() => {
    if (!isValid && inviteC.length >= 7) {
      if (inviteC == "--- ---") {
        setIsValid(true);
        onCodeVarified();
        return;
      }
      setVefiying(true);
      axiosInstance.post(`/api/auth/register/is_invited`, {
        invite_code: inviteC
      }).then(res => {
        if (res.data.message) {
          setIsValid(true);
          onCodeVarified();
          setInviteCode(inviteC);
        } else {
          if (res.data.error) toast.error(res.data.error, { id: "InviteErrorNotValid" });
          setInviteC("");
        }
      }).catch(() => {
        toast.error("Please try again after 5 minutes", { id: "InviteErrorNotValid" });
        setInviteC("");
      }).finally(() => {
        setVefiying(false);
      });
    }
  }, [inviteC]);

  return <div className="flex w-full flex-col">
    <div className="relative w-full select-none">
      <TextInput
        className="mt-6 w-full"
        inputClass={verifying ? "text-[14px] text-2" : ""}
        label="Enter 6 digit Invite Code"
        placeholder="Invite Code"
        required={true}
        name={'invite-code'}
        value={inviteC}
        setValue={handleChange}
        allowLength={7}
      />
      <center
        onClick={() => {
          navigator.clipboard.readText().then(v => {
            handleChange(v);
          });
        }}
        className=" absolute  bottom-[1.2rem] right-4 ">
        {verifying
          ? <Spinner className=" translate-y-1 -translate-x-2 " />
          : isValid
            ? <FontAwesomeIcon icon={faCheck} size='2x'
              className={`text-brand2 mr-4`} />
            : <span className="text-white rounded-lg text-f12 p-2 py-1 h-10 w-[25%] bg-brand2">
              Paste
            </span>
        }
      </center>
    </div>
    {(inviteC == "--- ---") && <ShowPrompt message={"It looks like you're trying to log in, but this will only work if you already have an account. Make sure you’re signed up before logging in!"} className="m-0 mt-4" />}
  </div>;
};

export default InviteCode;
