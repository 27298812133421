import * as React from 'react';

function DisconnectIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.96667 1.45161C4.96667 1.20181 4.75813 1 4.5 1C4.24187 1 4.03333 1.20181 4.03333 1.45161V4.6129C4.03333 4.8627 4.24187 5.06452 4.5 5.06452C4.75813 5.06452 4.96667 4.8627 4.96667 4.6129V1.45161ZM2.85938 2.70202C3.05771 2.54254 3.08396 2.25746 2.91917 2.06552C2.75438 1.87359 2.45979 1.84819 2.26146 2.00766C1.49146 2.62863 1 3.56573 1 4.6129C1 6.48286 2.56771 8 4.5 8C6.43229 8 8 6.48286 8 4.6129C8 3.56573 7.50708 2.62863 6.73708 2.00766C6.53875 1.84819 6.24417 1.875 6.07937 2.06552C5.91458 2.25605 5.94229 2.54254 6.13917 2.70202C6.70646 3.15786 7.06521 3.84516 7.06521 4.6129C7.06521 5.98468 5.91604 7.09677 4.49854 7.09677C3.08104 7.09677 1.93188 5.98468 1.93188 4.6129C1.93188 3.84516 2.29208 3.15786 2.85792 2.70202H2.85938Z" fill="#8F97AD" stroke="#8F97AD" strokeWidth="0.2" />
    </svg>
  );
}

export default React.memo(DisconnectIcon);
