import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const ShowPrompt: React.FC<{ step?: string, message: string | JSX.Element; className?: string; }> = ({ step = null, message, className = "" }) => {
  const [show, setShow] = useState(true);
  // useEffect(() => { setTimeout(() => setShow(false), 3000); }, []);
  return show ? <div className={twMerge("bg-yellow-100 shadow-sm text-2 w-full p-4 relative rounded-[5px] flex justify-between", className)}>
    <span>{step && <span className="text-brand font-extrabold flex">STEP {step}</span>} {message}</span>
    <span className="mt-[-5px] pl-2 text-brand rounded-[50%] cursor-pointer" onClick={() => setShow(false)}>
      <FontAwesomeIcon icon={faX} className=" text-[12px] rounded-full" />
    </span>
  </div> : null;
};

export default ShowPrompt;