import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const modalTypes = ['info', 'confirm', 'error', 'warning'];

type ModalState = {
  for: '' | 'yesno' | 'onboard';
  title: string;
  onConfirm: () => void;
  message?: string | JSX.Element;
  type?: 'info' | 'confirm' | 'error' | 'warning';
  onCancel?: () => void;
  className?: string;
  yesText?: string;
  noText?: string;
  noClass?: string;
  yesClass?: string;
};

const modalStateAtom = atom({
  default: {
    for: '',
    type: 'info' as ModalState['type'],
    title: '',
    message: '' as ModalState['message'],
    onConfirm: () => { },
    onCancel: () => { },
    className: '',
    iconColor: 'text-light3Brand2',
    yesText: 'Continue',
    noText: 'Go Back',
    noClass: '',
    yesClass: '',
  },
  key: 'ModalState',
});

const getIconColor = (type: ModalState['type']) => {
  switch (type) {
    case 'info':
      return 'text-light3Brand2';
    case 'confirm':
      return 'text-brand';
    case 'error':
      return 'text-red-400';
    case 'warning':
      return 'text-yellow-500';
    default:
      return 'text-light3Brand2';
  }
};

export const useModalState = () => {
  const modalState = useRecoilValue(modalStateAtom);
  const setModalState = useSetRecoilState(modalStateAtom);

  const makeDefault = () => {
    setModalState({
      for: '',
      type: 'info',
      title: '',
      message: '',
      onConfirm: () => { },
      onCancel: () => { },
      className: '',
      iconColor: 'text-light3Brand2',
      yesText: 'Continue',
      noText: 'Go Back',
      noClass: '',
      yesClass: '',
    });
  };

  const updateModal = (modalState: ModalState) => {
    setModalState({
      type: modalState?.type ?? "info",
      message: modalState?.message ?? "",
      onCancel: modalState?.onCancel ? modalState?.onCancel : () => { },
      className: modalState?.className ?? 'h-[25rem]',
      yesText: modalState?.yesText ?? "Continue",
      noText: modalState?.noText ?? "Go Back",
      iconColor: getIconColor(modalState.type),
      noClass: modalState?.noClass ?? '',
      yesClass: modalState?.yesClass ?? '',
      ...modalState,
    });
  };

  const openModal = (newState: ModalState) => {
    updateModal(newState);
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, for: '' }));
  };

  return { modalState, openModal, closeModal, updateModal, makeDefault };
};

// ========================= Usage =========================
const reportModalStateAtom = atom({
  default: false,
  key: 'ReportModalState',
});

export const useReportIssueModal = () => {
  const isOpen = useRecoilValue(reportModalStateAtom);
  const setReportModalState = useSetRecoilState(reportModalStateAtom);

  const openReportModal = () => {
    setReportModalState(true);
  };

  const closeReportModal = () => {
    setReportModalState(false);
  };

  return { isOpen, openReportModal, closeReportModal };
};