/**
 * @description This function is used to chunk an array into an array of arrays with a length of 2. 
 * @param array The array to be chunked 
 * @returns An array of arrays with a length of given size
 * @example
 * chunkArray([1, 2, 3, 4, 5, 6, 7, 8, 9], 2)
 * Returns [[1, 2], [3, 4], [5, 6], [7, 8], [9]] 
 */
export function chunkArray<T>(array: T[], size: number) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

/**
 * @description This function returns the difference between two dates in a human-readable format.
 * @param date The date to compare with the current date
 * @returns A string representing the time difference between the two dates
 * @example
 * formatTimeDifference(new Date('2022-01-01T00:00:00.000Z'))
 * Returns '1 Day'
 * @example
 * formatTimeDifference(new Date('2022-01-01T00:00:00.000Z'))
 * Returns '1 Day 1 Hr'
 * @example
 * formatTimeDifference(new Date('2022-01-01T00:00:00.000Z'))
 * Returns '1 Day 1 Hr 1 Min'
 * @example
 * formatTimeDifference(new Date('2022-01-01T00:00:00.000Z'))
 * Returns '1 Day 1 Hr 1 Min 1 Sec'
 */
export function formatTimeDifference(date: Date): string {
  const now = new Date();
  const diffInMs = date.getTime() - now.getTime();

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  const dayLabel = days === 1 ? 'Day' : 'Days';
  const formattedDays = days > 0 ? `${days} ${dayLabel} ` : '';
  const formattedHours = remainingHours > 0 ? `${remainingHours} Hr ` : '';
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes} Min ` : '';
  const formattedSeconds = remainingSeconds > 0 ? `${remainingSeconds} Sec` : '';

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
}