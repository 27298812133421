import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
// import { recoilPersist } from 'recoil-persist';
// const { persistAtom } = recoilPersist();

type DrawerStates =
  | null
  | { screen: 'handel-buy'; }
  | { screen: 'handel-sell'; }
  | { screen: 'nft-info'; }
  | { screen: 'mint-nft'; nft: NFTResponseData; }
  | { screen: 'login'; cb: () => void; }
  | { screen: 'wallet'; cb: () => void; }
  | { screen: 'balance'; cb: () => void; }
  | { screen: 'share'; options: any; }
  | { screen: 'claim-reward'; options: { market: NFTStoredData; earnedDividends: bigint, earnedRewards: bigint; }; }
  | { screen: 'claim-fees'; options: { ownerFeesCollected: bigint, creatorFeesCollected: bigint; }; }
  | { screen: 'nft-exist'; data: NFTExist; };

const DrawerStateAtom = atom<DrawerStates>({
  key: 'DrawerState',
  default: null,
});

// Drawer hook, to open and close drawer and set the screen
const useDrawerState = () => {
  const setDrawerState = useSetRecoilState(DrawerStateAtom);

  const drawerState = useRecoilValue(DrawerStateAtom);

  const openBuyDrwer = () => {
    setDrawerState({ screen: 'handel-buy' });
  };

  const openSellDrawer = () => {
    setDrawerState({ screen: 'handel-sell' });
  };

  const openWalletDrawer = (cb?: () => void) => {
    if (!cb) {
      cb = () => { };
    }
    setDrawerState({ screen: 'wallet', cb });
  };

  const openBalanceDrawer = (cb?: () => void) => {
    if (!cb) {
      cb = () => { };
    }
    setDrawerState({ screen: 'balance', cb });
  };

  const closeDrawer = () => {
    setDrawerState(null);
  };

  const openLoginDrawer = (cb?: () => void) => {
    if (!cb) {
      cb = () => { };
    }
    setDrawerState({ screen: 'login', cb });
  };

  const openNftInfoDrawer = () => {
    setDrawerState({ screen: 'nft-info' });
  };

  const closeLoginDrawer = () => {
    if (drawerState?.screen == 'login') {
      setDrawerState(null);
      drawerState.cb();
    }
  };

  // opens the mint drawer
  const openMintDrawer = (nft: NFTResponseData) => {
    setDrawerState({ screen: 'mint-nft', nft });
  };

  const openShareDrawer = (options: any) => {
    setDrawerState({ screen: 'share', options });
  };

  const openClaimRewardDrawer = (options: any) => {
    setDrawerState({ screen: 'claim-reward', options });
  };

  const openClaimFeesDrawer = (options: any) => {
    setDrawerState({ screen: 'claim-fees', options });
  };

  const openNftExistDrawer = (data: NFTExist) => {
    setDrawerState({ screen: 'nft-exist', data });
  };

  const isOpen = () => {
    return !!drawerState;
  };

  return {
    drawerState,
    openWalletDrawer,
    openBalanceDrawer,
    closeDrawer,
    openBuyDrwer,
    openSellDrawer,
    openLoginDrawer,
    closeLoginDrawer,
    openMintDrawer,
    isOpen,
    openShareDrawer,
    openNftInfoDrawer,
    openClaimRewardDrawer,
    openClaimFeesDrawer,
    openNftExistDrawer,
  };
};

export default useDrawerState;

// bottom buy button drawer state
export const BuyBtnDrawerStateAtom = atom<boolean | null>({
  key: 'BuyBtnDrawerState',
  default: null,
});

export const useBuyBtnDrawer = () => {
  const setDrawerState = useSetRecoilState(BuyBtnDrawerStateAtom);
  const buyBtnDrawer = useRecoilValue(BuyBtnDrawerStateAtom);
  return [buyBtnDrawer, setDrawerState] as [boolean | null, typeof setDrawerState];
};

// bottom show/hide nft drawer state
export const NFTShowStateAtom = atom<boolean>({
  key: 'NFTShowState',
  default: false,
});

export const useNFTShowState = () => {
  const setNFTShowState = useSetRecoilState(NFTShowStateAtom);
  const nftShowState = useRecoilValue(NFTShowStateAtom);
  return [nftShowState, setNFTShowState] as [boolean, typeof setNFTShowState];
};