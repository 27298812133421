import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

type UserState =
  | {
    first_name: string;
    last_name: string;
    email: string;
    img_url: string;
    id: number;
    is_active: boolean;
    public_address: string;
    updated_at: string;
    session_id?: string;
    topics?: Array<string>;
  }
  | undefined
  | null;

// Full User State
const UserStateAtom = atom<UserState>({ default: null, key: 'UserState3' });

const useUserState = () => {
  const setUserState = useSetRecoilState(UserStateAtom);

  const userState = useRecoilValue(UserStateAtom);
  return [userState, setUserState] as [UserState, typeof setUserState];
};
export default useUserState;

// Minimal User State
const OtherUserStateAtom = atom<MinimalUser | null>({
  default: null,
  key: 'UserState2',
});

export const useOtherUserState = () => {
  const setUserState = useSetRecoilState(OtherUserStateAtom);

  const userState = useRecoilValue(OtherUserStateAtom);
  return [userState, setUserState] as [MinimalUser, typeof setUserState];
};
