import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export const LoadMoreBtn: React.FC<{ loadMore?: () => void; className?: string; }> = ({ loadMore, className }) => {
  return loadMore ? (
    <>
      <div className={twMerge("w-full flex justify-center cursor-pointer", className)}
        onClick={loadMore}
      >
        <span className="bg-lightBrand2 font-thin text-0 rounded-[5px] p-2 mr-4 mt-4 px-4 shadow-md">
          <FontAwesomeIcon icon={faAnglesDown} className='animate-pulse' /> Load More
        </span>
      </div>
    </>
  ) : null;
};
