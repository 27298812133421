import { useEffect, useMemo, useState } from 'react';
import { PrimaryBtn } from './Buttons';
import { usePublicClient, useSendTransaction } from 'wagmi';
import { appConfig } from '../config';
import toast from 'react-hot-toast';
import MemoButtonLoader from './ButtonLoader';
import { formatError } from '../Helpers/web3utils';
import useDrawerState from '@/atoms/drawerState';
import useUserState from '@/atoms/userState';
import { twMerge } from 'tailwind-merge';
import { DisplayPrice } from './DisplayPrice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { nftDbFile } from '@/firebase/realtimeDb';
import { useNftSotredDataState } from '@/atoms/marketState';
import { axiosAIInstance, axiosInstance } from '@/config/axiosInstance';
import { chooseRandomEmoji } from '@/config/emoji';
import { Line } from 'rc-progress';


export const MintNftDrawer: React.FC<{
  data: NFTResponseData;
}> = ({ data }) => {
  const { waitForTransactionReceipt } = usePublicClient();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const drawerManager = useDrawerState();
  const [mintData, setMintData] = useState(data);
  const [nftData, setNftData] = useNftSotredDataState();
  const [reaction, setReaction] = useState<EmojiReaction | null | undefined>(null);
  const emojie = useMemo(() => reaction ? chooseRandomEmoji(reaction?.Mood) : null, [reaction]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (uploading) {
      setProgress(1);
      const interval = setInterval(() => {
        setProgress(p => {
          if (p >= 80) clearInterval(interval);
          return p + 10;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [uploading]);


  useEffect(() => {
    if (mintData?.txData || (drawerManager.drawerState?.screen == "mint-nft" && drawerManager.drawerState.nft.phrase)) {
      if (!reaction) {
        // @ts-ignore
        const title = mintData.title || drawerManager.drawerState.nft.title;
        // @ts-ignore
        const phrase = mintData.phrase || drawerManager.drawerState.nft.phrase;
        // @ts-ignore
        const author = mintData.author || drawerManager.drawerState.nft.author;
        // get the reaction for user minted phrase from AI
        axiosAIInstance.post<EmojiReaction>('/phrase/', {
          "title": title,
          "body": phrase,
          "author": author,
        }).then((res) => { setReaction(res.data); })
          .catch((e) => { setReaction(undefined); console.log(("Not Reaction")); });
      }
    }
  }, [mintData, drawerManager.drawerState]);

  useEffect(() => {
    if (mintData && mintData.marketId && !mintData.txData) {
      nftDbFile.toMint(mintData.marketId).then((d) => {
        if (d.txData) setMintData((prev) => ({ ...prev, txData: d.txData }));
      });
    }
  }, [mintData]);

  const { data: minted, sendTransaction } = useSendTransaction({
    onError: (e) => {
      toast.error(formatError(e));
      setIsLoading(false);
      drawerManager.closeDrawer();
    },
    onSuccess(data, variables, context) {
      toast.success('NFT Minted Successfully');
      waitForTransactionReceipt({ hash: data.hash })
        .then((receipt) => {
          // setIsLoading(false);
          // @ts-ignore
          setNftData(p => ({ ...p, minted: true }));
          setProgress(100);
          setTimeout(() => drawerManager.closeDrawer(), 1000);
        });
    },
  });

  const mintNft = async () => {
    setUploading(true);

    const formData = drawerManager.drawerState?.screen == "mint-nft" ? drawerManager.drawerState.nft.formData : null;
    if (formData) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await axiosInstance.post<ServerResponse<NFTResponseData>>("/api/mint/nft", formData, config)
        .then(res => {
          if (res.data.data) {
            sendTransaction({
              to: appConfig.mainAddress,
              // this data is sent from server to mint nft
              data: res.data.data.txData.data as Address,
              value: BigInt(res.data.data.txData.value),
              // gasPrice: 40_000n,
            });
            // @ts-ignore
          } else if (res.data?.hash) {
            drawerManager.openNftExistDrawer(res.data as unknown as NFTExist);
          } else {
            toast.error(res.data?.error ?? "Failed to mint NFT");
            drawerManager.closeDrawer();
          }
        }).catch(e => {
          toast("Something went wrong!");
          drawerManager.closeDrawer();
        });
    } else if (mintData.txData.data) {
      sendTransaction({
        to: appConfig.mainAddress,
        // this data is sent from server to mint nft
        data: mintData.txData.data as Address,
        value: BigInt(mintData.txData.value),
        // gasPrice: 40_000n,
      });
    } else {
      toast.error("Failed to mint NFT");
      drawerManager.closeDrawer();
    }
  };

  // const cantMint = !sendTransaction || !mintData.txData || !mintData.txData.data;

  return (
    <>
      <div className={`transition-all duration-300`}>

        <center className={` top-0 duration-300 min-h-full min-w-full`}>
          <div className="min-h-full relative w-[70%] rounded-[5px]">
            <img
              src={mintData.thumbnail}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.classList.remove('img-loading');
              }}
              className={`object-contain min-h-full min-w-full py-1 `} />

            {/* {mintData?.marketId != nftData?.marketId && <span
              className='absolute -bottom-2 -right-[3rem] p-2 cursor-pointer'
              onClick={() => {
                drawerManager.closeDrawer();
                navigate(`/markets/${mintData.marketId ?? ""}`);
              }}>
              <FontAwesomeIcon icon={faEye} className='text-[16px] cursor-pointer text-3' />
            </span>} */}

          </div>
        </center>
      </div>

      <div className={`duration-300 rounded-[5px] my-2 mx-2 transition-all `}>
        <MintNftCard data={mintData as any} />
      </div>

      <div className="flex justify-between items-center rounded-[5px] gap-2">
        <span className="text-2 flex w-full gap-2 p-1 text-f12 font-[500] ">
          {reaction ? <div className='flex w-full px-4 gap-2 bg-lightBrand py-2 rounded-[5px]'>
            <span className='text-[20px] w-[30px]'>{emojie}</span>
            <span className='text-[12px] flex items-center text-left w-full'>{reaction?.Motivation}</span>
          </div>
            : null}
        </span>
      </div>

      {progress > 0 ?
        <div className="relative mt-2 mb-10">
          <Line className=' animate-shine h-[40px] w-full rounded-[5px] z-[10] '
            strokeLinecap='square'
            percent={progress}
            strokeWidth={11}
            trailWidth={11}
            strokeColor={'#3c32a3'}
            trailColor="#c8cad0"
          />
          <p className=" animate-pulse absolute left-[40%] -mt-12 text-[white] text-[14px] z-[1000000]">
            {progress < 60 ? "Uploading..." : "Minting..."}
          </p>
        </div> : null}

      {progress == 0 && <PrimaryBtn
        onClick={() => mintNft()}
        // disable={cantMint ? "Not ready for minting, please delete and create new!" : false}
        className={`flex mt-2 items-center justify-center gap-5 h-[40px] text-white animate-shine`}
      >
        <MemoButtonLoader className="scale-110 " loading={isLoading} /> {isLoading ? "Minting..." : "Mint NFT"}
      </PrimaryBtn>}
    </>
  );
};


const MintNftCard: React.FC<{
  data: NFTResponseData;
  className?: string;
  idx?: number;
  toggleView?: () => void;
}> = ({ data, idx, className }) => {
  const [userState] = useUserState();
  const navigate = useNavigate();
  if (!data) return null;

  return (
    <div
      className={twMerge(
        `p-[10px] pb-2 rounded-[5px] justify-between flex gap-[15px] w-full bg-white `,
        className
      )}
    >
      <div className="flex flex-col gap-[3px] items-center  justify-start ">
        <img
          src={userState?.img_url ?? "/img_placeholder.svg"}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = '/img_placeholder.svg';
            e.currentTarget.classList.remove('img-loading');
          }}
          loading="lazy"
          className={`max-w-[60px] min-w-[60px] min-h-[60px] max-h-[60px] rounded-[5px] ${userState?.img_url ? "img-loading" : ""} object-cover`}
        />
      </div>

      <div className="flex flex-col items-start w-full select-none">
        <div className="flex justify-between w-full select-none">
          <span className="font-bold text-f14">{data?.title ?? ""}</span>
        </div>

        <div className="flex w-full select-none">
          <span className="font-semibold text-f12 text-justify text-2">{data?.phrase ?? ""}</span>
        </div>

        <div className="flex justify-end w-full select-none">
          <span className="font-semibold text-f10 italic text-0">{data?.author ? <>
            {"By "} <span className=" underline text-2 hover:text-brand" onClick={() => {
              navigate(`/profile/${data?.author}`);
            }}>
              {data?.author}
            </span>
          </>
            : ""}</span>
        </div>

        <span className="flex text-0 items-center gap-2">
          Fees {" "}
          <DisplayPrice
            className="text-2"
            compact
            active
            price={BigInt(0)}
          />
        </span>

      </div>

    </div>
  );
};

