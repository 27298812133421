// Checkbox.js
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

const Checkbox: FC<{
  label: string; onCheck: () => void; onUncheck: () => void; checked: boolean;
}> = ({ label, onCheck, onUncheck, checked }) => {

  return (
    <div
      className="flex items-center cursor-pointer mb-4"
      onClick={() => {
        if (checked) onUncheck();
        else onCheck();
      }}
    >
      <FontAwesomeIcon
        icon={checked ? faCheckSquare : faSquare}
        className={`text-[20px] ${checked ? 'text-brand' : 'text-3'}`}
      />
      <span className="ml-2 text-[16px]">{label}</span>
    </div>
  );
};

export default Checkbox;
