import { PresentNft } from '@/pages/MarketListingPage/components/MarketCards';
import { faCloudArrowDown, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,

  XIcon,
  FacebookIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import ShowPrompt from './ShowPrompt';
import { captureDivToImage3 } from '@/pages/NftGeneratePage.tsx/helpers';
import { useNftSotredDataState } from '@/atoms/marketState';
import { useAccount } from 'wagmi';
import { nftDbFileMatrics } from '@/firebase/realtimeDb';

const titles = [
  "Discover the Latest Phrase NFT: Unique, Rare, and Ready to Trade!",
  "Own a Piece of Digital Art: Check Out This Phrase NFT on Phrase.Trade!",
  "Unlock the Value of Words - Explore This Exclusive Phrase NFT Now!",
  "Dive Into the World of Unique Phrases - Get This NFT Before It's Gone!",
  "Transform Words Into Wealth - Discover This Unique Phrase NFT!",
];

const NftShareDrawer: React.FC<{ options: any; }> = ({ options }) => {
  const currentUrl = `${import.meta.env.VITE_BE_URL}/nft/${options.nft.id}\n\n`;
  const plainUrl = currentUrl.replace('https://', '').replace('http://', '');
  const columnRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [nft] = useNftSotredDataState();
  const randomTitle = titles[Math.floor(Math.random() * titles.length)];
  const account = useAccount();

  useEffect(() => {
    if (columnRef.current) {
      setWidth((columnRef.current.offsetWidth / 2) - 8);
    }
  }, [columnRef]);

  const handleCaptureAndSave = async () => {
    if (!capturing) {
      setCapturing(true);
      const imgUrl = await captureDivToImage3();
      const link = document.createElement('a');
      link.href = imgUrl;
      link.setAttribute('download', `${options.nft.title ?? "nft"}.png`); // or any other extension
      document.body?.appendChild(link);
      link?.click();
      link?.parentNode?.removeChild(link);
      setCapturing(false);
    }
  };

  return (
    <div className='px-4 relative' ref={columnRef}>
      <ShowPrompt message='Download the NFT image and attach it to your message to enhance your share.' className='mb-4' />

      <PresentNft
        id="text-with-img-nft"
        nft={options.nft} width={width}
        className='w-1/2 mb-6  mt-6 text-left'
      />

      <div
        onClick={handleCaptureAndSave}
        className=" flex-shrink absolute bottom-[9rem] sm:right-[10rem] xs:right-[6rem] right-[3rem]">
        <FontAwesomeIcon
          icon={faCloudArrowDown}
          className={` cursor-pointer text-[20px] ${capturing ? "text-light3Brand" : "text-brand"} `} />
      </div>

      <div className=" w-fit p-2 px-4 shadow-sm bg-white rounded-[5px] mb-4">
        <p className="text-center text-2 text-f14 font-bold ">{plainUrl}
          <FontAwesomeIcon icon={faPaperclip} className='ml-2 text-[17px] text-light3Brand'
            onClick={(e: any) => {
              navigator.clipboard.writeText(currentUrl);
              toast('The NFT link has been successfully copied!');
            }}
          /> </p>
      </div>

      <div className="flex gap-4 justify-center pb-2 mb-4"
        onClick={() => {
          if (account?.address && nft?.marketId) {
            nftDbFileMatrics.setShared(nft.marketId as string, account.address);
          }
        }}
      >
        <TwitterShareButton title={randomTitle + '\n\n'} hashtags={["PhraseTrade", 'NFT']}
          url={currentUrl} related={[]} >
          <XIcon size={35} className=' rounded-[7px] shadow-md animate-wave1' />
        </TwitterShareButton>

        <FacebookShareButton url={currentUrl} >
          <FacebookIcon size={35} className=' rounded-[7px] shadow-md animate-wave2' />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={currentUrl} appId={import.meta.env.VITE_FB_APP_ID}>
          <FacebookMessengerIcon size={35} className=' rounded-[7px] shadow-md animate-wave3' />
        </FacebookMessengerShareButton>

        <PinterestShareButton url={currentUrl} media={nft.thumbnail ?? ''}>
          <PinterestIcon size={35} className=' rounded-[7px] shadow-md animate-wave4' />
        </PinterestShareButton>

        <RedditShareButton url={currentUrl} title={randomTitle}>
          <RedditIcon size={35} className=' rounded-[7px] shadow-md animate-wave1' />
        </RedditShareButton>

        <TelegramShareButton url={currentUrl} title={randomTitle + '\n'}>
          <TelegramIcon size={35} className=' rounded-[7px] shadow-md animate-wave2' />
        </TelegramShareButton>

        <WhatsappShareButton url={currentUrl} title={randomTitle}>
          <WhatsappIcon size={35} className=' rounded-[7px] shadow-md animate-wave3' />
        </WhatsappShareButton>

        {/* <div
          className="flex h-[35px] w-[35px] bg-gray-500 shadow-md animate-wave4 rounded-lg"
          
        >
          <FontAwesomeIcon icon={faLink} className='text-white text-[18px] m-auto' />
        </div> */}
      </div>
    </div>
  );
};

export default NftShareDrawer;
