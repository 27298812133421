import { useNavigate } from 'react-router-dom';
import { view } from '../../Helpers/bigintUtils';
import { formatAddress } from '../../Helpers/web3utils';
import { dateToLocalFormat } from '@/Helpers/date';

export const UserCardSm: React.FC<{ user: Holders; }> = ({ user }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => user.public_address && navigate('/profile/' + user.public_address)}
      className={`flex flex-col ${user?.highlight ? "animate-fade" : "bg-white"}
       items-center shadow-sm rounded-[10px] justify-center w-full cursor-pointer relative `}
    >
      {/*  */}
      <div className={`flex flex-col items-center justify-center w-full h-full p-4 rounded-[10px]`}>
        <div className="flex w-full ">
          {/* profile img section */}
          <span className="flex items-center flex-grow">
            <img
              className="w-[35px] h-[35px] rounded-[5px] mr-[10px] img-loading"
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/img_placeholder.svg';
                e.currentTarget.classList.remove('img-loading');
              }}
              loading='lazy'
              src={user.img_url || '/dplaceholder.png'}
              alt="user profile"
            />
            {/* demographics */}
            <span className="flex flex-col w-full">
              <span className="flex items-center font-semibold text-f14">
                {user?.first_name ? user.first_name : 'Anonymous'}
                {user?.shares && <div className="ml-auto text-2 text-f10">
                  {showShares(user?.shares)}
                </div>}
                {user?.since && <div className="ml-auto text-2 text-f10">
                  {dateToLocalFormat(user?.since as any)}
                </div>}
              </span>
              <span className="flex justify-between w-full text-2 ">
                <span className="font-semibold text-f12">
                  <span className=" max-w-[70px] rounded-lg ">
                    {formatAddress(user?.public_address)}{' '}
                  </span>
                </span>
              </span>
            </span>
          </span>

        </div>
      </div>
    </div>
  );
};


export const showShares = (shares: string | bigint) => {
  if (shares == '' || shares == null || shares == undefined) return '';
  const shareStr = BigInt(shares) == 1000000000000000000n ? 'Share' : 'Shares';
  return view(shares) + ' ' + shareStr;
};
