import useUserState from '@/atoms/userState';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExportIcon from '@/SVG/ExportIcon';
import useDrawerState from '@/atoms/drawerState';
import { useProtection } from '@/Helpers/useProtection';
import { useAccount, useDisconnect } from 'wagmi';
import { useModalState } from '@/atoms/modalState';
import { axiosInstance } from '@/config/axiosInstance';
import { clearAllData } from '@/Helpers/localStorage';
import { deleteInviteCode } from '@/Helpers/register';

export default function Settings() {
  const [user, setUser] = useUserState();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [protect] = useProtection();
  const drawerManager = useDrawerState();
  const { openModal, closeModal } = useModalState();
  const { disconnect } = useDisconnect();

  const handleDeleteAccount = () => {
    openModal({
      for: 'yesno',
      type: "error",
      title: "Delete Your Account!",
      yesText: "Delete Account",
      yesClass: "bg-red-200 text-red-900",
      noText: "Skip",
      noClass: " bg-green-100 text-green-800",
      message: "Your user data will be deleted. However, all NFT data will remain on our platform. You can log in again to recreate your account.",
      onConfirm: () => {
        axiosInstance.delete(`/api/auth/unregister`)
          .then((res) => {
            if (res.data.message) {
              clearAllData().then(r => {
                disconnect();
                closeModal();
                navigate("/");
                deleteInviteCode();
              });
            }
          });
      },
    });
  };

  useEffect(() => { if (!user) navigate("/"); }, [user]);

  return (
    <div className="h-full overflow-y-auto">
      <div className="text-2 px-[15px] flex flex-col gap-[10px] justify-center">

        <div className=' text-[18px] mt-10 mb-0 poppins-500 flex justify-between ' style={{}}>
          Explorer
        </div>

        <div className=' ml-2 flex flex-col leading-10'>
          <span className="poppins-500 text-f14">Arbitrum Scan</span>
          <span className="poppins-500 text-f12 flex justify-start"
          >
            Phrase Trade Main Contract<ExportIcon
              onClick={() => {
                window.open(`${import.meta.env.VITE_TESTNET_EXPLORER}/address/${import.meta.env.VITE_PHRASE_TRADE_MAIN}`, '_blank');
              }}
              className='ml-2 translate-y-2 cursor-pointer' fill='#3c32a3' />
          </span>
          <span className="poppins-500 text-f12 flex justify-start"
          >
            Phrase Trade NFT Contract<ExportIcon
              onClick={() => {
                window.open(`${import.meta.env.VITE_TESTNET_EXPLORER}/address/${import.meta.env.VITE_PHRASE_TRADE_NFT}`, '_blank');
              }}
              className='ml-2 translate-y-2 cursor-pointer' fill='#3c32a3' />
          </span>
          <span className="poppins-500 text-f12 flex justify-start"
          >
            Your Account<ExportIcon
              onClick={() => {
                window.open(`${import.meta.env.VITE_TESTNET_EXPLORER}/address/${address}`, '_blank');
              }}
              className='ml-2 translate-y-2 cursor-pointer' fill='#3c32a3' />
          </span>
        </div>


        <div className=' text-[18px] mt-8 mb-0 poppins-500 flex justify-between ' style={{}}>
          Sensitive Settings
        </div>

        <div className=' ml-2 flex flex-col leading-10'>
          <span className="poppins-500 text-f14 ">Wallet</span>
          <span className="poppins-500 text-f12 cursor-pointer"
            onClick={() => {
              protect(() => drawerManager.openWalletDrawer());
            }}
          >
            Export Wallet
          </span>
          <span className="poppins-500 text-f12 cursor-pointer"
            onClick={() => {
              protect(() => drawerManager.openBalanceDrawer());
            }}
          >
            Account Balance
          </span>
        </div>

        <div className=' ml-2 flex flex-col mt-6 leading-10'>
          <span className="poppins-500 text-f14 justify-between">Account</span>
          <span className="poppins-500 text-f12 flex justify-start text-red-400 cursor-pointer"
            onClick={handleDeleteAccount}
          >
            Delete Account
          </span>
          <span className="poppins-500 text-f12 flex justify-start cursor-pointer">
            See your account details
          </span>
          <span className="poppins-500 text-f12 flex justify-between cursor-pointer">Clear Cache (Limited to Device)</span>
        </div>

        <div className=' ml-2 flex flex-col w-full items-center text-3 my-[10rem] leading-10'>
          <span className="poppins-500 text-f14 justify-between">All Rights Reserved Phrase.Trade</span>
          {/* <span className="poppins-500 text-f12 justify-between">Near 3 28A Street - Al Quoz - Dubai - UAE </span> */}
          {/* <span className="poppins-500 text-f12 justify-between">Al Quoz - Dubai - UAE </span> */}

          <span className="poppins-500 text-f12 flex justify-between">
            <a href={`${import.meta.env.VITE_FE_URL}/privacy-policy`} className='px-2 underline' target="_blank" rel="noreferrer">Privacy Policy</a>
            |
            <a href={`${import.meta.env.VITE_FE_URL}/terms-of-service`} className='px-2 underline' target="_blank" rel="noreferrer"> Terms of Service</a>
          </span>

          <span className="poppins-500 text-f12 flex justify-between">
            <a href="https://docs.phrase.trade" className='px-2 underline  text-light3Brand' target="_blank" rel="noreferrer">Docs</a>
            |
            <a href="https://blogs.phrase.trade" className='px-2 underline  text-light3Brand' target="_blank" rel="noreferrer">Blogs</a>
            |
            <a href="https://discord.gg/8ZdXvmjJ" className='px-2 underline  text-light3Brand' target="_blank" rel="noreferrer">Discord</a>
            |
            <a href="https://twitter.com/phrasedottrade" className='px-2 underline  text-light3Brand' target="_blank" rel="noreferrer">Twitter</a>
          </span>

          <a className="poppins-500 text-f12 flex justify-between underline "
            href="mailto:support@phrase.trade" target="_blank" rel="noreferrer"
          >support@phrase.trade</a>
        </div>
        <div className=' ml-2 flex flex-col w-full items-center text-3 mt-[6rem] pb-[6rem] leading-10'>
        </div>

      </div>
    </div>
  );
}
