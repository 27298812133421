import { Dispatch, SetStateAction } from "react";
import { realtimeDb } from "./instance";
import { ref, get, child, onChildAdded, onChildChanged, query, orderByChild, equalTo, limitToLast, limitToFirst, set, orderByValue, onChildRemoved } from "firebase/database";

const rootPath = `${import.meta.env.VITE_REALTIME_DB_ROOT}`; // ganache
const version = `${import.meta.env.VITE_REALTIME_DB_VERSION}`;

const ganacheMainDb = ref(realtimeDb, `${rootPath}/${version}/PhraseTradeMain`);
const ganacheNftDb = ref(realtimeDb, `${rootPath}/${version}/PhraseTradeNFT`);
const ganacheUser = ref(realtimeDb, `${rootPath}/${version}/users`);
const ganacheInstalls = ref(realtimeDb, `${rootPath}/${version}/installs`);
const ganacheOtherUser = ref(realtimeDb, `${rootPath}/${version}/usersPublic`);
const ganachePreferences = ref(realtimeDb, `${rootPath}/${version}/preferences`);

// custom implementation of the firebase realtime database, to be used in the app
// for elasticity to change the database in the future

export const preferencesDb = {
  getNextRewardAt: async () => {
    return Number((await get(child(ganachePreferences, "nextRewardAt"))).val() as string ?? '0');
  }
};

export const appInstallDb = {
  get: async (address: Address) => {
    return Number((await get(child(ganacheInstalls, address))).val() as string ?? "Infinity");
  },
  set: async (address: Address, date = Date.now()) => {
    return (await set(child(ganacheInstalls, address), date));
  },
};

export const mainDbUser = {
  get: async (address: string) => {
    return (await get(child(ganacheUser, `${address}`))).val();
  }
};

export const mainDbOtherUser = {
  get: async (address: string) => {
    return (await get(child(ganacheOtherUser, `${address}`))).val();
  }
};

export const nftDbMinData = {
  get: async (marketId: string) => {
    const nft = (await get(child(ganacheNftDb, `files/minted/${marketId}`))).val();
    return { title: nft.title, thumbnail: nft.thumbnail };
  }
};


export const nftDbFile = {
  deleteMarket: async (marketId: string) => {
    return await set(child(ganacheNftDb, `files/unminted/${marketId}`), null);
  },
  getMinted: async (marketId: string) => {
    return (await get(child(ganacheNftDb, `files/minted/${marketId}`))).val() as unknown as NFTStoredData;
  },
  getMintedByCID: async (cid: string) => {
    const q = query(child(ganacheNftDb, `files/minted`), orderByChild("key"), equalTo(cid));
    const d = Object.values((await get(q)).val());
    return (d.length > 0 ? d[0] : {}) as unknown as NFTStoredData;
  },
  getAllMintedOwnedBy: async (address: string) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("owner"), equalTo(address));
    console.log({ minted });
    return (await get(minted)).val() as unknown as Record<string, NFTStoredData>;
  },
  getUnminted: async (marketId: string) => {
    return (await get(child(ganacheNftDb, `files/unminted/${marketId}`))).val() as unknown as NFTStoredData;
  },

  // ================== Top Minted =======================
  getTopMintedListner: (callback: (d: Record<string, NFTStoredData>) => void, len = 20) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("sharesSupply"), limitToLast(len));
    const unSub = onChildAdded(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return unSub;
  },
  getTopMintedUpdateListner: (callback: (d: Record<string, NFTStoredData>) => void, len = 20) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("sharesSupply"));
    const unSub = onChildChanged(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return unSub;
  },

  // =================== Rank List =========================
  getRankListner: (callback: (d: Record<string, NFTStoredData>) => void, len = 20) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("ttv"), limitToLast(len));
    const unSub = onChildAdded(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return unSub;
  },


  // ================== sign for minting =======================
  toMint: async (cid: string) => {
    return (await get(child(ganacheNftDb, `signs/${cid}`))).val() as unknown as NFTMintSign;
  },


  // ================== New Minted =======================
  getNewMintedListner: (callback: (d: Record<string, NFTStoredData>) => void, len = 20) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("mintedOn"), limitToLast(len));
    const unSub = onChildAdded(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return unSub;
  },
  getNewMintedUpdateListner: (callback: (d: Record<string, NFTStoredData>) => void, len = 20) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("mintedOn"), limitToLast(len));
    const unSub = onChildChanged(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return unSub;
  },


  // ================== Holding =======================
  getHoldingsFirstBatch: async (address: string, len = 20) => {
    const nfts = query(child(ganacheNftDb, `files/holdings/${address}`), limitToFirst(len));
    const nftIdsMap = (await get(nfts)).val() as unknown as Record<string, HoldingData>;
    const nftList = nftIdsMap ? Object.keys(nftIdsMap).map(k => k) : [];

    const nftData = await Promise.all(nftList.map(async nftId => {
      return {
        ...(await get(child(ganacheNftDb, `files/minted/${nftId}`))).val(),
        shares: nftIdsMap[nftId].shares
      } as unknown as NFTStoredData;
    }));
    return nftData;
  },

  // ================== Holders =======================
  getHoldersCount: async (marketId: string) => {
    const holders = query(child(ganacheNftDb, `files/holders/${marketId}`));
    let count = 0;
    (await get(holders)).forEach(() => { count++; });
    return count;
  },
  getHoldersLastBatch: async (marketId: string, len = 20) => {
    const holders = query(child(ganacheNftDb, `files/holders/${marketId}`), orderByChild("shares"), limitToLast(len));
    const holdersMap = (await get(holders)).val() as unknown as Record<string, HoldingData>;
    const holderAddresses = holdersMap ? Object.keys(holdersMap) : [];
    const holderData = await Promise.all(holderAddresses.map(async address => {
      return {
        ...(await get(child(ganacheOtherUser, `/${address}`))).val(),
        shares: holdersMap[address].shares
      } as unknown as Holders;
    }));
    return holderData;
  },
  getHoldersLastBatchListner: (marketId: string, callback: (d: Record<string, Holders>) => void, len = 20) => {
    const holders = query(child(ganacheNftDb, `files/holders/${marketId}`), orderByChild("shares"));
    const unSub = onChildAdded(holders, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },
  getHoldersSharesUpdateListner: (marketId: string, callback: (d: Record<string, HoldingData>) => void) => {
    const holders = query(child(ganacheNftDb, `files/holders/${marketId}`), orderByChild("shares"));
    const unSub = onChildChanged(holders, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },

  // ================== Watchlist =======================
  addToWatchlist: async (address: string, marketId: string) => {
    await set(child(ganacheNftDb, `files/watchlist/${address}/${marketId}`), Date.now());
    await set(child(ganacheNftDb, `files/watchedBy/${marketId}/${address}`), Date.now());
  },
  removeFromWatchlist: async (address: string, marketId: string) => {
    await set(child(ganacheNftDb, `files/watchlist/${address}/${marketId}`), null);
    await set(child(ganacheNftDb, `files/watchedBy/${marketId}/${address}`), null);
  },
  getWatchlistFirstBatch: async (address: string, len = 20) => {
    const watchlist = query(child(ganacheNftDb, `files/watchlist/${address}`), limitToFirst(len));
    const watchlistMap = (await get(watchlist)).val() as unknown as Record<string, boolean>;
    const watchlistIds = watchlistMap ? Object.keys(watchlistMap) : [];

    const watchlistData = await Promise.all(watchlistIds.map(async marketId => {
      return (await get(child(ganacheNftDb, `files/minted/${marketId}`))).val() as unknown as NFTStoredData;
    }));

    return watchlistData;
  },
  isWatchlisted: async (address: string, marketId: string) => {
    return (await get(child(ganacheNftDb, `files/watchlist/${address}/${marketId}`))).val() as number;
  },
  getWatchlistCount: async (marketId: string) => {
    const watchlist = query(child(ganacheNftDb, `files/watchedBy/${marketId}`));
    let count = 0;
    (await get(watchlist)).forEach(() => { count++; });
    return count;
  },
  getWatchedByLastBatch: async (marketId: string, len = 20) => {
    const watchedBy = query(child(ganacheNftDb, `files/watchedBy/${marketId}`), orderByValue(), limitToLast(len));
    const watchedByMap = (await get(watchedBy)).val() as unknown as Record<string, number>;
    const watchedByAddresses = watchedByMap ? Object.keys(watchedByMap) : [];
    console.log({ watchedByMap });
    const watchedByData = await Promise.all(watchedByAddresses.map(async address => {
      return {
        ...(await get(child(ganacheOtherUser, `/${address}`))).val(),
        since: watchedByMap[address]
      } as unknown as MinimalUser;
    }));
    return watchedByData;
  },
  getWatchedByLastBatchListner: (marketId: string, callback: (d: Record<string, MinimalUser>) => void, len = 20) => {
    const watchedBy = query(child(ganacheNftDb, `files/watchedBy/${marketId}`), limitToLast(len));
    const unSub = onChildAdded(watchedBy, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },
  getWatchedByLastBatchDeleteListner: (marketId: string, callback: (d: Record<string, MinimalUser>) => void, len = 20) => {
    const watchedBy = query(child(ganacheNftDb, `files/watchedBy/${marketId}`), limitToLast(len));
    const unSub = onChildRemoved(watchedBy, (snap) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },

  // ================== Like =======================
  addToLikes: async (address: string, marketId: string) => {
    await set(child(ganacheNftDb, `files/likes/${address}/${marketId}`), true);
    await set(child(ganacheNftDb, `files/likedBy/${marketId}/${address}`), true);
  },
  removeFromLikes: async (address: string, marketId: string) => {
    await set(child(ganacheNftDb, `files/likes/${address}/${marketId}`), null);
    await set(child(ganacheNftDb, `files/likedBy/${marketId}/${address}`), null);
  },
  isLiked: async (address: string, marketId: string) => {
    return (await get(child(ganacheNftDb, `files/likes/${address}/${marketId}`))).val() as boolean;
  },
  getLikeCount: async (marketId: string) => {
    const likes = query(child(ganacheNftDb, `files/likedBy/${marketId}`));
    let count = 0;
    (await get(likes)).forEach(() => { count++; });
    return count;
  },
  getLikesCountListner: (marketId: string, callback: Dispatch<SetStateAction<number>>) => {
    const addresses = query(child(ganacheNftDb, `files/likedBy/${marketId}`));
    const unSub = onChildAdded(addresses, (snap, previous) => {
      callback(p => p + 1);
    });
    const unSubRemove = onChildRemoved(addresses, (snap) => {
      callback(p => p - 1);
    });
    return () => {
      unSub();
      unSubRemove();
    };
  }
};

export const nftDbMyFile = {
  getAll: async (address: string) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("owner"), equalTo(address));
    const unminted = query(child(ganacheNftDb, `files/unminted`), orderByChild("owner"), equalTo(address));

    return { ...(await get(minted)).val(), ...(await get(unminted)).val() } as unknown as Record<string, NFTStoredData>;
  },
  getAllListner: (address: string, callback: (d: Record<string, NFTStoredData>) => void) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("owner"), equalTo(address));
    const unminted = query(child(ganacheNftDb, `files/unminted`), orderByChild("owner"), equalTo(address));

    const unSubMinted = onChildAdded(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    const unSubUnminted = onChildAdded(unminted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });

    return () => {
      unSubMinted();
      unSubUnminted();
    };
  },
  getAllUpdateListner: (address: string, callback: (d: Record<string, NFTStoredData>) => void) => {
    const minted = query(child(ganacheNftDb, `files/minted`), orderByChild("owner"), equalTo(address));

    const unSubMinted = onChildChanged(minted, (snap, previous) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });

    return () => {
      unSubMinted();
    };
  },
  getDeleteListner: (address: string, callback: (d: Record<string, NFTStoredData>) => void) => {
    const minted = query(child(ganacheNftDb, `files/unminted`), orderByChild("owner"), equalTo(address));
    const unSubMinted = onChildRemoved(minted, (snap) => {
      callback({ [snap.key as string]: { ...snap.val(), highlight: true } });
    });
    return () => {
      unSubMinted();
    };
  }
};


export const nftDbFileListen = {
  unsubscribe: null as (() => void) | null,
  startLoading: async (callback: (d: any) => void) => {
    const files = ref(realtimeDb, `${rootPath}/v1/PhraseTradeNFT/files`);
    const unSubAdd = onChildAdded(files, (snap, previous) => {

    });
    const unSubChange = onChildChanged(files, (snap, previous) => {

    });

    nftDbFileListen.unsubscribe = () => {
      unSubAdd();
      unSubChange();
    };
  },
  stopLoading: () => { nftDbFileListen.unsubscribe?.(); }
};

export const mainDbBuySellActivity = {
  getFirstBatch: async (marketId: string, len = 20) => {
    const activity = query(child(ganacheMainDb, `/BuySell:MarketId/${marketId}`), limitToFirst(len));
    return (await get(activity)).val() as unknown as Record<string, BuySellActivity>;
  },
  getFirstBatchListner: (marketId: string, callback: (d: Record<string, BuySellActivity>) => void, len = 20) => {
    // const activity = ref(realtimeDb, `${rootPath}/${version}/PhraseTradeMain/BuySell:MarketId/${marketId}`);
    const activity = query(child(ganacheMainDb, `/BuySell:MarketId/${marketId}`), limitToFirst(len));
    const unSub = onChildAdded(activity, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },
  getLastBatch: async (marketId: string, len = 20) => {
    const activity = query(child(ganacheMainDb, `/BuySell:MarketId/${marketId}`), limitToLast(len));
    return (await get(activity)).val() as unknown as Record<string, BuySellActivity>;
  },
  getLastBatchListner: (marketId: string, callback: (d: Record<string, BuySellActivity>) => void, len = 20) => {
    const activity = query(child(ganacheMainDb, `/BuySell:MarketId/${marketId}`), limitToLast(len));
    const unSub = onChildAdded(activity, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  }
};

export const mainDbUserActivity = {
  getAllActivity: async (address: string, limit = 20) => {
    const activity = query(child(ganacheMainDb, `/activity/${address}`), orderByChild("doneAt"), limitToLast(limit));
    return (await get(activity)).val() as unknown as Record<string, BuySellActivity>;
  },
  getAllActivityListner: (address: string, callback: (d: any) => void) => {
    const activity = query(child(ganacheMainDb, `/activity/${address}`));
    const unSub = onChildAdded(activity, (snap, previous) => {
      callback({ [snap.key as string]: snap.val() });
    });
    return unSub;
  },
};

export const nftDbFileMatrics = {
  setSeen: async (marketId: string, address: string) => {
    await set(child(ganacheNftDb, `files/seenBy/${marketId}/${address}`), true);
  },
  getSeenCount: async (marketId: string) => {
    const seen = query(child(ganacheNftDb, `files/seenBy/${marketId}`));
    let count = 0;
    (await get(seen)).forEach(() => { count++; });
    return count;
  },
  setShared: async (marketId: string, address: string) => {
    await set(child(ganacheNftDb, `files/sharedBy/${marketId}/${address}`), true);
  },
  getSharedCount: async (marketId: string) => {
    const shared = query(child(ganacheNftDb, `files/sharedBy/${marketId}`));
    let count = 0;
    (await get(shared)).forEach(() => { count++; });
    return count;
  }
};