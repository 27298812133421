// WAGMI Libraries
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { arbitrumSepolia, arbitrum } from 'wagmi/chains';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { publicProvider } from 'wagmi/providers/public';
import { SWRConfig } from 'swr';
import Web3AuthConnectorInstance from './Web3AuthConnectorInstance';
import { Onboarding } from '@/pages/Onboarding';
import { Layout } from '@/components/Layout';
import UserProfilePage from '@/pages/UserProfilePage';
import Settings from '@/pages/SettingsPage';
import { NftGeneratePage } from '@/pages/NftGeneratePage.tsx';
import MarketInfoPage from '@/pages/MarketInfoPage';
import { getInviteCode } from '@/Helpers/register';
import PrivacyPolicy from '@/PrivacyPolicy';
import TermsOfService from '@/TermsOfService';


// Configure chains & providers with the Public provider.
export const { chains, publicClient, webSocketPublicClient } = configureChains(
  import.meta.env.VITE_PRODUCTION_MODE == "testnet"
    ? [{
      ...arbitrumSepolia,
      "rpcUrls": {
        "default": {
          "http": [import.meta.env.VITE_ARB_SEPOLIA_RPC ?? ""]
        },
        "public": {
          "http": [import.meta.env.VITE_ARB_SEPOLIA_RPC ?? ""]
        }
      }
    }] : [{ ...arbitrum }],
  // [localhost],
  [publicProvider()]
);


// Set up client
export const config = createConfig({
  autoConnect: getInviteCode() ? true : false,
  connectors: [Web3AuthConnectorInstance(chains) as any],
  publicClient,
  webSocketPublicClient,
});

// Pass client to React Context Provider
export const ConfiguredRoutes = () => {
  return (
    <WagmiConfig config={config}>
      <RecoilRoot>
        <SWRConfig>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Onboarding />} />

              <Route path="/markets" element={<Onboarding />}>
                <Route
                  path=":marketid"
                  element={
                    <Layout>
                      <MarketInfoPage />
                    </Layout>
                  }
                ></Route>
              </Route>

              <Route path="/add" element={
                <Layout>
                  <div className="h-full overflow-y-auto">
                    <NftGeneratePage />
                  </div>
                </Layout>
              }>
              </Route>

              <Route
                path="/settings"
                element={
                  <Layout>
                    <Settings />
                  </Layout>
                }
              />

              <Route
                path="/profile"
                element={
                  <Layout>
                    <UserProfilePage />
                  </Layout>
                }
              >
                <Route
                  path=":user_addr"
                  element={
                    <Layout>
                      <UserProfilePage />
                    </Layout>
                  }
                ></Route>
              </Route>

              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              />

              <Route
                path="/terms-of-service"
                element={<TermsOfService />}
              />

              <Route path="*" element={<Navigate to="/" />} />

            </Routes>
          </BrowserRouter>
        </SWRConfig>
      </RecoilRoot>
    </WagmiConfig>
  );
};
