import { useNftSotredDataState } from '@/atoms/marketState';
import { ClaimMarketRewards } from '@/pages/MarketInfoPage/components/Tabs';
import { ClaimUserRewards } from '@/pages/UserProfilePage/userProfileTabs';
import React from 'react';
import { useAccount } from 'wagmi';

export const ClaimRewardsDrawer: React.FC<{}> = () => {
  const [nftData,] = useNftSotredDataState();
  return (
    <div className=" w-full">
      <ClaimMarketRewards
        // @ts-ignore
        nftData={nftData} />
    </div>
  );
};

export const ClaimFeesDrawer: React.FC<{}> = () => {
  const account = useAccount();
  return (
    <div className=" w-full">
      <ClaimUserRewards user_addr={account.address ?? ""} />
    </div>
  );

};
