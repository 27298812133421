import "./index.css";

import { SetFont } from '@/components/SetFont';
import { useEffect, useRef, useState } from 'react';
import { googleFonts } from '@/utils/googleFontList';
import { HexAlphaColorPicker } from "react-colorful";
import {
  faBrush,
  faCheck,
  faDownLeftAndUpRightToCenter,
  faDroplet,
  faExpand,
  faEye,
  faFont,
  faImage,
  faPlus,
  faQuoteLeft,
  faTextHeight,
  faUpDown,
  faVoicemail,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { base64ToBlob, calculateFontSize, captureDivToImage2, isValidUrl, loadFont } from "./helpers";
import { RangeSliderWithDropdown } from "@/components/RangeSliderWithDropdown";
import useUserState from "@/atoms/userState";
import Typewriter from 'typewriter-effect';
import { PrimaryBtn } from "@/components/Buttons";
import { useProtection } from "@/Helpers/useProtection";
import useDrawerState from "@/atoms/drawerState";
import { useAccount } from "wagmi";
import { isValidEthAddress } from "@/utils/getSanitizeInput";
import MemoButtonLoader from "@/components/ButtonLoader";
import VoiceRecorder from "@/components/VoiceRecorder";
import ShowPrompt from "@/components/ShowPrompt";
import ControlIcon from "@/components/ControlIcon";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "@/components/ui/Spinner";
import { TextError } from "@/components/ui/TextError";

export const NftGeneratePage: React.FC<any> = ({ }) => {
  const [protect] = useProtection();
  const drawerManager = useDrawerState();
  const account = useAccount();
  const [loading, setLoading] = useState(false);
  const [userState] = useUserState();
  const [font, setFont] = useState('Roboto'); // Initial font
  const [phrase, setPhrase] = useState('');
  const [author, setAuthor] = useState('');
  const [address, setAddress] = useState(account.address);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [bgImgFileName, setBgImgFileName] = useState('');
  const [fontSize, setFontSize] = useState<string>('1rem'); // Initial font size
  const [fontSizePercent, setFontSizePercent] = useState(70); // Initial font size factor
  const [opacityPercent, setOpacityPercent] = useState(100); // Initial opacity factor
  const [paddingTop, setPaddingTop] = useState(15); // Initial padding top factor
  const [backgroundColor, setBackgroundColor] = useState('transparent'); // Initial background color
  const [fontColor, setFontColor] = useState('black'); // Initial font color
  const [openControl, setOpenControl] = useState("image");
  const [fontWeight, setFontWeight] = useState(600);
  const [lineHeight, setLineHeight] = useState(120);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const squareRef = useRef(null);
  const [fileSizeErr, setFileSizeErr] = useState('');

  // Resize the font size based on the height of the square div
  useEffect(() => {
    const resizeFont = () => {
      if (squareRef.current) {
        // @ts-ignore
        const height = squareRef.current.offsetHeight; // Get the height of the square div
        const calculatedFontSize = `${(height * 1.8).toFixed(2)}px`; // Example calculation, adjust as needed
        // console.log({ height, calculatedFontSize });
        const padTop = parseInt((height * 0.038).toFixed(2));
        setFontSize(calculatedFontSize);
        setPaddingTop(padTop);
      }
    };
    // Call resizeFont on mount and add event listener for window resize
    resizeFont();
    window.addEventListener('resize', resizeFont);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', resizeFont);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => { setAuthor(userState?.first_name ?? ""); }, [userState]);

  // when image is uploaded, set the image as background and crop it to a square
  const handleImageUpload = (e: any) => {
    setLoading(true);
    setFileSizeErr('');

    // uploaded image file
    const file = e.target.files[0];

    // Check if the file is larger than 5MB
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxSize) {
      setFileSizeErr("File is too large! Please select a file smaller than 5MB.");
      setLoading(false); // Stop loading if the file is too large
      return;
    }

    setBgImgFileName(file.name);
    // while loading the image, show a loading gif
    setBackgroundImageUrl("/spinning-loading2.gif");
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Create a canvas and get its context
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Determine the size of the square
          const size = Math.min(img.width, img.height);

          // Set canvas size to the size of the square
          canvas.width = size;
          canvas.height = size;

          // Draw the image onto the canvas, centered to crop it into a square
          ctx?.drawImage(
            img,
            (img.width - size) / 2, // Start clipping x
            (img.height - size) / 2, // Start clipping y
            size, // Clipping width
            size, // Clipping height
            0, // Place the image at 0, 0 in the canvas
            0,
            size,
            size
          );

          // Convert the canvas to a data URL and set it as background
          const dataUrl = canvas.toDataURL('image/png');
          setBackgroundImageUrl(dataUrl);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
      setLoading(false);
    }
  };

  // when image is set, open the opacity
  useEffect(() => {
    if (backgroundImageUrl && backgroundImageUrl !== "/spinning-loading2.gif") setOpenControl("opacity");
  }, [backgroundImageUrl]);

  // when font is changed, load the font in link element 
  useEffect(() => {
    if (font) loadFont(font);
  }, [font]);

  const handleCapture = async () => {
    if (isCapturing) {

      const formData = new FormData();

      // @ts-ignore
      const thumbImgUrl = await captureDivToImage2(squareRef.current.offsetWidth, () => setIsCapturing(false));
      if (thumbImgUrl) {
        const thumbFile = new File([base64ToBlob(thumbImgUrl)], "thumbnail.png", { type: "image/png" });
        formData.append("files", thumbFile);
      }

      if (backgroundImageUrl) {
        const bgFile = new File([base64ToBlob(backgroundImageUrl)], "background.png", { type: "image/png" });
        formData.append("files", bgFile);
      }

      if (audioBlob) {
        const audioFile = new File([audioBlob], "audio.wav", { type: "audio/wav" });
        formData.append("files", audioFile);
      }

      formData.append("title", title);
      formData.append("phrase", phrase);

      formData.append("author", author);
      formData.append("creator", address as string);

      formData.append("font", font);
      formData.append("fontColor", fontColor);
      formData.append("fontWeight", fontWeight.toString());
      formData.append("fontSize", calculateFontSize(fontSize, fontSizePercent));
      formData.append("lineHeight", lineHeight.toString());
      formData.append("paddingTop", paddingTop.toString() + "rem");
      formData.append("backgroundColor", backgroundColor);
      formData.append("opacity", (opacityPercent / 100).toString());
      // @ts-ignore
      formData.append("dimension", squareRef?.current?.offsetWidth?.toString());
      formData.append("link", link);

      protect(async () => {
        if (!address || !isValidEthAddress(address)) return toast.error("Please enter an address to mint an NFT");
        // @ts-ignore
        drawerManager.openMintDrawer({
          thumbnail: thumbImgUrl,
          title: title,
          author: author,
          phrase: phrase,
          formData: formData,
        });
      });
      setIsProcessing(false);
    }
  };

  // when capturing is true, capture the div to an image
  useEffect(() => { handleCapture(); }, [isCapturing]);

  // console.log({ fontSize: calculateFontSize(fontSize, fontSizePercent), paddingTop, lineHeight });

  const handelMintNFT = async () => {
    if (phrase.length < 1) { setOpenControl("text"); return toast.error("Please enter a phrase to mint an NFT"); }
    if (title.length < 1) { setOpenControl("text"); return toast.error("Please enter a title to mint an NFT"); }
    if (author.length < 1) { setOpenControl("text"); return toast.error("Please enter an author to mint an NFT"); }
    if (link && !isValidUrl(link)) { setOpenControl("text"); return toast.error("Please enter a valid link or remove it"); }

    setIsCapturing(true);
    setIsProcessing(true);
  };

  const handleUpdatePhrase = (e: any) => {
    if (e.target.value.length > 512) return;
    setPhrase(e.target.value);
  };

  const handleUpdateTitle = (e: any) => {
    if (e.target.value.length > 15) return;
    setTitle(e.target.value);
  };

  return (
    <div className='relative pb-[8rem]' onClick={() => {
      if (!isTyping) setIsTyping(true);
    }}>

      <center className="w-full">
        <div className="text-lg font-bold text-0 text-center my-3 select-none">Mint your Phrase</div>
        <div className="">

          {/* this whole node with center tag will be captured as thumbnel by html2canvas */}
          <center className="w-[94%] shadow-sm" id="text-with-img-nft">
            <div className={`absolute w-[94%] ${isCapturing ? "border-[0px] rounded-none" : "rounded-lg border-[0px] border-lightBrand"} square-container bg-white`}
              style={{
                textAlign: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none',
                opacity: (opacityPercent / 100),
              }}
            >
              <div className="square" ref={squareRef}>
                <div className="square-content"></div>
              </div>
            </div>

            <div className="square">
              <div
                className="w-full square-content "
                style={{
                  backgroundColor: backgroundColor,
                }}
              >
                {/* this is used for demo purpose */}
                {!isTyping && <div
                  style={{
                    verticalAlign: "middle",
                    paddingTop: `${paddingTop}rem`,
                  }}
                  className="font-thin h-full">
                  <Typewriter
                    options={{
                      wrapperClassName: `text-3 phrase-text-p`,
                      cursorClassName: "text-3 font-semibold",
                      loop: true,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("Enter a Phrase!")
                        .pauseFor(1000)
                        .deleteAll()
                        .callFunction((s) => {
                          const i = Math.floor(Math.random() * googleFonts.length);
                          loadFont(googleFonts[i]);
                          s.elements.wrapper.style.fontFamily = googleFonts[i];
                        })
                        .start();
                    }}
                  />
                </div>}

                {/* this is used for accepting phrase */}
                {isTyping && <textarea
                  id="input-phrase-area"
                  placeholder='Enter your phrase here..'
                  style={{
                    paddingTop: `${paddingTop}rem`,
                    fontFamily: font,
                    backgroundColor: "transparent",
                    fontSize: calculateFontSize(fontSize, fontSizePercent),
                    resize: "none",
                    overflow: "hidden",
                    color: fontColor,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight / 100,
                  }}
                  className={`h-full placeholder:text-3 w-full text-center ${isCapturing ? "border-[0px]" : " border-[1px] rounded-lg focus:border-brand2"} outline-none`}
                  value={phrase}
                  onChange={handleUpdatePhrase}
                  hidden={isCapturing}
                />}

                <div className=""
                  style={{
                    paddingTop: `${paddingTop}rem`,
                    paddingBottom: `${paddingTop}rem`,
                    fontFamily: font,
                    backgroundColor: "transparent",
                    fontSize: calculateFontSize(fontSize, fontSizePercent),
                    resize: "none",
                    overflow: "hidden",
                    color: fontColor,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight / 100,
                  }}
                  hidden={!isCapturing}
                >
                  {phrase.split("\n").map((line, i) => (
                    <p
                      key={i}
                      className='phrase-text-p'
                      style={{
                        fontFamily: font,
                        fontSize: calculateFontSize(fontSize, fontSizePercent),
                        lineHeight: lineHeight / 100,
                        // paddingBottom: "0.2rem",
                      }}
                    >{line}</p>
                  ))}
                </div>
              </div>
            </div>
          </center>

        </div>
      </center>


      <div className="mx-2  h-full">

        {/* rendring control buttons */}
        <div className="flex overflow-y-auto text-[12px] gap-4 mt-2 p-4">
          <ControlIcon faIcon={faImage} name="image" key="image" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faEye} name="opacity" key="opacity" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faBrush} name="bgcolor" key="bgcolor" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faQuoteLeft} name="text" key="text" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faDroplet} name="color" key="color" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faVoicemail} className="px-1" name="voice" key="voice" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faFont} name="font" key="font" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faDownLeftAndUpRightToCenter} name="thickness" key="thickness" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faTextHeight} name="lineheight" key="lineheight" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faExpand} name="resize" key="resize" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faUpDown} name="move" key="move" value={openControl} setValue={setOpenControl} />
          <ControlIcon faIcon={faCheck} name="done" key="done" value={openControl} setValue={setOpenControl} />
        </div>


        {/* rendering controls */}
        <div className=" rounded-[10px] h-full mb-[10rem] bg-white m-2 p-4">

          {openControl == "image" && <div className="">
            <ShowPrompt step="1" message="Please upload a background image that enhances the mood of your phrase. Maximum file size is 5MB." className="" />
            <div className="flex flex-col">
              <div className="flex justify-center mt-2">
                <input
                  id="file-upload-image"
                  className='text-[14px] text-2 my-4'
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  hidden
                />
                <div
                  className="w-[6rem] h-[6rem] rounded-full flex items-center justify-center bg-brand2 text-white cursor-pointer hover:bg-light3Brand2 transition duration-300"
                >
                  {loading
                    ? <Spinner />
                    : bgImgFileName
                      ? <FontAwesomeIcon icon={faXmark}
                        onClick={() => {
                          setBackgroundImageUrl('');
                          setBgImgFileName('');
                        }}
                        className='text-[20px] px-1 text-white ' />
                      : <label
                        htmlFor="file-upload-image"
                      >
                        <FontAwesomeIcon
                          icon={faPlus} className='text-[20px] px-1 text-white ' />
                      </label>
                  }
                </div>
              </div>


              <div className="flex justify-center mt-2">
                {!backgroundImageUrl && <p className="text-2 text-f12 ">Upload Background Image</p>}
                {backgroundImageUrl && <p className="text-2 text-f12 ">{bgImgFileName}</p>}
              </div>
              {fileSizeErr && <TextError>{fileSizeErr}</TextError>}

            </div>
          </div>}

          {openControl == "opacity" && <div className="">
            <ShowPrompt step="2" message="Modify the visibility of the background image." className="" />
            <RangeSliderWithDropdown defaultValue={1} label='Image Visibilty' custom min={0} max={100} value={opacityPercent} setValue={setOpacityPercent} />
          </div>}

          {openControl == "bgcolor" && <center className="">
            <ShowPrompt step="3" message="Change the background color of the phrase and adjust its opacity to make the background image visible." className=" text-left mb-2" />
            <HexAlphaColorPicker defaultValue={"0xffffff00"} className="w-" color={backgroundColor} onChange={setBackgroundColor} />
          </center>}

          {openControl == "text" && <div className="">
            <label htmlFor="title" className="text-2 select-none">Title <span className=" text-red-500">*</span></label>
            <input
              name="title"
              type="text"
              className='text-0 shadow-sm w-full p-4 placeholder:text-3 t-outline text-f14 font-[500] rounded-[5px] mt-1 border-[1px] bg-white '
              placeholder='NFT Title'
              value={title}
              onChange={handleUpdateTitle}
            />
            <span className='flex w-full justify-end text-2 pt-1'>{title.length}/15</span>

            <label htmlFor="phrase" className="text-2 select-none">Phrase <span className=" text-red-500">*</span></label>
            <textarea
              name="phrase"
              className='text-0 shadow-sm w-full p-4 placeholder:text-3 t-outline text-f14 font-[500] rounded-[5px] mt-1 border-[1px] bg-white '
              placeholder='Enter your phrase here'
              value={phrase}
              onChange={handleUpdatePhrase}
            />
            <span className='flex w-full justify-end mb-4 text-2'>
              {/* <span className="flex">
                <button className="py-2 px-4 bg-brand text-white font-bold rounded-[5px]">Phrase Test</button>
                <Spinner className="pl-2 pt-1" />
                <FontAwesomeIcon icon={faCheck} className="pl-2 pt-1 text-brand2" size="2x" />
              </span> */}
              <span>
                {phrase.length}/512
              </span>
            </span>

            <label htmlFor="author" className="text-2 select-none">{"Author (Creator)"} <span className=" text-red-500">*</span></label>
            <input
              name="author"
              type="text"
              className='text-0 shadow-sm w-full p-4 placeholder:text-3 t-outline text-f14 font-[500] rounded-[5px] mt-1 border-[1px] bg-white '
              placeholder='Author Name'
              value={author.substring(0, 20)}
              onChange={(e) => { if (e.target.value.length < 21) setAuthor(e.target.value); }}
            />
            <span className='flex w-full justify-end text-2 pt-1'>{author.length}/20</span>
          </div>}

          {openControl == "color" && <center className="">
            <ShowPrompt step="5" message="Adjust the color of the phrase to match the vibe it conveys." className=" text-left mb-2" />
            <HexAlphaColorPicker defaultValue={"0x000000ff"} className="w-" color={fontColor} onChange={setFontColor} />
          </center>}

          {openControl == "voice" && <div className="">
            <ShowPrompt step="6" message="Feel free to optionally explain or read your phrase, or attach music. This will be saved with your NFT. You have up to 1 minute to record!" className=" text-left mb-2" />
            <VoiceRecorder blob={audioBlob} setBlob={setAudioBlob} />
          </div>}

          {openControl == "font" && <div className="w-full">
            <ShowPrompt step="7" message="Change the font style to make it beautiful and unique." className="mb-2" />
            <SetFont options={googleFonts} selectedOption={font} setSelectedOption={setFont} />
          </div>}

          {openControl == "lineheight" && <div className="">
            <ShowPrompt step="8" message="Adjust the line spacing of your phrase." className="mb-2" />
            <RangeSliderWithDropdown defaultValue={120} label='Line Height' custom min={20} max={200} value={lineHeight} setValue={setLineHeight} />
          </div>}

          {openControl == "thickness" && <div className="">
            <ShowPrompt step="9" message="Also, adjust the thickness of your phrase to make it more visible and stylish." className="mb-2" />
            <RangeSliderWithDropdown defaultValue={600} label='Thickness' unit={" wt"} min={100} max={900} dropdownValues={[100, 300, 400, 500, 600, 700, 800, 900]} value={fontWeight} setValue={setFontWeight} />
          </div>}

          {openControl == "resize" && <div className="">
            <ShowPrompt step="10" message="Resize your phrase to nicely fit in the frame and make it easy to read." className="mb-2" />
            <RangeSliderWithDropdown defaultValue={70} label='Text Size' custom min={0} max={140} value={fontSizePercent} setValue={setFontSizePercent} />
          </div>}

          {openControl == "move" && <div className="">
            <ShowPrompt step="11" message="Center your phrase for a more visually appealing look." className="mb-2" />
            <RangeSliderWithDropdown defaultValue={5} label='Top Space' custom unit={" px"} min={0} max={50} dropdownValues={[10, 20, 30, 50]} value={paddingTop} setValue={setPaddingTop} />
          </div>}

          {openControl == "done" && <div className="">
            <ShowPrompt step="12" message="Click to proceed with minting the NFT." className="mb-2" />
            <center className={``}>
              <PrimaryBtn
                onClick={handelMintNFT}
                className="p-2 flex text-[white] text-[18px] w-fit h-fit min-w-fit font-semibold rounded-[4px] px-8 my-4 mb-3"
              >
                <MemoButtonLoader className="scale-110 mr-4 " loading={isCapturing || isProcessing} /> {isCapturing || isProcessing ? "Processing.." : "Mint your NFT"}
              </PrimaryBtn>
            </center>
          </div>}

        </div>
      </div>
    </div>
  );
};
