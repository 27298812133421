import { nftDbFile } from '@/firebase/realtimeDb';
import { useEffect, useRef, useState } from 'react';
import { PrimaryButton, SecondaryButton } from './Buttons';
import ShowPrompt from './ShowPrompt';
import { PresentNft } from '@/pages/MarketListingPage/components/MarketCards';
import useDrawerState from '@/atoms/drawerState';
import { useNavigate } from 'react-router-dom';
import { useMarketState, useNftSotredDataState } from '@/atoms/marketState';
import { PresentNftSkeleton } from './ListLoader';

export const NftExistDrawer: React.FC<{ data: NFTExist; }> = ({ data }) => {
  const drawerManager = useDrawerState();
  const navigate = useNavigate();
  const [nft, setNft] = useState<NFTStoredData | null | undefined>(null);
  const [nftState, setNftState] = useMarketState(); // used on market info page
  const [nftStore, setNftStore] = useNftSotredDataState(); // used on market info page
  const timeOutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!data.hash || nft) return;
    nftDbFile.getMintedByCID(data.hash).then((nft) => {
      setNft(nft);
      setNftState(nft);
      setNftStore(nft);
    });
  }, [data?.hash]);

  useEffect(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      if (!nft) {
        setNft(undefined);
      }
    }, 3000);
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [nft]);

  const columnRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (columnRef.current) {
      setWidth(Math.abs((columnRef.current.offsetWidth / 2) - 8));
    }
  }, [columnRef, nft]);

  console.log({ nft, data, width });

  return (<>
    <div className="flex justify-center " ref={columnRef}>
      <div className="flex flex-col justify-center items-center w-full" >

        <div className="text-[15px] text-2">
          <span className="text-[25px] text-red-500 pr-2">
            Oops!
          </span>
          That Phrase Is Already Taken
        </div>

        {nft ? <PresentNft
          id=""
          nft={nft} width={width}
          onClick={() => {
            drawerManager.closeDrawer();
            navigate(`/markets/${nft.marketId}`);
          }}
          className='w-1/2 mb-6 mt-2 text-left animate-float'
        /> : nft === null ? <>
          <PresentNftSkeleton width={width} className='w-1/2 my-4 opacity-55' />
        </> :
          <>
            <div className="text-[14px] text-0 flex justify-center p-4 my-4">
              {data.existing_sentence}
            </div>
          </>
        }

        <ShowPrompt message={<div className='text-left'>
          The phrase you're trying to mint is too similar to an existing NFT. Please try a different phrase to make your unique NFT.
          <span className='pl-2 text-brand underline'>Read More</span>
          {nft === undefined && <div className='text-[10px] text-2 mt-2'>
            <br />
            This NFT is reserved by the owner. It will be available for you to mint once the owner deletes it, or it will automatically expire in 7 days if not minted.
          </div>}
        </div>} />

        <div className="flex w-full justify-between">
          <div className={` transition-all duration-300 w-full h-[60px]`}>
            <div className="flex gap-2 pt-4  w-full">
              {<>
                <SecondaryButton
                  className="text-f14 h-[4rem] w-full"
                  onClick={() => {
                    drawerManager.closeDrawer();
                  }}
                >
                  Try a New Phrase
                </SecondaryButton>
                <PrimaryButton
                  disable={nft ? false : 'NFT is not minted yet!'}
                  className={`text-f14 h-[4rem] w-full`}
                  onClick={() => {
                    if (nft) {
                      if (nftState) navigate(`/markets/${nftState.marketId}`);
                      drawerManager.openBuyDrwer();
                    }
                  }}
                >
                  Buy This
                </PrimaryButton>
              </>
              }
            </div>
          </div>
        </div>

        <div className="text-[12px]  w-full text-2 mb-2 ">
          <span className="text-[12px] text-brand pr-2">
            Suggestion:
          </span>
          Try adding a unique words.
        </div>
      </div>
    </div>
  </>);
};
