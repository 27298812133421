import { faSackDollar, faSackXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { viewDec } from '@/Helpers/bigintUtils';
import { SecondaryBtn } from '@/components/Buttons';
import MemoTImerIcon from '@/SVG/TImerIcon';
import { useNetwork } from 'wagmi';
import { formatAddress } from '@/Helpers/web3utils';
import { NoDataFound } from '@/components/NoDataFound';
import { showShares } from '@/pages/UserProfilePage/UserCardSm';
// @ts-ignore
import TimeAgo from 'react-timeago';
import { LoadMoreBtn } from '@/components/LoadMore';
import { toJSEpoch } from '@/lib/utils';

export const typeValueMapping: { [any: string]: any; } = {
  buy: 'Bought',
  sell: 'Sold',
};

const MarketActivityList: React.FC<{
  userAddrMap: UserAddrMap;
  data: BuySellActivityForMarket[];
  loadMore?: () => void;  // load more data
}> = ({ userAddrMap, data, loadMore }) => {

  const activity = useMemo(() => data
    .filter((d) => d.type == 'buy' || d.type == 'sell' || d.type == 'RewardsOffered' || d.type == 'BonusAdded'), [data]);

  if (!activity.length)
    return (
      <NoDataFound className="mt-3">No data found</NoDataFound>
    );

  return (
    <div className="flex flex-col gap-[10px]">
      {activity
        .map((activity: BuySellActivityForMarket, i) => {
          if (activity.type == 'buy' && activity.buyer) {
            if (userAddrMap[activity.buyer] == undefined) return null;
            return (
              <UserActivityCard
                key={activity.blockTimestamp}
                user={userAddrMap[activity.buyer]}
                activityData={activity}
              />
            );
          }
          if (activity.type == 'sell' && activity.seller) {
            if (userAddrMap[activity.seller] == undefined) return null;
            return (
              <UserActivityCard
                key={activity.blockTimestamp}
                user={userAddrMap[activity.seller]}
                activityData={activity}
              />
            );
          }
          if (activity.type == 'RewardsOffered') {
            return <RewardsOfferedCard
              key={activity.blockTimestamp}
              data={{ ...activity, type2: 'Rewards Offered' }} />;
          }
          if (activity.type == 'BonusAdded') {
            return <RewardsOfferedCard
              key={activity.blockTimestamp}
              data={{ ...activity, type2: 'Bonus Added' }} />;
          }
        })}
      <LoadMoreBtn loadMore={loadMore} />
    </div>
  );
};

export default MarketActivityList;

const UserActivityCard: React.FC<{
  user: User;
  activityData: BuySellActivityForMarket;
}> = ({ user, activityData }) => {
  const navigate = useNavigate();
  console.log({ user, activityData });
  return (
    <div
      onClick={() => user.id && navigate('/profile/' + user.public_address)}
      className={`flex flex-col ${activityData.highlight ? "animate-fade" : "bg-white"} rounded-[10px] p-4 py-[0.9rem] shadow-sm justify-between w-full h-full items-center cursor-pointer `}
    >
      <div className="flex w-full">
        {/* profile img section */}
        <span className="flex items-center flex-grow">
          <img
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = '/img_placeholder.svg';
              e.currentTarget.classList.remove('img-loading');
            }}
            className="w-[35px] h-[35px] rounded-[5px] mr-[10px] mt-1 img-loading"
            src={user.img_url || '/dplaceholder.png'}
            alt="user profile"
          />

          {/* demographics */}
          <span className="flex flex-col w-full">
            <span className="flex justify-between w-full pt-1 ">
              <span className="font-semibold leading-tight text-f14">
                {user?.first_name && user?.first_name.trim() != "" ? (
                  user.first_name
                ) : (
                  <span
                    // className="text-[grey]"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={user.public_address}
                  >
                    {user?.public_address
                      ? formatAddress(user.public_address)
                      : 'No Name'}
                  </span>
                )}
              </span>

              <span className="mt-1 font-semibold text-2">
                {showShares(activityData.qty)}
              </span>
            </span>
            {/* <PrimeText>{user.last_name}</PrimeText> */}
            <span className="flex justify-between w-full text-2 ">
              {/* <span className="mt-1 font-semibold text-f12">
                  {user?.email ? user.email : "No details"}
                </span> */}
              <div className="flex w-full justify-between mt-1">
                <div className="flex items-center gap-2 text-f10 !whitespace-nowrap ">
                  <MemoTImerIcon />
                  <TimeAgo date={toJSEpoch(activityData?.blockTimestamp)} />
                </div>
                <SecondaryBtn
                  className="p-1 text-[10px] font-semibold rounded-[4px] px-2 "
                  onClick={() => console.log}
                >
                  {typeValueMapping[activityData?.type] || activityData.type}
                </SecondaryBtn>
              </div>

            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

const RewardsOfferedCard: React.FC<{ data: any; }> = ({ data }) => {
  const network = useNetwork();
  return (
    <div className="flex flex-col shadow-sm bg-white rounded-[10px] p-3 justify-between w-full h-full items-center cursor-pointer ">
      <div className="flex w-full">
        {/* profile img section */}
        <span className="flex flex-grow">
          <span className='animate-shine pr-2'>
            <FontAwesomeIcon
              className={`w-[35px] h-[35px] mr-[7px] p-1 ${data.type == "RewardsOffered" ? "text-2" : "text-yellow-600"} cursor-pointer`}
              icon={data.type == "RewardsOffered" ? faSackDollar : faSackXmark}
              onClick={() => { }}
            />
          </span>
          {/* demographics */}
          <span className="flex flex-col w-full mt-0">
            <span className="flex justify-between w-full ">
              <span className="font-semibold text-f14">
                {viewDec(data?.amount)}{' '}
                {network.chain?.nativeCurrency.symbol ?? ''}
              </span>
              {/* <MemoRedirectIcon className='scale-150 text-brand' /> */}
            </span>
            {/* <PrimeText>{user.last_name}</PrimeText> */}
            <span className="flex justify-between w-full text-2 ">
              <div className="flex font-[500] items-center justify-between">
                <div className="flex items-center gap-2 text-f10">
                  <MemoTImerIcon />
                  <TimeAgo date={toJSEpoch(data?.blockTimestamp)} />
                </div>
              </div>
              <SecondaryBtn
                className="p-1 text-[10px] font-semibold rounded-[4px] px-2 "
                onClick={() => console.log}
              >
                {data?.type2.toUpperCase()}
              </SecondaryBtn>
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};
