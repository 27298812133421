import { useReportIssueModal } from '@/atoms/modalState';
import ClickAwayListener from 'react-click-away-listener';
import { twMerge } from 'tailwind-merge';
import { SecondaryBtn } from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faShield } from '@fortawesome/free-solid-svg-icons';
import { useNftSotredDataState } from '@/atoms/marketState';
import Checkbox from './CheckBox';
import { useState } from 'react';
import { axiosInstance } from '@/config/axiosInstance';
import toast from 'react-hot-toast';
import { TextError } from './ui/TextError';

export default function ReportIssueModal() {
  const { isOpen, closeReportModal } = useReportIssueModal();
  const [nft] = useNftSotredDataState();
  const [element, setElement] = useState<string[]>([]); //['text', 'audio', 'img']
  const [reported, setReported] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');

  const handleReport = async () => {
    if (element.length == 0) {
      setError("Please choose any or all of the issues you'd like to address.");
      return;
    }
    if (element.includes('other') && msg.length == 0) {
      setError("You have selected other categorie, so please provide a detailed explanation of the issue.");
      return;
    }
    axiosInstance.post(`/api/nft/report`, {
      marketId: nft.marketId, cid: nft.key, elements: element, msg
    }).then((res) => {
      setReported(true);
    }).catch(err => {
      closeReportModal();
      setReported(false);
      toast.error("Whoops! Something went wrong. Please give it another try.", { id: "report-error" });
    }).finally(() => {
      setMsg('');
      setElement([]);
    });
  };

  const closeModal = () => {
    setReported(false);
    closeReportModal();
  };

  return isOpen ?
    reported
      ? <div className="relative w-full z-[100000]" onClick={() => { }}>
        <div className="absolute h-[100vh] flex felx-col justify-center items-center bg-[#00000066] w-full z-[12]">
          <ClickAwayListener className='' onClickAway={() => {
            closeModal();
          }}>
            <div className={twMerge(` bg-grey1 mx-8 rounded-[10px] animate-fade-up shadow-2xl w-full h-fit justify-center`)}>
              <div className="relative h-full w-full">
                <center className=" text-[14px] px-8 text-2 pb-[8rem] font-bold pt-8">
                  <div className="w-[40px] mb-4 h-[40px] flex items-center justify-center bg-brand rounded-full animate-shine">
                    <FontAwesomeIcon icon={faShield} className='text-white text-[18px]' />
                  </div>
                  Your feedback helps ensure a better experience for everyone. Thank you!
                </center>

                <div className="h-[4rem] absolute w-full felx-1 bottom-8 px-8 flex justify-between ">
                  <SecondaryBtn className={"shadow-sm px-8 bg-4 text-2"}
                    onClick={() => closeModal()}
                  >Skip</SecondaryBtn>
                  <SecondaryBtn className={"shadow-sm px-8"}
                    onClick={() => closeModal()}
                  >Have Feedback</SecondaryBtn>
                </div>

              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      : <div className="relative w-full z-[100000]" onClick={() => { }}>
        <div className="absolute h-[100vh] flex felx-col justify-center items-center bg-[#00000066] w-full z-[12]">
          <ClickAwayListener className='' onClickAway={() => {
            closeModal();
          }}>
            <div className={twMerge(` bg-grey1 mx-8 rounded-[10px] animate-fade-up shadow-2xl w-full h-fit justify-center`)}>
              <div className="relative h-full w-full">
                <center className=" text-[17px] font-bold pt-8">
                  <div className="w-[40px] mb-4 h-[40px] flex items-center justify-center bg-brand rounded-full">
                    <FontAwesomeIcon icon={faFlag} className='text-white' />
                  </div>
                  Help us keep the platform safe!
                </center>
                <center className='pb-[6rem]'>
                  <div className="text-[14px] mt-4 text-0">
                    What's wrong with this NFT?
                  </div>
                  <div
                    onClick={() => setError('')}
                    className=" flex flex-col w-[17rem] items-start text-[16px] px-8 text-2 font-thin mt-4">
                    <Checkbox
                      label='Phrase Used'
                      checked={element.includes('text')}
                      onCheck={() => { setElement([...element, 'text']); }}
                      onUncheck={() => { setElement(p => p.filter(e => e !== 'text')); }}
                    />
                    <Checkbox
                      label='Image Used'
                      checked={element.includes('img')}
                      onCheck={() => { setElement([...element, 'img']); }}
                      onUncheck={() => { setElement(p => p.filter(e => e !== 'img')); }}
                    />
                    <Checkbox
                      label='Audio Used'
                      checked={element.includes('audio')}
                      onCheck={() => { setElement([...element, 'audio']); }}
                      onUncheck={() => { setElement(p => p.filter(e => e !== 'audio')); }}
                    />
                    <Checkbox
                      label='Other'
                      checked={element.includes('other')}
                      onCheck={() => { setElement([...element, 'other']); }}
                      onUncheck={() => { setElement(p => p.filter(e => e !== 'other')); }}
                    />
                  </div>
                  <div className="flex flex-col  mb-4 w-[80%] gap-0">
                    <textarea
                      name="phrase"
                      className={`text-0 p-4 placeholder:text-3 t-outline text-f14 font-[500] rounded-[5px] mt-1 
                    border-[1px] bg-white  `}
                      value={msg}
                      onChange={(e) => { if (e.target.value.length <= 512) setMsg(e.target.value); }}
                      placeholder='Additional details about the NFT?'
                    />
                    <span className='flex w-full justify-end text-2 pt-1'>{msg.length} /512</span>
                  </div>
                  {error && <TextError className='mb-3 mx-10'>{error}
                  </TextError>}
                </center>

                <div className="h-[4rem] absolute w-full felx-1 bottom-8 px-8 flex justify-between ">
                  <SecondaryBtn className={"shadow-sm px-8 bg-4 text-2"}
                    onClick={() => closeModal()}
                  >Close</SecondaryBtn>
                  <SecondaryBtn className={"shadow-sm px-8"}
                    onClick={handleReport}
                  >Report</SecondaryBtn>
                </div>

              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    : null;
}
