import React from 'react';
import { twMerge } from 'tailwind-merge';

export const TextInput: React.FC<{
  value: string;
  setValue: (a: any) => void;
  name: string;
  placeholder: string;
  allowLength: number;
  showLength?: boolean;
  label?: string;
  required?: boolean;
  inputClass?: string;
  labelClass?: string;
  className?: string;
}> = ({ value, setValue, name, placeholder, allowLength, label = "", required = false, showLength = false, inputClass = '', labelClass = '', className = '' }) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      {label && <label htmlFor={name} className={twMerge("text-2 select-none", labelClass)}>{label} {required && <span className=" text-red-500">*</span>}</label>}

      <input
        name={name}
        type="text"
        className={twMerge('text-0 shadow-sm w-full p-4 placeholder:text-3 t-outline text-f14 font-[500] rounded-[5px] mt-1 border-[1px] bg-white ', inputClass)}
        placeholder={placeholder}
        value={value.substring(0, allowLength)}
        onChange={(e) => { if (e.target.value.length <= allowLength) setValue(e.target.value); }}
      />
      {showLength && <span className='flex w-full justify-end text-2 pt-1'>{value.length}/20</span>}
    </div>
  );
};
