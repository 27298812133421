import useSearchMarket from '../../atoms/marketSearch';
import { ListNftCardLoader } from '../../components/ListLoader';
import UserActivityList from './UserActivityList';
import { useOwnedNftState, useUserHoldingState, useWatchlistNftState } from '@/atoms/marketState';
import { useEffect, useMemo, useState } from 'react';
import { mainDbUserActivity, nftDbFile } from '@/firebase/realtimeDb';
import { NftCardsPairInRow } from '../MarketListingPage/components/MarketCards';
import { NoDataFound } from '@/components/NoDataFound';
import { useAccount, useContractWrite, usePublicClient } from 'wagmi';
import { appConfig } from '@/config';
import toast from 'react-hot-toast';
import ShowPrompt from '@/components/ShowPrompt';
import PrimeFadeText from '@/components/PrimeFadeText';
import { DisplayPrice } from '@/components/DisplayPrice';
import { PrimaryBtn } from '@/components/Buttons';
import MemoButtonLoader from '@/components/ButtonLoader';
import { PhraseTradeMainAbi } from '@/ABI/PhraseTradeMain';
import useDrawerState from '@/atoms/drawerState';

export const SearchList: React.FC<{}> = () => {
  const searchManager = useSearchMarket();
  if (searchManager.loading) return <ListNftCardLoader />;
  // return <MarketList markets={searchManager.markets} />;
  if (searchManager.markets?.length == 0) return <NoDataFound>No NFTs found!</NoDataFound>;
  return <NftCardsPairInRow data={searchManager.markets} />;
};

export const Holdings: React.FC<{ user_addr: string; }> = ({ user_addr }) => {
  const [files, setFiles] = useUserHoldingState();
  useEffect(() => {
    if (!files || !files.length) {
      nftDbFile.getHoldingsFirstBatch(user_addr).then(f => {
        setFiles(f);
      });
    }
  }, [user_addr]);
  if (!files) return <ListNftCardLoader />;
  if (files?.length == 0) return <NoDataFound>No NFTs found!</NoDataFound>;
  return <NftCardsPairInRow holders data={files} />;
};

export const Markets: React.FC<{ user_addr: string; }> = ({ user_addr }) => {
  const [files, setFiles] = useOwnedNftState();
  useEffect(() => {
    if (!files || !files.length) {
      nftDbFile.getAllMintedOwnedBy(user_addr).then(f => {
        setFiles(f ?? []);
      });
    }
  }, [user_addr]);
  if (!files) return <ListNftCardLoader />;
  if (files?.length == 0) return <NoDataFound>No NFTs found!</NoDataFound>;
  return <NftCardsPairInRow data={files} />;
};

export const Watchlist: React.FC<{ user_addr: string; }> = ({ user_addr }) => {
  const [files, setFiles] = useWatchlistNftState();
  useEffect(() => {
    if (!files || !files.length) {
      nftDbFile.getWatchlistFirstBatch(user_addr).then(f => {
        setFiles(f);
      });
    }
  }, [user_addr]);
  if (!files) return <ListNftCardLoader />;
  if (files?.length == 0) return <NoDataFound>No NFTs found!</NoDataFound>;
  return <NftCardsPairInRow data={files} />;
};

export const UserActivityTab: React.FC<{ user_addr: string; }> = ({ user_addr }) => {
  const PAGE_LENGTH = 20;
  const [activity, setActivity] = useState<any[]>([]);
  const [length, setLength] = useState(PAGE_LENGTH);
  const loadMore = useMemo(() => {
    if (Object.keys(activity).length % PAGE_LENGTH == 0) {
      return () => setLength(length + PAGE_LENGTH);
    }
    return null;
  }, [activity]);

  useEffect(() => {
    if (user_addr && !activity.length) {
      mainDbUserActivity.getAllActivity(user_addr, length).then((data) => {
        setActivity(data as any ?? []);
      });
    }
  }, [user_addr, length]);

  return (
    <div className='w-full h-full overflow-y-auto'>
      <UserActivityList loadMore={loadMore} data={activity ?? []} />
    </div>
  );
};

export const ClaimUserRewards: React.FC<{ user_addr: string; }> = ({ user_addr }) => {
  const account = useAccount();
  const drawerManager = useDrawerState();
  const { ownerFeesCollected, creatorFeesCollected } = drawerManager.drawerState?.screen == 'claim-fees'
    ? drawerManager.drawerState.options
    : { ownerFeesCollected: null, creatorFeesCollected: null };

  const [loading, setLoading] = useState({
    rewards: false,
    reflection: false,
    ownerFees: false,
    creatorFees: false,
  });
  const [claimed, setClaimed] = useState({
    rewards: false,
    reflection: false,
    ownerFees: false,
    creatorFees: false,
  });

  const { waitForTransactionReceipt } = usePublicClient();

  const { writeAsync: claimOwnerFeesAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'claimOwnerFees',
  });

  const { writeAsync: claimCreatorFeesAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'claimCreatorFees',
  });

  const claimOwnerFees = useMemo(() => async () => {
    if (!account?.address) return;
    setLoading(p => ({ ...p, ownerFees: true }));
    const argPack = {
      args: [account?.address] as [Address],
    };
    const { hash } = await claimOwnerFeesAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    setLoading(p => ({ ...p, ownerFees: false }));
    setClaimed(p => ({ ...p, ownerFees: false }));
    toast.success('Owner fees claimed');
  }, [account]);

  const claimCreatorFees = useMemo(() => async () => {
    if (!account?.address) return;
    setLoading(p => ({ ...p, creatorFees: true }));
    const argPack = {
      args: [account?.address] as [Address],
    };
    const { hash } = await claimCreatorFeesAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    setLoading(p => ({ ...p, creatorFees: false }));
    setClaimed(p => ({ ...p, creatorFees: true }));
    toast.success('Creator fees claimed');
  }, [account]);

  return (
    <div className="h-full text-left py-4 ">
      <div className="flex flex-col gap-[10px]  pr-6 pl-6">

        <ShowPrompt step="" message={
          (<>It's recommended to claim an amount of at least <span className="text-brand">$1</span></>) as any
        } className="mb-2" />

        {/* Claim OwnerFees Card */}
        {<div className="flex flex-col bg-white p-4 rounded-[10px] shadow-sm ">
          <span className="font-semibold text-f14">Collected Owner's Fees</span>
          <span className="flex justify-between">
            <PrimeFadeText classname=" text-[12px] text-2 ">
              <DisplayPrice
                active={!!ownerFeesCollected}
                price={ownerFeesCollected as any ?? BigInt(0)}
              />
            </PrimeFadeText>
            <PrimaryBtn
              className={`p-1 text-[white] text-[12px] items-center mr-2 w-[50px] h-fit min-w-fit font-semibold rounded-[4px] 
                ${(!!ownerFeesCollected && !claimed.ownerFees) ? (loading.ownerFees ? '' : 'animate-shine') : 'bg-3 cursor-not-allowed'
                }`}
              onClick={() => claimOwnerFees()}
            >
              <MemoButtonLoader
                className="mx-auto h-[2.4rem] mt-[-0.64rem]"
                loading={loading.ownerFees}
              />{' '}
              {loading.ownerFees ? '' : 'Claim'}
            </PrimaryBtn>
          </span>
        </div>}

        {/* Claim Creator fees Card */}
        {<div className="flex flex-col bg-white p-4 rounded-[10px] shadow-sm ">
          <span className="font-semibold text-f14">Collected Creator's Fees</span>
          <span className="flex justify-between">
            <PrimeFadeText classname=" text-[12px] text-2 ">
              <DisplayPrice
                active={!!creatorFeesCollected}
                price={creatorFeesCollected as any ?? BigInt(0)}
              />
            </PrimeFadeText>
            <PrimaryBtn
              className={`p-1 text-[white] text-[12px] mr-2 w-[50px] h-fit min-w-fit font-semibold rounded-[4px] 
                ${(!!creatorFeesCollected && !claimed.creatorFees) ? (loading.creatorFees ? '' : 'animate-shine') : 'bg-3 cursor-not-allowed'
                }`}
              onClick={() => claimCreatorFees()}
            >
              <MemoButtonLoader
                className="mx-auto h-[2.4rem] mt-[-0.64rem]"
                loading={loading.creatorFees}
              />{' '}
              {loading.creatorFees ? '' : 'Claim'}
            </PrimaryBtn>
          </span>
        </div>}

      </div>
    </div>
  );
};
