import { UserCardSm } from '../pages/UserProfilePage/UserCardSm';
import { NoDataFound } from './NoDataFound';

const UserCardList: React.FC<{ users: Holders[]; }> = ({ users }) => {
  return (
    <div className="flex flex-col gap-[10px] ">
      {users?.length ? (
        users.map((user) => <UserCardSm key={user.public_address + user.shares} user={user} />)
      ) : (
        <NoDataFound>No data found</NoDataFound>
      )}
    </div>
  );
};

export { UserCardList };
