import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();


// Minimal User State
const isAudioOnState = atom<boolean>({
  default: true,
  key: 'isAudioOn',
  effects: [persistAtom],
});

export const useAudioON = () => {
  const setState = useSetRecoilState(isAudioOnState);
  const getState = useRecoilValue(isAudioOnState);
  return [getState, setState];
};