
export const addSlippageBigint = (amount: bigint, slippage: number) => {
  slippage = slippage / 100;
  slippage = +slippage.toFixed(4);
  const num = BigInt(slippage * 1e4);
  return amount + (num * amount) / 10000n;
};


export const subtractSlippageBigint = (amount: bigint, slippage: number) => {
  slippage = slippage / 100;
  slippage = +slippage.toFixed(4);
  const num = BigInt(slippage * 1e4);
  return amount - (num * amount) / 10000n;
};


export const EXPO = BigInt(1e18);

export function getPrice(supply: bigint, qty: bigint) {
  // console.log('Supply: ', supply, 'Qty: ', qty);
  const sum1 =
    supply === BigInt(0)
      ? BigInt(0)
      : ((supply - EXPO) * supply * (BigInt(2) * (supply - EXPO) + EXPO)) /
      (BigInt(6) * EXPO);
  const sum2 =
    supply === BigInt(0) && qty === EXPO
      ? BigInt(0)
      : ((supply - EXPO + qty) *
        (supply + qty) *
        (BigInt(2) * (supply - EXPO + qty) + EXPO)) /
      (BigInt(6) * EXPO);
  const summation = (sum2 - sum1) / EXPO;
  return summation / BigInt(1600);
}
