import { useEffect, useRef, useState } from 'react';
import MemoSearchIcon from '../SVG/SearchIcon';
import useSearchMarket from '../atoms/marketSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const MarketSearchBar: React.FC<any> = ({ }) => {
  const [show, setShow] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchManager = useSearchMarket();
  const [placeholders,] = useState(["Search by Title OR Phrase", "Search by Author Name", "Search by NFT Content"]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeInter = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % placeholders.length);
    }, 3100); // Change placeholder every 3 seconds
    return () => clearInterval(timeInter); // Cleanup on component unmount
  }, [placeholders]);

  return (
    <div
      className={`bg-white px-[10px] shadow-md  transition-all w-full h-[50px] rounded-[10px] flex items-center relative overflow-hidden justify-between pr-6  height-pop-up }`}
    >
      <div className=" z-[100]">
        <MemoSearchIcon className="z-10 ml-3" />
      </div>

      <input
        ref={inputRef}
        onFocus={() => setShow(false)}
        onBlur={() => { if (!searchManager.keyword) setShow(true); }}
        value={searchManager.keyword}
        // placeholder="Search by Author or Phrase"
        onChange={(e) => searchManager.onSearch(e.target.value)}
        className="bg-white z-[10] placeholder:font-[500] placeholder:animate-move-up placeholder:text-3 text-0 text-f14 font-[500] rounded-[10px] absolute w-full h-full px-[50px] top-0 left-0 outline-brand2"
      />
      {show && !searchManager.keyword && < SearchPlaceHolder placeholder={placeholders[currentIndex]} key={currentIndex} />}

      {searchManager.keyword ? (
        <div
          className=" text-f14 duration-200 text-brand hover:scale-125 hover:mb-[-1px] z-[100] cursor-pointer"
          onClick={() => {
            searchManager.cancelSearch();
            setShow(true);
          }}
        >
          <FontAwesomeIcon icon={faXmark} className='text-[1.5rem] mt-1' />
        </div>
      ) : null}
    </div>
  );
};

export { MarketSearchBar };

const SearchPlaceHolder: React.FC<{ placeholder: string; }> = ({ placeholder }) => {
  return (
    <>
      <div
        className={` z-[10000] absolute left-4 w-[20rem] text-3 text-f14 top-[16px] ml-[4.1rem] 
          pointer-events-none animate-move-up`}>
        {placeholder}
      </div>;
    </>
  );
};;