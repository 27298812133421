import { useEffect, useState } from 'react';

import { MarketSearchBar } from '@/components/MarketSearchBar';
import { SearchTab, Tablist } from '@/components/Tablist';
import useSearchMarket from '@/atoms/marketSearch';
import { useAccount } from 'wagmi';
import { SearchList } from '../UserProfilePage/userProfileTabs';
import { Mine, New, Rank, Top } from './components/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { ListNftCardLoader } from '@/components/ListLoader';

const MarketListingPage: React.FC<any> = ({ }) => {

  const [activeTab, setActiveTab] = useState('Top');
  const [mountedTabs, setMountedTabs] = useState([false, true, false]);
  const searchManager = useSearchMarket();
  const [tabs, settabs] = useState(['New', 'Top', 'Rank']);
  const account = useAccount();

  // if user is logged in, add 'Mine' tab
  useEffect(() => {
    if (account.address) {
      settabs((t) => {
        t.push('Mine');
        return [...new Set(t)];
      });
    }
  }, [account.address]);

  return (
    <div className=" h-full bg-brandGrey min-h-full pb-10 flex flex-col gap-[10px] overflow-hidden">

      <div className="sticky z-[10] px-[15px]  top-[-1px] flex flex-col w-full pt-4 pb-2 bg-brandGrey gap-y-3">
        <MarketSearchBar />
        <div className="flex">
          {searchManager.keyword ? (
            <SearchTab
              keyword={searchManager.keyword}
              onClose={searchManager.cancelSearch}
            />
          ) : (
            <Tablist
              tablist={tabs}
              activeTab={searchManager.keyword ? '-1' : activeTab}
              onTabSelect={(tabName: string) => {
                const index = tabs.indexOf(tabName);
                setActiveTab(tabName);
                if (!mountedTabs[index]) {
                  setMountedTabs((t) => {
                    t[index] = true;
                    return [...t];
                  });
                }
              }}
            />
          )}
        </div>
      </div>

      <div className='flex-1 relative px-[15px] z-[1] overflow-hidden'>
        {searchManager.keyword
          ? <SearchList />
          : <>
            <SwipeableViews
              index={tabs.indexOf(activeTab)}
              className='h-full'
              onChangeIndex={(index) => {
                setActiveTab(tabs[index % tabs.length]);
                if (!mountedTabs[index]) {
                  setMountedTabs((t) => {
                    t[index] = true;
                    return [...t];
                  });
                }
              }}
            >
              {activeTab == "New" || mountedTabs[0] ?
                <div className="h-[calc(100vh-190px)] overflow-hidden">
                  <New />
                </div>
                : <ListNftCardLoader />}
              {activeTab == "Top" || mountedTabs[1] ?
                <div className="h-[calc(100vh-190px)] overflow-hidden">
                  <Top />
                </div>
                : <ListNftCardLoader />}
              {activeTab == "Rank" || mountedTabs[2] ?
                <div className="h-[calc(100vh-190px)] overflow-hidden">
                  <Rank />
                </div>
                : <ListNftCardLoader />}
              {activeTab == "Mine" || mountedTabs[3] ?
                <div className="h-[calc(100vh-190px)] overflow-hidden">
                  <Mine address={account.address} />
                </div>
                : <ListNftCardLoader />}

            </SwipeableViews>
          </>
        }

      </div>
    </div>
  );
};

export default MarketListingPage;
