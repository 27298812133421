import {
  Outlet,
  useParams,
} from 'react-router-dom';
import { Layout } from '../../components/Layout';
import MarketListingPage from '../MarketListingPage';

const Onboarding: React.FC<any> = ({ }) => {
  const params = useParams();
  if (params.marketid) {
    return <Outlet />;
  }
  return (
    <Layout>
      <div className="w-full h-full bg-2b overflow-hidden">
        <MarketListingPage />
      </div>
    </Layout>
  );
};

export { Onboarding };
