import * as React from 'react';

function ExportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.85628 1C4.68355 1 4.52689 1.10446 4.45994 1.26518C4.39299 1.42589 4.43048 1.60937 4.55233 1.73259L5.10667 2.28571L3.26824 4.12589C3.10087 4.2933 3.10087 4.56518 3.26824 4.73259C3.43562 4.9 3.70743 4.9 3.8748 4.73259L5.71323 2.89241L6.26757 3.44687C6.39076 3.57009 6.5742 3.60625 6.73488 3.53929C6.89556 3.47232 7 3.31696 7 3.14286V1.42857C7 1.19152 6.80852 1 6.57152 1H4.85628ZM2.07119 1.42857C1.47936 1.42857 1 1.90804 1 2.5V5.92857C1 6.52054 1.47936 7 2.07119 7H5.499C6.09083 7 6.57019 6.52054 6.57019 5.92857V4.85714C6.57019 4.62009 6.37871 4.42857 6.14171 4.42857C5.90471 4.42857 5.71323 4.62009 5.71323 4.85714V5.92857C5.71323 6.04643 5.61683 6.14286 5.499 6.14286H2.07119C1.95336 6.14286 1.85695 6.04643 1.85695 5.92857V2.5C1.85695 2.38214 1.95336 2.28571 2.07119 2.28571H3.14238C3.37938 2.28571 3.57085 2.0942 3.57085 1.85714C3.57085 1.62009 3.37938 1.42857 3.14238 1.42857H2.07119Z" fill="inherit" stroke="#8F97AD" strokeWidth="0.2" />
    </svg>
  );
}

export default React.memo(ExportIcon);