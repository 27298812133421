import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import useSWR from 'swr';
import { absoluteBigInt } from '../Helpers/bigintUtils';
import { useEffect } from 'react';

const ETHPriceAtom = atom<bigint | undefined>({
  key: 'EHTPrice',
  default: undefined,
});

export const useEthPrice = () => {
  const price = useRecoilValue(ETHPriceAtom);
  return price;
};

export const setEthPrice = (price: bigint | undefined) => {
  const set = useSetRecoilState(ETHPriceAtom);
  set(price);
};


// Fetches the latest ETH price from the Pyth network
export const useEthPriceRefresher = (interval = 10_000) => {
  const set = useSetRecoilState(ETHPriceAtom);
  const { data, error } = useSWR<bigint>('ETH-Price', {
    fetcher: async () => {
      const results = await axios.get<PythPrices>(
        // `https://xc-mainnet.pyth.network/api/latest_price_feeds?ids[]=${ETHUSD}`,
        `https://hermes.pyth.network/api/latest_price_feeds?ids[]=${import.meta.env.VITE_PYTH_ETHUSD_ID ?? ''}`,
      );
      const price = BigInt(results.data[0].price.price);
      if (results.data[0].price.expo == -8) {
        return price;
      } else {
        return absoluteBigInt(price, -results.data[0].price.expo + 8);
      }
    },
    refreshInterval: interval,
  });
  useEffect(() => {
    set(data);
  }, [data]);
};