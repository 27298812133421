import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const PageLengthAtom = atom<{
  topPageLength: number;
  myPageLength: number;
  newPageLength: number;
}>({
  default: {
    topPageLength: 0,
    myPageLength: 0,
    newPageLength: 0
  },
  key: 'PageLength',
});

export const usePageLength = () => {
  const setPageLength = useSetRecoilState(PageLengthAtom);
  const pageLength = useRecoilValue(PageLengthAtom);
  return {
    topPageLength: [pageLength.topPageLength, (v: number) => {
      setPageLength((prev) => ({ ...prev, topPageLength: v }));
    }] as [number, (v: number) => void],
    myPageLength: [pageLength.myPageLength, (v: number) => {
      setPageLength((prev) => ({ ...prev, myPageLength: v }));
    }] as [number, (v: number) => void],
    newPageLength: [pageLength.newPageLength, (v: number) => {
      setPageLength((prev) => ({ ...prev, newPageLength: v }));
    }] as [number, (v: number) => void],
  };
};

const MarketStateAtom = atom<NFTStoredData | null>({
  default: null,
  key: 'MarketState',
});

export const useMarketState = () => {
  const setMarketState = useSetRecoilState(MarketStateAtom);

  const marketState = useRecoilValue(MarketStateAtom);
  return [marketState, setMarketState] as [NFTStoredData, typeof setMarketState];
};


// Top Market List State
const MarketListStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'MarketListState',
});

export const useMarketListState = () => {
  const setMarketListState = useSetRecoilState(MarketListStateAtom);

  const marketListState = useRecoilValue(MarketListStateAtom);
  return [marketListState, setMarketListState] as [NFTRecords, typeof setMarketListState];
};


// My Market List State
const MyMarketListStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'MarketMyListState',
});

export const useMyMarketListState = () => {
  const setMyMarketListState = useSetRecoilState(MyMarketListStateAtom);

  const myMarketListState = useRecoilValue(MyMarketListStateAtom);
  return [myMarketListState, setMyMarketListState] as [NFTRecords, typeof setMyMarketListState];
};


// My Market List State
const NewMarketListStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'MarketNewListState',
});

export const useNewMarketListState = () => {
  const setNewMarketListState = useSetRecoilState(NewMarketListStateAtom);

  const newMarketListState = useRecoilValue(NewMarketListStateAtom);
  return [newMarketListState, setNewMarketListState] as [NFTRecords, typeof setNewMarketListState];
};


// Market Minted State 
export const marketMintedStateAtom = atom<boolean>({
  default: false,
  key: 'marketMintedState',
});

export const useMarketMintedState = () => {
  const setMarketMintedState = useSetRecoilState(marketMintedStateAtom);
  const marketMintedState = useRecoilValue(marketMintedStateAtom);
  return [marketMintedState, setMarketMintedState] as [boolean, typeof setMarketMintedState];
};


// NFT Stored Data State
export const nftSotredDataStateAtom = atom<NFTStoredData | null>({
  default: null,
  key: 'nftSotredDataState',
});

export const useNftSotredDataState = () => {
  const setNftSotredDataState = useSetRecoilState(nftSotredDataStateAtom);
  const nftSotredDataState = useRecoilValue(nftSotredDataStateAtom);
  return [nftSotredDataState, setNftSotredDataState] as [NFTStoredData, typeof setNftSotredDataState];
};


// User Holding State
export const userHoldingStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'userHoldingState',
});

export const useUserHoldingState = () => {
  const setUserHoldingState = useSetRecoilState(userHoldingStateAtom);
  const userHoldingState = useRecoilValue(userHoldingStateAtom);
  return [userHoldingState, setUserHoldingState] as [NFTRecords, typeof setUserHoldingState];
};


// Owned NFT State
export const ownedNftStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'ownedNftState',
});

export const useOwnedNftState = () => {
  const setOwnedNftState = useSetRecoilState(ownedNftStateAtom);
  const ownedNftState = useRecoilValue(ownedNftStateAtom);
  return [ownedNftState, setOwnedNftState] as [NFTRecords, typeof setOwnedNftState];
};


// watchlist nft state
export const watchlistNftStateAtom = atom<NFTRecords | null>({
  default: null,
  key: 'watchlistNftState',
});

export const useWatchlistNftState = () => {
  const setWatchlistNftState = useSetRecoilState(watchlistNftStateAtom);
  const watchlistNftState = useRecoilValue(watchlistNftStateAtom);
  return [watchlistNftState, setWatchlistNftState] as [NFTRecords, typeof setWatchlistNftState];
};

// Holders NFT State
export const holdersNftStateAtom = atom<Holders[] | null>({
  default: null,
  key: 'holdersNftState',
});

export const useHoldersNftState = () => {
  const setHoldersNftState = useSetRecoilState(holdersNftStateAtom);
  const holdersNftState = useRecoilValue(holdersNftStateAtom);
  return [holdersNftState, setHoldersNftState] as [Holders[], typeof setHoldersNftState];
};

// WatchedBy NFT State
export const watchedByNftStateAtom = atom<MinimalUser[] | null>({
  default: null,
  key: 'watchedByNftState',
});

export const useWatchedByNftState = () => {
  const setWatchedByNftState = useSetRecoilState(watchedByNftStateAtom);
  const watchedByNftState = useRecoilValue(watchedByNftStateAtom);
  return [watchedByNftState, setWatchedByNftState] as [MinimalUser[], typeof setWatchedByNftState];
};

// Market Activity Data State
export const marketActivityDataStateAtom = atom<any[] | null>({
  default: null,
  key: 'marketActivityDataState',
});

export const useMarketActivityDataState = () => {
  const setMarketActivityDataState = useSetRecoilState(marketActivityDataStateAtom);
  const marketActivityDataState = useRecoilValue(marketActivityDataStateAtom);
  return [marketActivityDataState, setMarketActivityDataState] as [any[], typeof setMarketActivityDataState];
};


// User Address Map State
export const userAddressMapStateAtom = atom<UserAddrMap | null>({
  default: {},
  key: 'userAddress'
});

export const useUserAddressMapState = () => {
  const setUserAddressMapState = useSetRecoilState(userAddressMapStateAtom);
  const userAddressMapState = useRecoilValue(userAddressMapStateAtom);
  return [userAddressMapState, setUserAddressMapState] as [UserAddrMap, typeof setUserAddressMapState];
};
