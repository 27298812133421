import { ReactNode, useEffect, useMemo, useState } from 'react';
import { PrimaryBtn } from './Buttons';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePublicClient,
} from 'wagmi';
import { appConfig } from '../config';
import toast from 'react-hot-toast';
import MemoButtonLoader from './ButtonLoader';
import { bigIntMax, bigIntMin, view } from '../Helpers/bigintUtils';
import { Popover } from 'react-tiny-popover';
import { formatError } from '../Helpers/web3utils';
import { twJoin, twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayPrice } from './DisplayPrice';
import { E18, E8 } from '../Helpers/constants';
import MemoSettings from '../SVG/Settings';
import { SlippageSetting } from './SlippageSetting';
import { getSanitizedInput } from '@/utils/getSanitizeInput';
import { showShares } from '@/pages/UserProfilePage/UserCardSm';
import { useSlippage } from '@/atoms/SlipageState';
import { Skeleton } from './ui/skeleton';
import { faBookmark, faEye, faPaperPlane, faWarning } from '@fortawesome/free-solid-svg-icons';
import { dateToLocalFormat } from '@/Helpers/date';
import { useNftSotredDataState } from '@/atoms/marketState';
import { addSlippageBigint, subtractSlippageBigint } from '@/Helpers/protocol';
import { PhraseTradeStorageAbi } from '@/ABI/PhraseTradeStorage';
import { PhraseTradeMainAbi } from '@/ABI/PhraseTradeMain';
import { loadNftMinData } from '@/Helpers/register';


export const BuyDrawer: React.FC = () => {
  const { waitForTransactionReceipt } = usePublicClient();
  const [loading, setLoading] = useState(false);
  const account = useAccount();
  const slipage = useSlippage();

  const { address } = useAccount();
  const [value, setValue] = useState<number>(2);
  const [nft,] = useNftSotredDataState();

  const marketIdValueArgPack = useMemo<[bigint, bigint]>(
    () => [BigInt(nft.marketId), BigInt(value * 1e18)],
    [value]
  );

  const marketIdHolderArgPack = useMemo<[bigint, `0x${string}`]>(
    () => [BigInt(nft.marketId), address as Address],
    [nft, address]
  );

  const { data: supply } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'sharesSupply',
    args: [BigInt(nft.marketId)],
    account: address,
    watch: true,
  });

  const { data: userSharesBalance } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'sharesBalance',
    args: marketIdHolderArgPack,
    account: address,
    watch: true,
  });

  const { data: nextBuyPrice } = useContractRead({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'getBuyPriceAfterFee',
    args: marketIdValueArgPack,
    account: address,
    watch: true,
  });


  const maxSell = useMemo(() => {
    return typeof supply == 'bigint' && typeof userSharesBalance == 'bigint'
      ? bigIntMax(bigIntMin(userSharesBalance, supply - E18), 0n)
      : undefined;
  }, [supply, userSharesBalance]);

  const data = useMemo(() => ({
    supply: supply as bigint,
    userBalance: userSharesBalance as bigint,
    // dividends: dividends as bigint,
    nextBuyPrice: nextBuyPrice as bigint,
    // nextSellPrice: nextSellPrice as bigint,
    maxSell: maxSell as bigint,
  }), [supply, userSharesBalance, nextBuyPrice, maxSell]);

  const { writeAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'buyShares',
    // gasPrice: 40_000n,
  });

  const userBalance = useBalance({
    address: account.address,
    watch: true,
  });

  const [trade, setTrade] = useState({
    shares: +value,
    price: -1n,
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    setErrors({});
  }, [data?.userBalance]);

  // @ts-ignore
  const error = errors?.['Quantity'];

  const handelTrade = async () => {
    if (!data || !data.nextBuyPrice) {
      throw new Error('Pre-fetching failed!');
    }
    if (error) throw new Error(error);
    const argPack = {
      args: [nft.marketId, BigInt(trade.shares * 1e18)],
      value: trade.price,
    };

    // @ts-ignore
    const { hash } = await writeAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    loadNftMinData(nft);
    toast.success('Shares transferred to your Account');
  };


  const [isPopoverOpen, setIsPopoverOpen] = useState(false);


  useEffect(() => {
    if (!data.nextBuyPrice) return;

    const increasedPrice = addSlippageBigint(data.nextBuyPrice, slipage);
    setTrade((s) => {
      return { ...s, price: increasedPrice };
    });

    if (!userBalance.isLoading) {
      // @ts-ignore
      if (increasedPrice > userBalance?.data?.value) {
        setErrors((e) => ({ Quantity: 'Shares cost exceeds balance!' }));
      }
    }
  }, [data.nextBuyPrice, userBalance.data?.value, slipage]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (!e.target.value) return setValue('');
    const value = getSanitizedInput(e.target.value);
    if (!value) return;
    const ip = e.target;
    // const value = parseFloat(e.target.value).toFixed(2);
    setValue(+value);

    if (ip.validationMessage)
      return setErrors((e) => ({ ...e, [ip.name]: ip.validationMessage }));
    else setErrors({});

    setTrade({ shares: +value, price: -1n });
  };

  return (
    <>
      <NftDetailsCard data={nft} />
      <div className="flex items-end justify-between">
        <IpLabel htmlFor="Shares ip">Number of shares</IpLabel>
        <Popover
          onClickOutside={() => setIsPopoverOpen(false)}
          isOpen={isPopoverOpen}
          positions={['left']} // preferred positions by priority
          containerStyle={{ zIndex: '1000', marginRight: '5px' }}
          content={<SlippageSetting />}
          padding={10}
        >
          <button
            className="text-2 mb-1"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <MemoSettings />
          </button>
        </Popover>
      </div>
      <div className=" flex flex-col rounded-[5px] gap-2 ">
        <input
          id="Shares ip"
          name="Quantity"
          placeholder="Enter quantity of shares to buy"
          value={value}
          type="number"
          max={'200'}
          min={'0.001'}
          step={'0.001'}
          // pattern="[0-9]"
          title="Numbers only"
          onChange={handleChange}
          className={
            'p-4 border-[1px] bg-1b rounded-md py-3 h-16  pr-12 font-bold text-f14 text-1 outline-none focus:border-brand2 ' +
            (error ? ' border-yellow-600' : ' ')
          }
        />
        <div className="text-2 flex w-full justify-end">Balance: {showShares(data?.userBalance || "0")}</div>
      </div>

      <div className="flex flex-col justify-between ">
        {error ? (
          <div className="text-yellow-600 flex w-full justify-end text-f10">
            <span><FontAwesomeIcon icon={faWarning} className='text-f12 pr-2' /></span>
            {error}
          </div>
        ) : trade.price && trade.price !== -1n ? (
          <DisplayPrice active price={trade.price} className="text-2 animate-pulse" />
        ) : (
          <Skeleton className="block w-[100px] h-6 rounded-md opacity-30" />
        )}
        {/* <div className="text-2">Makret Supply: {showShares(data.supply)}</div> */}
      </div>

      <PrimaryBtn
        onClick={() => {
          setLoading(true);
          handelTrade()
            .catch((e) => {
              const msg = formatError(e);
              toast(msg, { icon: '❌' });
            })
            .finally(() => setLoading(false));
        }}
        className="flex items-center mt-2 justify-center gap-5 h-[40px] text-white animate-shine"
      >
        <MemoButtonLoader className="scale-110 " loading={loading} /> Buy
      </PrimaryBtn>
    </>
  );
};


export const IpLabel: React.FC<{
  children: ReactNode;
  className?: string;
  htmlFor: string;
}> = ({ children, htmlFor, className }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={twJoin('text-2 text-f12 font-[500]  mt-3', className)}
    >
      {children}
    </label>
  );
};


const NftDetailsCard: React.FC<{
  data: NFTStoredData;
  className?: string;
  idx?: number;
  toggleView?: () => void;
}> = ({ data, idx, className }) => {

  if (!data) return null;

  return null;
  return (
    <div
      className={twMerge(
        `p-[10px] pb-2 rounded-[10px] justify-between flex gap-[15px] w-full bg-white `,
        className
      )}
    >
      <div className="flex flex-col gap-[3px] items-center  justify-start ">
        <img
          src={data?.thumbnail ?? ""}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = '/img/NoNft.png';
            e.currentTarget.classList.remove('img-loading');
          }}
          loading="lazy"
          className="max-w-[120px] mb-2 min-w-[120px] min-h-[120px] max-h-[120px] rounded-[5px] img-loading object-cover"
        />
      </div>

      <div className="flex flex-col items-start w-full select-none">
        <div className="flex justify-between w-full select-none">
          <span className="font-bold text-f14">{data?.title ?? ""}</span>
        </div>

        <div className="flex w-full select-none">
          <span className="font-semibold text-f12 text-justify text-2">{data?.phrase ?? ""}</span>
        </div>

        <br />

        <div className="flex flex-col justify-start gap-0 text-f12 w-full select-none">
          <span className='text-1 flex'>By
            <span className=" text-f12 italic text-2 pl-2">{data?.author ?? ""}</span>
          </span>
          <span className='text-1 flex'>Created
            <span className=" text-f12 italic text-2 pl-2">{dateToLocalFormat(
              // @ts-ignore
              Date(data?.mintedOn ?? null
              )) ?? ""}</span>
          </span>
        </div>

        <div className="flex pt-2">
          <span className='flex pr-4'>
            <span className='text-1 text-f12 flex pr-2'>4</span>
            <FontAwesomeIcon icon={faPaperPlane} className="text-2 text-[14px] pt-[0.2rem]" />
          </span>
          <span className='flex pr-4'>
            <span className='text-1 text-f12 flex pr-2'>120</span>
            <FontAwesomeIcon icon={faEye} className="text-2 text-[14px] pt-[0.2rem]" />
          </span>
          <span className='flex pr-4'>
            <span className='text-1 text-f12 flex pr-2'>8</span>
            <FontAwesomeIcon icon={faBookmark} className="text-2 text-[14px] pt-[0.2rem]" />
          </span>
        </div>

      </div>

    </div>
  );
};


export const SellDrawer: React.FC = () => {
  const { waitForTransactionReceipt } = usePublicClient();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { address } = useAccount();
  const [value, setValue] = useState<string>("2");
  const [nft,] = useNftSotredDataState();
  const [trade, setTrade] = useState({
    shares: +value,
    price: -1n,
  });


  const marketIdValueArgPack = useMemo<[bigint, bigint]>(
    () => [BigInt(nft.marketId), BigInt(trade.shares * 1e18)],
    [nft, trade.shares]
  );

  const marketIdHolderArgPack = useMemo<[bigint, `0x${string}`]>(
    () => [BigInt(nft.marketId), address as Address],
    [nft, address]
  );

  const { data: supply } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'sharesSupply',
    args: [BigInt(nft.marketId)],
    account: address,
  });
  const { data: userSharesBalance } = useContractRead({
    address: appConfig.storageAddress,
    abi: PhraseTradeStorageAbi,
    functionName: 'sharesBalance',
    args: marketIdHolderArgPack,
    account: address,
    watch: true,
  });
  const { data: nextSellPrice } = useContractRead({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'getSellPriceAfterFee',
    args: marketIdValueArgPack,
    account: address,
    watch: true,
  });
  const { data: rawSellPrice } = useContractRead({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'getSellPrice',
    args: marketIdValueArgPack,
    account: address,
    watch: true,
  });

  const maxSell = useMemo(() => {
    return typeof supply == 'bigint' && typeof userSharesBalance == 'bigint'
      ? bigIntMax(bigIntMin(userSharesBalance, supply - E18), 0n)
      : undefined;
  }, [supply, userSharesBalance]);

  const data = useMemo(() => ({
    supply: supply as bigint,
    userBalance: userSharesBalance as bigint,
    nextSellPrice: nextSellPrice as bigint,
    maxSell: maxSell as bigint,
    rawSellPrice: rawSellPrice as bigint,
  }), [supply, userSharesBalance, nextSellPrice, rawSellPrice]);

  const { writeAsync } = useContractWrite({
    address: appConfig.mainAddress,
    abi: PhraseTradeMainAbi,
    functionName: 'sellShares',
    // gasPrice: 40_000n,
  });
  const slipage = useSlippage();

  useEffect(() => {
    setErrors({});
  }, [data?.userBalance]);

  const handelTrade = async () => {
    if (!data.nextSellPrice) {
      throw new Error('Pre-fetching failed!');
    }
    if (error) throw new Error(error);

    const argPack = {
      args: [
        nft.marketId,
        BigInt(trade.shares * 1e18),
        trade.price,
      ],
    };
    // @ts-ignore
    const { hash } = await writeAsync(argPack);
    const { status: completionStatus } = await waitForTransactionReceipt({
      hash,
    });
    loadNftMinData(nft);
    toast.success('Funds transfered to Account');
  };

  useEffect(() => {
    if (data?.maxSell) {
      try {
        const val = Number(data.maxSell / E18);
        console.log({ maxSell: data.maxSell, val });
        setValue(val.toString());
      } catch (error) {
        setValue('0');
        console.log({ error });
      }
    }
  }, [data?.maxSell]);

  useEffect(() => {
    setTrade({ shares: +value, price: -1n });
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // @ts-ignore
      if (!e.target.value) return setValue('');
      const value = getSanitizedInput(e.target.value, 5);
      if (!value) return;
      const ip = e.target;
      setValue(value);
      if (ip.validationMessage) return setErrors((e) => ({ ...e, [ip.name]: ip.validationMessage }));
      else setErrors({});
    } catch (error) {
      console.log({ error });
    }
  };

  // @ts-ignore
  const error = errors?.['SellQuantity'];

  useEffect(() => {
    if (!data.rawSellPrice) return;
    setTrade((s) => {
      const decreasedPrice = subtractSlippageBigint(
        data.rawSellPrice, // sell function only takes price without dividends
        slipage
      );
      return {
        ...s,
        price: decreasedPrice,
      };
    });
  }, [data.rawSellPrice, slipage]);

  return (
    <>
      <NftDetailsCard data={nft} />
      <div className="flex items-end justify-between">
        <IpLabel htmlFor="Shares ip">Number of shares</IpLabel>
        <Popover
          onClickOutside={() => setIsPopoverOpen(false)}
          isOpen={isPopoverOpen}
          positions={['left']} // preferred positions by priority
          containerStyle={{ zIndex: '1000', marginRight: '5px' }}
          content={<SlippageSetting />}
          padding={10}
        >
          <button
            className="text-2 mb-1"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <MemoSettings />
          </button>
        </Popover>
      </div>
      <div className=" flex flex-col rounded-[5px] gap-2 ">
        <input
          id="Shares ip"
          name="SellQuantity"
          placeholder="Enter quantity of shares to sell"
          value={value}
          max={view(data?.maxSell ?? 0n, 5).toString()}
          type="number"
          min={'0.0001'}
          step={'0.0001'}
          title="Numbers only"
          onChange={handleChange}
          className={
            'p-4 py-3 border-[1px] rounded-md pr-12 bg-1b font-bold text-f14 text-1 outline-none focus:border-brand2 ' +
            (error ? ' border-yellow-600' : '')
          }
        />
        <div className="text-2 flex w-full justify-end">Balance: {showShares(data?.userBalance || "0")}</div>
      </div>

      <div className="flex flex-col justify-between ">
        {error ? (
          <div className="text-yellow-600 flex w-full justify-end text-f10">
            <span><FontAwesomeIcon icon={faWarning} className='text-f12 pr-2' /></span>
            {error}
          </div>
        ) : trade.shares > 0 ? (
          <div className="flex gap-2 items-center">
            {/* <DisplayPrice active price={trade.price + data.dividends} className="text-2 animate-pulse" /> */}
            <DisplayPrice active price={data?.nextSellPrice ?? 0n} className="text-2 animate-pulse" />
            {/* {eth && data.dividends ? `(Inc Dividends: $${view(data.dividends * eth / E8, 4)})` : null} */}
          </div>
        ) : (
          <Skeleton className="block w-[100px] h-6 rounded-md opacity-30" />
        )}
        {/* <div className="text-2">Makret Supply: {showShares(data.supply)}</div> */}
      </div>

      <PrimaryBtn
        onClick={() => {
          setLoading(true);
          handelTrade()
            .catch((e) => {
              const msg = formatError(e);
              toast(msg, { icon: '❌' });
            })
            .finally(() => setLoading(false));
        }}
        className={`flex items-center justify-center gap-5 mt-2 h-[40px] text-white ${!!data.maxSell ? "animate-shine" : "bg-3"}`}
        disable={!!data.maxSell ? false : `Insufficient funds for selling`}
      >
        <MemoButtonLoader className="scale-110 " loading={loading} /> Sell
      </PrimaryBtn>
    </>
  );
};