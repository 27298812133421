import { useEffect, useRef, useState } from 'react';
import { PrimaryBtn } from './Buttons';
import MemoButtonLoader from './ButtonLoader';
import toast from 'react-hot-toast';
import { config } from '@/config/ConfiguredRoutes';
import ShowPrompt from './ShowPrompt';

export default function ExportWalletDrawer() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [pk, setPk] = useState('');

  const getPk = async () => {
    const provider = await config.connectors[0].getProvider();
    const p = await provider?.request({
      method: "private_key"
    });
    setLoading(false);
    setPk(p);
  };

  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        // @ts-ignore
        ref.current.focus();
      }
    }, 500);
  }, []);


  return (
    <>
      <div className='flex align-middle items-center justify-center'>
        <div className="flex flex-col responsive-layout gap-4 pb-4">

          {pk ? <div className="flex flex-col rounded-[5px] bg-1b gap-2 p-3">
            <span className="text-2 text-f14 font-[500]">
              Your private key
            </span>
            <textarea
              value={pk}
              rows={3}
              style={{ resize: 'none' }}
              className="px-3 text-lg font-bold text-1"
            />
          </div>
            :
            <div className="flex flex-col rounded-[5px] bg-1b gap-2 p-3">
              <ShowPrompt
                message={
                  <div className='text-left'>
                    <span className="text-2 text-f14 font-[500]">
                      ⚠️ Warning: Your Private Key is Extremely Sensitive!
                    </span>
                    <br />
                    <br />
                    <ol className="text-2 text-f12 font-[500] list-decimal list-inside">
                      <li>
                        <span className="text-red-500 pr-1">
                          Do not share
                        </span>
                        this private key with anyone.
                      </li>
                      <li>
                        <span className="text-red-500 pr-1">
                          Store it securely
                        </span>
                        in an offline location, such as a hardware wallet or encrypted storage.
                      </li>
                      <li>
                        <span className="text-red-500 pr-1">
                          Avoid copying or pasting
                        </span>
                        it in untrusted applications, websites, or devices.
                      </li>
                      <li>
                        <span className="text-red-500 pr-1">
                          If anyone gains access
                        </span>
                        to your private key, they can steal your funds, and there is no way to recover them.
                      </li>
                    </ol>
                    <br />
                    <span className="text-2 text-f14 font-[500] mt-4">
                      Are you sure you want to reveal your private key?
                    </span>
                  </div>}
              />
              <span className="text-2 mt-4 text-f14 font-[500]">
                Write 'export' to see your private key
              </span>
              <input
                // @ts-ignore
                ref={ref}
                value={value}
                type="text"
                onChange={(e) => setValue(e.target.value)}
                className="px-3 text-lg font-bold text-0 outline-none border-[1px] focus:border-brand rounded-[5px] mb-1"
              />
            </div>}

          <PrimaryBtn
            onClick={() => {
              if (value === 'export') {
                setLoading(true);
                getPk();
              } else {
                toast.error('Wrong input', {
                  id: 'wrong-input'
                });
              }
            }}
            className="flex items-center justify-center gap-5 h-[40px] text-white"
          >
            <MemoButtonLoader className="scale-110 " loading={loading} /> Show Private Key
          </PrimaryBtn>
        </div>
      </div >


    </>
  );
}
