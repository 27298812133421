import { ReactNode } from 'react';
import toast from 'react-hot-toast';
import { twJoin, twMerge } from 'tailwind-merge';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  disable?: string | false;
}

const PrimaryBtn: React.FC<ButtonProps> = ({
  children,
  className,
  disable,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        `${!disable ? 'bg-brand' : 'bg-3  cursor-not-allowed'
        } text-md font-bold text-white min-w-full py-[10px] rounded-[5px] shrink-0`,
        className
      )}
      {...props}
      onClick={disable ? () => toast(disable, { id: disable }) : props.onClick}
    >
      {children}
    </button>
  );
};


const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  className,
  disable,
  ...props
}) => {
  return (
    <button
      className={twJoin(
        `${!disable ? 'bg-brand' : 'bg-3  cursor-not-allowed'
        } text-f10 px-2 py-1 font-bold text-white  rounded-[5px] select-none `,
        className
      )}
      {...props}
      onClick={disable ? () => toast(disable, { id: disable }) : props.onClick}
    >
      {children}
    </button>
  );
};


const UnderlineButton: React.FC<ButtonProps> = ({
  children,
  className,
  disable,
  ...props
}) => {
  return (
    <button
      className={twJoin(` text-brand `, className)}
      {...props}
      onClick={disable ? () => toast(disable, { id: disable }) : props.onClick}
    >
      {children}
    </button>
  );
};


const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  className,
  disable,
  ...props
}) => {
  return (
    <button
      className={twJoin(
        `${!disable ? ' bg-lightBrand' : 'bg-[grey]  cursor-not-allowed'
        } text-f10 px-2 py-1 font-bold outline-none text-brand  rounded-[5px] select-none `,
        className
      )}
      {...props}
      onClick={disable ? () => toast(disable, { id: disable }) : props.onClick}
    >
      {children}
    </button>
  );
};


const SecondaryBtn: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        ' bg-lightBrand text-md font-bold text-brand  py-[10px] rounded-[5px] shrink-0 select-none',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};


const BadgeWrapper: React.FC<ButtonProps & { tag: string; }> = ({
  children,
  className,
  tag,
  ...props
}) => {
  return (
    <div className={`relative`}>
      {tag && <div className={twMerge(`absolute right-0 top-0 rounded-full bg-brand z-10 p-[1px] h-[14px] w-[14px] text-[9px] text-white`, className)}>
        {tag}
      </div>}
      {children}
    </div>
  );
};

export {
  PrimaryBtn,
  UnderlineButton,
  SecondaryBtn,
  PrimaryButton,
  SecondaryButton,
  BadgeWrapper
};
