import { axiosInstance } from '@/config/axiosInstance';
import { mainDbOtherUser, mainDbUser, nftDbMinData } from '@/firebase/realtimeDb';
import { fetchData, storeData } from './localStorage';

// Get user data from the backend
export const getUserData = async (address: `0x${string}`) => {
  try {
    const u = (await mainDbUser.get(address)) as unknown as User;
    if (u) {
      // if user data is older than 24 hours, return null
      if (!u.refreshed_at || Number(u.refreshed_at) < Date.now() - 1000 * 60 * 60 * 24) return null;
      else return u;
    }
    return null;
  } catch (e) {
    return null;
  }
};


// Get user data from the backend
export const getOtherUserData = async (address: `0x${string}`) => {
  try {
    const user = await fetchData(address);
    if (user) return user;
    const loadUser = (await mainDbOtherUser.get(address)) as unknown as MinimalUser;
    await storeData(address, loadUser);
    return loadUser;
  } catch (e) {
    return null;
  }
};

// Get user data from the backend
export const getMinNftData = async (marketId: string) => {
  try {
    const nft = await fetchData(marketId);
    if (nft) return nft;
    const loadNft = (await nftDbMinData.get(marketId)) as unknown as MinimalUser;
    await storeData(marketId, loadNft);
    return loadNft;
  } catch (e) {
    return null;
  }
};

export const loadNftMinData = async (nft: NFTStoredData) => {
  try {
    await storeData(nft.marketId, {
      thumbnail: nft.thumbnail,
      title: nft.title,
    });
  } catch (e) {
    return null;
  }
};


// Register user to the backend, means sends data yielded from the (third party) web3AuthInstance.getUserInfo() to the backend
export const registerUser = async (connector: any, address: `0x${string}`) => {
  if (!connector) return null;

  const userInfo = await connector.web3AuthInstance.getUserInfo();
  const inviteCode = getInviteCode();

  const registerPayload = {
    first_name: userInfo.name,
    last_name: '',
    email: userInfo.email,
    img_url: userInfo.profileImage,
    password: 'not-needed',
    third_party_verifier: userInfo.verifier,
    third_party_id: userInfo.verifierId,
    public_address: address,
    country: '',
    timezone: '',
    gender: 0,
    invite_code: inviteCode
  };

  const res = await axiosInstance.post<ServerResponse<User>>(
    `/api/auth/register`,
    registerPayload,
    {
      headers: {
        Authorization: userInfo.idToken ? `Bearer ${userInfo.idToken}` : ""
      },
      withCredentials: true,
    }
  );

  if (res.data.error) {
    throw new Error('User info not found');
  }
  // await clearAllData();
  return res.data;
};


export const setInviteCode = (ic: string) => {
  return localStorage.setItem("doesUserHaveInviteCode", ic);
};

export const getInviteCode = () => {
  return localStorage.getItem("doesUserHaveInviteCode");
};

export const deleteInviteCode = () => {
  localStorage.removeItem("doesUserHaveInviteCode");
};