import html2canvas from "html2canvas";

/**
 * Calculate the font size based on the percentage of the original font size
 * @param fontSize: string
 * @param fontSizePercent: number
 * @returns string
 */
export const calculateFontSize = (fontSize: string, fontSizePercent: number) => {
  const f = fontSize.slice(0, 1);
  return ((parseFloat(f) * fontSizePercent) / 100).toString() + "rem";
};


export const captureDivToImage = async ({ phrase, font, fontSize, paddingTop, backgroundColor, fontColor, fontWeight, lineHeight }: {
  phrase: string; font: string; fontSize: string; paddingTop: number; backgroundColor: string; fontColor: string; fontWeight: number;
  lineHeight: number;
}) => {

  const inputPhraseArea = document.getElementById('input-phrase-area') as HTMLTextAreaElement;
  inputPhraseArea.value = "";
  const divElement = document.getElementById('text-with-img-nft');
  // return;
  if (divElement) {
    const originalWidth = divElement.offsetWidth;
    const originalHeight = divElement.offsetHeight;
    const scale = 1; // Target scale factor
    const fontSizeRem = parseFloat(fontSize.slice(0, -3));
    const paddingTopRem = paddingTop;

    // console.log({ fontSize, paddingTop, fontSizeRem, paddingTopRem });

    const canvas = await html2canvas(divElement, {
      scale: scale,
      logging: true,
      onclone: (clonedDocument) => {
        const clonedDiv = clonedDocument.getElementById('text-with-img-nft');
        if (clonedDiv) {
          // Adjust the width of the cloned element to match the scaled size
          clonedDiv.style.width = `${originalWidth * scale}px`;
          // Optionally, adjust the height if necessary
          clonedDiv.style.height = `${originalHeight * scale}px`;

          clonedDiv.style.backgroundSize = "cover";
          clonedDiv.style.backgroundPosition = "center";
          clonedDiv.style.backgroundRepeat = "no-repeat";

          // translate to right
          // clonedDiv.style.top = `${originalWidth * (1 - scale) / 2}px)`;
          console.log({ top: clonedDiv.style.top, left: clonedDiv.style.left });
        }
      }
    });

    const image = canvas.toDataURL('image/png'); // Use 'image/jpeg' for JPG format

    const imgContainer = document.createElement('div');
    // imgContainer.style.backgroundColor = "skyblue";
    imgContainer.style.position = "relative";
    imgContainer.style.width = `${originalWidth * scale / 2}px`;
    imgContainer.style.height = `${originalHeight * scale / 2}px`;

    // For demonstration: create a new Image element to display the captured content
    const imgElement = document.createElement('img');
    imgElement.id = "nft-bg-image";

    imgElement.src = image;
    imgContainer.appendChild(imgElement);


    // render the text on the image, line by line
    phrase.split("\n").forEach((line, index) => {
      const fontSize = fontSizeRem * scale / 2;
      const paddingTop = paddingTopRem * scale / 2;

      // create a new paragraph element for each line of text
      const text = document.createElement('p');
      text.className = "phrase-text-p";
      text.innerHTML = line;
      text.style.position = "absolute";
      text.style.fontSize = fontSize.toFixed(2) + "rem";

      // this is the padding top of the first line of text
      text.style.top = (paddingTop + (fontSize * index * 1.2) + 0.2).toFixed(2) + "rem";

      text.style.fontWeight = fontWeight.toString();
      text.style.fontFamily = font;
      text.style.color = fontColor;
      text.classList.add("w-full", "text-center");

      // append the text to the image container
      imgContainer.appendChild(text);
    });


    const hr = document.createElement('hr');
    document.getElementById("last-element")?.prepend(hr);
    document.getElementById("last-element")?.prepend(imgContainer);


    const thumbnail = await html2canvas(imgContainer, {
      // while rendering the cloned element, adjust the position of the text (it was translated up by 25px)
      onclone: (clonedDocument) => {
        const clonedDiv = clonedDocument.getElementsByClassName("phrase-text-p");
        if (clonedDiv) {
          for (let i = 0; i < clonedDiv.length; i++) {
            // @ts-ignore
            clonedDiv[i].style.transform = "translateY(-15px)";
          }
        }
      }
    });

    const thumbnailImage = thumbnail.toDataURL('image/png');
    // // Create an anchor (<a>) element to facilitate downloading
    const downloadLink = document.createElement('a');
    downloadLink.href = thumbnailImage;
    downloadLink.download = `${phrase.replace(/ /g, "_")}.png`; // or 'cb-image.jpg' for JPG format

    document.body.appendChild(downloadLink); // Append to body to ensure it can be clicked
    downloadLink.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(downloadLink); // Clean up by removing the link
  }

  inputPhraseArea.value = phrase;
};


/**
 * Capture the content of a div element to an image
 * @param nftWidth: number Width of the NFT image
 * @param cb Callback function to be called after the image has been captured
 * @returns Promise to return an image data URL 
 */
export const captureDivToImage2 = async (nftWidth?: number, cb?: () => void): Promise<string> => {

  const divElement = document.getElementById('text-with-img-nft');
  let image = "";

  // return;
  if (divElement) {

    const canvas = await html2canvas(divElement, {
      onclone: (clonedDocument) => {
        const clonedDiv = clonedDocument.getElementsByClassName("phrase-text-p");
        if (clonedDiv && nftWidth) {
          for (let i = 0; i < clonedDiv.length; i++) {
            // @ts-ignore
            clonedDiv[i].style.transform = `translateY(${(-nftWidth * 0.09).toFixed(0)}px)`;
            // @ts-ignore
            // clonedDiv[i].style.paddingTop = "-20px";
          }
        }
      }
    });

    // normal size image
    // image = canvas.toDataURL('image/png'); // Use 'image/jpeg' for JPG format

    // Create a new canvas with the desired dimensions
    const fixedSizeCanvas = document.createElement('canvas');
    fixedSizeCanvas.width = 510;
    fixedSizeCanvas.height = 510;

    // Draw the captured image onto the new canvas
    const ctx = fixedSizeCanvas.getContext('2d');
    if (ctx) {
      // Adjust these parameters to fit the captured content in the new canvas as desired
      ctx.drawImage(canvas, 0, 0, 512, 512);
    }

    // Convert the new canvas to an image data URL
    image = fixedSizeCanvas.toDataURL('image/png'); // Use 'image/jpeg' for JPG format

    // const imgContainer = document.createElement('div');
    // // For demonstration: create a new Image element to display the captured content
    // const imgElement = document.createElement('img');
    // imgElement.id = "nft-bg-image";
    // imgElement.src = image;

    // imgContainer.appendChild(imgElement);

    // const hr = document.createElement('hr');
    // document.getElementById("last-element")?.prepend(hr);
    // document.getElementById("last-element")?.prepend(imgContainer);


    // Create an anchor (<a>) element to facilitate downloading
    // const downloadLink = document.createElement('a');
    // downloadLink.href = image;
    // downloadLink.download = `nft.png`; // or 'cb-image.jpg' for JPG format

    // document.body.appendChild(downloadLink); // Append to body to ensure it can be clicked
    // downloadLink.click(); // Programmatically click the link to trigger the download
    // document.body.removeChild(downloadLink); // Clean up by removing the link
  }
  if (cb) cb();
  return image;
};


/**
 * Capture the content of a div element to an image
 * @param cb Callback function to be called after the image has been captured
 * @returns Promise to return an image data URL 
 */
export const captureDivToImage3 = async (cb?: () => void): Promise<string> => {
  const divElement = document.getElementById('text-with-img-nft');
  let image = "";
  // return;
  if (divElement) {
    // Wait for all images within the div to load
    const images = divElement.getElementsByTagName('img');
    // console.log({ images });
    const imageLoadPromises = Array.from(images).map((img) => {
      return new Promise<void>((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => resolve(); // Handle errors gracefully
        }
      });
    });
    await Promise.all(imageLoadPromises); // Wait until all images are loaded

    const canvas = await html2canvas(divElement, {
      onclone: (clonedDocument) => {
        const images = clonedDocument.getElementsByTagName('img');
        console.log({ images });
        Array.from(images).map((img) => {
          img.style.marginBottom = "-10px";
        });

        const eth = clonedDocument.getElementById("eth-icon-display");
        console.log({ eth });
        if (eth) {
          eth.style.padding = "0px";
          eth.style.paddingTop = "1px";
          eth.style.paddingBottom = "2px";
          eth.style.paddingLeft = "1px";
          eth.style.paddingRight = "2px";
          eth.style.width = "10px";
          eth.style.height = "10px";
          eth.style.marginTop = "14px";
        }
      },
      useCORS: true, // Enable CORS if needed
    });

    // // Create a new canvas to apply rounded corners
    // const roundedCanvas = document.createElement('canvas');
    // const ctx = roundedCanvas.getContext('2d');

    // if (ctx) {
    //   const radius = 20; // Adjust this to set your desired border-radius

    //   // Set the new canvas dimensions
    //   roundedCanvas.width = canvas.width;
    //   roundedCanvas.height = canvas.height;

    //   // Draw a rounded rectangle
    //   ctx.beginPath();
    //   ctx.moveTo(radius, 0);
    //   ctx.lineTo(canvas.width - radius, 0);
    //   ctx.quadraticCurveTo(canvas.width, 0, canvas.width, radius);
    //   ctx.lineTo(canvas.width, canvas.height - radius);
    //   ctx.quadraticCurveTo(canvas.width, canvas.height, canvas.width - radius, canvas.height);
    //   ctx.lineTo(radius, canvas.height);
    //   ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - radius);
    //   ctx.lineTo(0, radius);
    //   ctx.quadraticCurveTo(0, 0, radius, 0);
    //   ctx.closePath();

    //   // Clip to the rounded rectangle
    //   ctx.clip();

    //   // Draw the original canvas onto the new one
    //   ctx.drawImage(canvas, 0, 0);
    // }
    // image = roundedCanvas.toDataURL('image/png');
    
    image = canvas.toDataURL('image/png');
  }
  if (cb) cb();
  return image;
};


/**
 * Load the specified font from Google Fonts
 * @param fontName: string
 * @returns void
 */
export const loadFont = (fontName: string) => {
  const linkId = `font-style-${fontName.replace(/ /g, '-')}`;
  let link = document.getElementById(linkId) as HTMLLinkElement;

  // If the link node already exists, remove it first
  if (link) {
    document.head.removeChild(link);
  }

  // Create a new link node
  link = document.createElement('link');
  link.id = linkId;
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/css?family=${fontName.replace(/ /g, '+')}:wght@100;300;400;500;700;900;100&display=swap`;

  // Append the new link node to the head
  document.head.appendChild(link);
};

/**
 * Find the nearest value to the target in the array
 * @param arr: number[]
 * @param target: number
 * @returns number
 */
export const findNearestValue = (arr: number[], target: number): number => {
  if (arr.length === 0) {
    throw new Error("Array is empty");
  }

  let start = 0;
  let end = arr.length - 1;

  while (start < end) {
    let mid = Math.floor((start + end) / 2);
    if (arr[mid] < target) {
      start = mid + 1;
    } else {
      end = mid;
    }
  }

  // If the element at the 'start' index is greater than the target, and 'start' is not at the beginning,
  // return the previous element, ensuring it's the nearest lower value.
  // If 'start' is at the beginning, or the element at 'start' is equal to the target, return the element at 'start'.
  if (arr[start] > target && start > 0) {
    return arr[start - 1];
  }

  return arr[start];
};


/**
 * Assuming thumbImgUrl is your Base64 string
 * @param base64: string
 * @param type: string
 * @returns Blob
 */
export const base64ToBlob = (base64: string, type = 'image/png') => {
  const binaryString = window.atob(base64.split(',')[1]); // Decode base64
  const len = binaryString.length;
  const binaryArray = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    binaryArray[i] = binaryString.charCodeAt(i);
  }

  return new Blob([binaryArray], { type });
};


/**
 * Verifies if a given string is a valid URL.
 * @param urlString: string
 * @returns boolean
 */
export const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};