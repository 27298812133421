// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG ?? "{}");

// Initialize Firebase
export const fbApp = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const realtimeDb = getDatabase(fbApp);

// Initialize Firebase Analytics and get a reference to the service
export const fbAnalytics = getAnalytics(fbApp);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(fbApp);

export const vadpidKey = import.meta.env.VITE_FIREBASE_VADPIDKEY;

// Initialize Firebase Authentication and get a reference to the service
export const faAuth = getAuth(fbApp);

// Function to log in a user with email and password
export const loginUserWithEmailPassword = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(faAuth, email, password);
    // User is logged in
    // userCredential.user will contain user information
    // console.log("User logged in:", userCredential.user);
    return userCredential.user;
  } catch (error) {
    // Handle errors here, such as incorrect password, user not found, etc.
    // console.error("Error logging in:", error);
    return null;
  }
};

// Function to log out the current user
export const logoutUser = async () => {
  try {
    await signOut(faAuth);
    return true;
  } catch (error) {
    return false;
  }
};


// Function to check if a user is logged in
export const setUserLoggedInData = (setState: (a: any) => void) => {
  onAuthStateChanged(faAuth, (user) => {
    if (user) {
      // User is signed in, you can use the 'user' object to get user information
      setState({ ...user, loggedIn: true });
    } else {
      // No user is signed in.
      setState({ loggedIn: false });
    }
  });
};